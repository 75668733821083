import AppShell from "components/shared/AppShell";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  return (
    <div>
      <AppShell>
        <Outlet />
      </AppShell>
    </div>
  );
};

export default DashboardLayout;
