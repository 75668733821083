export const PrimaryButton = ({
  type = "button",
  text,
  onClick,
  styleOverride,
  disabled,
}: {
  type?: "button" | "submit";
  text: string | React.ReactNode;
  onClick?: () => void;
  styleOverride?: string;
  disabled?: boolean;
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={` mb-2 me-2 rounded-lg border  px-4 py-1 text-center text-sm font-medium text-white  focus:outline-none focus:ring-4 focus:ring-blue-300 ${styleOverride} ${
        disabled
          ? "border-gray-300 bg-gray-300 hover:bg-gray-300"
          : "border-blue-500 bg-blue-500 hover:bg-blue-700"
      }`}>
      {text}
    </button>
  );
};

export const SecondaryButton = ({
  text,
  onClick,
  styleOverride,
  disabled,
}: {
  text: string;
  onClick: () => void;
  styleOverride?: string;
  disabled?: boolean;
}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={`mb-2 me-2 rounded-lg border border-gray-400 bg-white px-4 py-1 text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 ${styleOverride} ${
        disabled ? "opacity-60" : "opacity-100"
      }`}>
      {text}
    </button>
  );
};
export const TertiaryButton = ({
  text,
  onClick,
  styleOverride,
  disabled,
}: {
  text: string;
  onClick: () => void;
  styleOverride?: string;
  disabled?: boolean;
}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={`mb-2 me-2 rounded-lg border border-red-600 px-4 py-1 text-sm font-medium text-red-600 hover:bg-red-700 hover:text-white ${styleOverride}`}>
      {text}
    </button>
  );
};

export const IconButton = ({
  children,
  onClick,
  styleOverride,
}: {
  children: React.ReactNode;
  onClick: () => void;
  styleOverride?: string;
}) => {
  return (
    <button type="button" onClick={onClick} className={styleOverride}>
      {children}
    </button>
  );
};
