import type { ReactNode } from "react";
import { H1 } from "components/shared/Headers";

interface Props {
  title?: ReactNode;
  text: ReactNode;
}

export function TitleAndText({ title, text }: Props) {
  return (
    <>
      <H1>{title}</H1>
      <div className="my-4">{text}</div>
    </>
  );
}
