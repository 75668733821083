const Checkbox = ({
  onChangeHandler,
  checked,
  labelText,
}: {
  onChangeHandler: () => void;
  checked: boolean;
  labelText: string;
}) => {
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id="comments"
          aria-describedby="comments-description"
          name="comments"
          type="checkbox"
          onChange={onChangeHandler}
          checked={checked}
          className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor="comments">{labelText}</label>
        {/* <p id="comments-description" className="text-gray-500">
        Get notified when someones posts a comment on a posting.
      </p> */}
      </div>
    </div>
  );
};

export default Checkbox;
