const env = process.env.NEXT_PUBLIC_NAMESPACE || "development";
/**
 * @typedef {Object} ClientConfig
 * @property {string} baseUrl
 * @property {Object} s3
 * @property {string} s3.publicUrl
 * @property {Object} tolgee
 * @property {string | undefined} tolgee.publicKey
 * @property {string} tolgee.publicUrl
 * @property {boolean} isDev
 * @property {string} edgeApi
 * @property {Object} stripe
 * @property {string} stripe.pubKey
 * @property {string} stripe.pricingTable
 */
/** @type {ClientConfig} */
const testAndDevConfig = {
  baseUrl: "http://localhost:3000",
  s3: {
    publicUrl: "http://localhost:3000/s3/dev/",
  },
  tolgee: {
    publicKey: process.env.NEXT_PUBLIC_TOLGEE_API_KEY,
    publicUrl: "https://app.tolgee.io",
  },
  stripe: {
    pubKey: "pk_test_51NQGpqBOVUJROXbs2FQTHPx3Ms062hTfbHl0qcGuFc1XOP18IId5cgU6vmvXRdOoUbThFWkg1tFPgx1XyiFKFbYb008OVQsGzo",
    pricingTable: "prctbl_1NnHN2BOVUJROXbseKdamPFv",
  },
  isDev: true,
  edgeApi: "https://edge.vettify.io",
};

/**
 * @type {Record<string, ClientConfig>}
 */
const configs = {
  development: testAndDevConfig,
  test: testAndDevConfig,
  staging: {
    baseUrl: "https://remi.vettify.io",
    s3: {
      publicUrl: "https://pub-ce3be87453554f5a8156481324ab9d00.r2.dev/",
    },
    tolgee: {
      publicKey: undefined,
      publicUrl: "https://app.tolgee.io",
    },
    stripe: {
      pubKey: "pk_test_51NnO43DWXUYnVjVPTGHjDYJqGkjG8lXhdGsS5CmuUbLRFc1Jfu4HEi7Lh6TUrMBg8kNUalQmNA4hGSSc164TNN0Z00vZeUjqRA",
      pricingTable: "prctbl_1NngJkDWXUYnVjVPGSae9ykf",
    },
    isDev: false,
    edgeApi: "https://edge.vettify.io",
  },
  production: {
    baseUrl: "https://vettify.io",
    s3: {
      publicUrl: "https://cdn.vettify.io/",
    },
    tolgee: {
      publicKey: undefined,
      publicUrl: "https://app.tolgee.io",
    },
    stripe: {
      pubKey: "pk_live_51NnO43DWXUYnVjVP1h6jpPdqUAvPFsD7ekkYyw57mCnome9h1wMgGfm6GD97zXSTR7tfaaGZadpB75txV3ddEm0r00dsP63TCL",
      pricingTable: "prctbl_1No38sDWXUYnVjVPfCyBLQM5",
    },
    isDev: false,
    edgeApi: "https://edge.vettify.io",
  },
};

const config = configs[env];

module.exports = config;