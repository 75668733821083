import { type FormEvent, useState } from "react";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import clsx from "clsx";
import { PrimaryButton, SecondaryButton } from "components/shared/button";
import Alert from "components/shared/Alert";
import { Text, TextArea } from "components/shared/Fields";
import { useTranslate } from "lib/translate";
import useFetch from "lib/fetch-utils";
import clientConfig from "lib/client_config";
import { useUserData } from "lib/hooks/UserProvider";
import { useRightData } from "lib/hooks/RightData";
import { urlValidator } from "lib/validators";

function extractDomain(url: string) {
  try {
    const domain = new URL(url).hostname;
    const domainWithoutWWW = removeWWWFromHostname(domain);
    return domainWithoutWWW;
  } catch (error) {
    return url;
  }
}
function removeWWWFromHostname(hostname: string) {
  if (hostname.startsWith("www.")) {
    return hostname.slice(4);
  }
  return hostname;
}

function selectedOrgTemplate(
  option: { name: string; logo: { asset_code: string } },
  // eslint-disable-next-line
  props: any
) {
  if (option) {
    return (
      <div className="flex items-center">
        {option.logo.asset_code && (
          <div>
            <img
              className="mr-2 w-[15px]"
              alt={option.name}
              src={`https://cdn.vettify.io/asset/file/${option.logo.asset_code}`}
            />
          </div>
        )}
        <div>{option.name}</div>
      </div>
    );
  }
  return <span>{props.placeholder}</span>;
}

const orgsListOptionTemplate = (option: {
  name: string;
  website: string;
  logo: { asset_code: string };
}) => {
  return (
    <div className="flex items-center">
      {option.logo.asset_code && (
        <img
          className="mr-2 w-[15px]"
          alt={option.name}
          src={`https://cdn.vettify.io/asset/file/${option.logo.asset_code}`}
        />
      )}
      <div className="flex w-full justify-between">
        <div className="mr-2">{option.name}</div>
        <div className="text-left text-sm text-gray-400">
          {extractDomain(option.website)}
        </div>
      </div>
    </div>
  );
};

const SendAssetStep = ({
  step,
  orgsList,
  selectedOrg,
  asset,
  firstname,
  email,
  orgname,
  isSubmitted,
  targetURL,
  urlOptions,
  customURL,
  relationshipNature,
  message,
  setSelectedOrg,
  setFirstname,
  setOrgname,
  setTargetURL,
  setCustomURL,
  setRelationshipNature,
  setMessage,
  setIsSubmitted,
  resetDrawerStatus,
  resetFormState,
  selectedOption,
  setSelectedOption,
}: {
  step: number;
  // eslint-disable-next-line
  selectedOrg: any;
  // eslint-disable-next-line
  orgsList: any[];
  asset: { id: number; target_url: string };
  firstname: string;
  email: string;
  orgname: string;
  isSubmitted: boolean;
  targetURL: number;
  urlOptions: { name: string; value: number }[];
  customURL: string;
  relationshipNature: string;
  message: string;
  // eslint-disable-next-line
  setSelectedOrg: (value: any) => void;
  setFirstname: (value: string) => void;
  setOrgname: (value: string) => void;
  setTargetURL: (value: number) => void;
  setCustomURL: (value: string) => void;
  setRelationshipNature: (value: string) => void;
  setMessage: (value: string) => void;
  setIsSubmitted: (value: boolean) => void;
  resetDrawerStatus: () => void;
  resetFormState: () => void;
  // eslint-disable-next-line
  selectedOption: any;
  // eslint-disable-next-line
  setSelectedOption: (value: any) => void;
}) => {
  const [userData] = useUserData();
  const [, setRightData] = useRightData();
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslate();
  const { performApiAction, error, resetStatus } = useFetch();
  const existingUserDisabled =
    !email ||
    (targetURL === 1 && !urlValidator(customURL)) ||
    (targetURL === 2 && !relationshipNature);
  const newUserDisabled =
    !firstname ||
    !orgname ||
    !email ||
    (targetURL === 1 && !urlValidator(customURL)) ||
    (targetURL === 2 && !relationshipNature);
  const postURL =
    step === 1
      ? "/api/v1/invites/asset/send-existing-user"
      : "/api/v1/invites/asset/send-new-user";
  const existingUserForm = {
    asset: { asset_id: asset?.id },
    invitee: {
      user: {
        email,
      },
      org: {
        id: orgsList.length > 1 ? selectedOrg?.id : orgsList[0]?.id,
      },
    },
    right: {
      target_url_type: targetURL,
      target_url:
        targetURL === 0 ? asset?.target_url : targetURL === 1 ? customURL : "",
      relationship_nature: targetURL === 2 ? relationshipNature : "",
      message,
    },
  };
  const newUserForm = {
    asset: { asset_id: asset?.id },
    invitee: {
      user: {
        firstname,
        lastname: "",
        email,
      },
      org: {
        name: orgname,
        website: "",
      },
    },
    right: {
      target_url_type: targetURL,
      target_url:
        targetURL === 0 ? asset?.target_url : targetURL === 1 ? customURL : "",
      relationship_nature: targetURL === 2 ? relationshipNature : "",
      message,
    },
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // To show error message after submit
    setIsSubmitted(true);
    // To disable submit button, while submitting
    setIsSubmitting(true);

    event.preventDefault();
    // Validate form existing user
    if (step === 1) {
      if (existingUserDisabled || !existingUserForm.invitee.org.id) {
        setIsSubmitting(false);
        return;
      }
    }
    // Validate form new user
    if (step === 2) {
      if (newUserDisabled) {
        setIsSubmitting(false);
        return;
      }
    }

    await performApiAction({
      url: `${clientConfig.edgeApi}${postURL}`,
      xSession: userData?.session,
      newData: step === 1 ? existingUserForm : newUserForm,
      onSuccess: async () => {
        const rightRequest = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/rights/all`,
          xSession: userData?.session,
          newData: {
            batchSize: 20,
          },
          resultNeeded: true,
        });
        if (rightRequest?.ok) {
          // Updated rightData
          setSuccess(true);
          setRightData(rightRequest.data);
          localStorage.setItem("right", JSON.stringify(rightRequest.data));
          setIsSubmitting(false);

          setTimeout(() => {
            resetDrawerStatus();
          }, 2000);
        } else {
          console.log("Error in rightRequest", rightRequest);
        }
      },
    });
  };

  return (
    <div>
      {error && (
        <Alert
          className="my-4"
          type="error"
          resetStatus={() => {
            resetStatus("error");
          }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert
          className="my-4"
          type="success"
          resetStatus={() => {
            resetStatus("success");
          }}>
          {t("asset.send-by-email.form.success.message")}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-5 grid max-w-2xl grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-8">
          <div className="col-span-full max-w-sm">
            <label>
              <div className="mb-2 block text-sm font-medium text-gray-700">
                {t("asset.send-by-email.form.email")}
              </div>
            </label>
            <input
              type="text"
              disabled
              value={email}
              className="p-inputtext p-component w-full"
            />
          </div>
          {step === 1 && (
            <div className="col-span-full max-w-sm">
              <label htmlFor="orgsList">
                <div className="mb-2 text-sm font-medium text-gray-700">
                  {t("asset.send-by-email.form.orgname")}
                </div>
              </label>
              {orgsList.length > 1 ? (
                <>
                  <Dropdown
                    value={selectedOrg}
                    onChange={(e) => {
                      setSelectedOrg(e.value);
                    }}
                    name="orgsList"
                    options={orgsList}
                    optionLabel="name"
                    placeholder={t(
                      "asset.send-by-email.form.orgname.dropdown.placeholder"
                    )}
                    className={clsx(
                      "w-full",
                      isSubmitted && !selectedOrg && "!border-red-500"
                    )}
                    valueTemplate={selectedOrgTemplate}
                    itemTemplate={orgsListOptionTemplate}
                  />
                  {isSubmitted && !selectedOrg && (
                    <div className="mt-1 text-sm text-red-500">
                      {t("asset.send-by-email.form.orgname.dropdown.error")}
                    </div>
                  )}
                </>
              ) : (
                <div className="flex max-w-sm justify-between">
                  <div>{orgsList[0].name}</div>
                  <div className="text-sm text-gray-400">
                    {extractDomain(orgsList[0].website)}
                  </div>
                </div>
              )}
            </div>
          )}
          {step === 2 && (
            <>
              <div className="col-span-full max-w-sm">
                <Text
                  name="firstname"
                  label={t("asset.send-by-email.form.firstname")}
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  errorText={
                    isSubmitted && !firstname
                      ? t("asset.send-by-email.form.firstname.error")
                      : ""
                  }
                />
              </div>
              <div className="col-span-full max-w-sm">
                <Text
                  name="orgname"
                  label={t("asset.send-by-email.form.orgname")}
                  type="text"
                  value={orgname}
                  onChange={(e) => setOrgname(e.target.value)}
                  errorText={
                    isSubmitted && !orgname
                      ? t("asset.send-by-email.form.orgname.error")
                      : ""
                  }
                />
              </div>
            </>
          )}

          <div className="col-span-full max-w-sm">
            <label htmlFor="targetURL">
              <div className="mb-2 text-sm font-medium text-gray-700">
                {t("asset.send-by-email.form.targetURL.label")}
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon icon-[ion--information-circle-outline] ml-1 text-center  align-middle text-gray-500"
                  data-pr-tooltip={t(
                    "asset.send-by-email.form.targetURL.tooltip"
                  )}
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"></i>
              </div>
            </label>
            <Dropdown
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.value);
                setTargetURL(e.value.value === 0 ? 0 : e.value);
              }}
              name="targetURL"
              options={urlOptions}
              optionLabel="name"
              placeholder={t(
                "asset.send-by-email.form.targetURL.dropdown.placeholder"
              )}
              className="w-full"
            />
          </div>
          {targetURL === 1 && (
            <div className="col-span-full max-w-sm">
              <Text
                name="customURL"
                label={t("asset.send-by-email.form.targetURL.customURL.label")}
                type="text"
                value={customURL}
                onChange={(e) => setCustomURL(e.target.value)}
                errorText={
                  isSubmitted && !urlValidator(customURL) ? t("errors.url") : ""
                }
              />
            </div>
          )}

          {targetURL === 2 && (
            <div className="col-span-full max-w-sm">
              <Text
                name="relationshipNature"
                label={t("asset.send-by-email.form.relationship-nature")}
                type="text"
                value={relationshipNature}
                onChange={(e) => setRelationshipNature(e.target.value)}
                withTooltip={true}
                tooltipText={t(
                  "asset.send-by-email.relationship-nature.tooltip"
                )}
                errorText={
                  isSubmitted && !relationshipNature
                    ? t("asset.send-by-email.form.relationship-nature.error")
                    : ""
                }
              />
            </div>
          )}
          <TextArea
            name="message"
            label={t("asset.send-by-email.form.message")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="col-span-full"
          />
        </div>
        <div className="flex justify-between">
          <SecondaryButton
            text={t("asset.send-by-email.form.button.back")}
            onClick={() => {
              resetFormState();
            }}
          />
          <PrimaryButton
            type="submit"
            styleOverride="min-w-[110px]"
            text={
              isSubmitting ? (
                <>
                  <i className="icon-[svg-spinners--dot-revolve] !mr-0"></i>
                </>
              ) : (
                t("asset.send-by-email.form.button.submit")
              )
            }
          />
        </div>
      </form>
    </div>
  );
};

export default SendAssetStep;
