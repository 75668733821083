import { createContext, useContext, useEffect, useState } from "react";

// eslint-disable-next-line
const assetDataContext = createContext<any>(null);

export const AssetDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [assetsData, setAssetsData] = useState("");

  useEffect(() => {
    const assets = localStorage.getItem("assets");
    if (assets) {
      setAssetsData(JSON.parse(assets));
    }
  }, []);

  return (
    <assetDataContext.Provider value={[assetsData, setAssetsData]}>
      {children}
    </assetDataContext.Provider>
  );
};

export function useAssetsData() {
  const value = useContext(assetDataContext);
  if (value === null) {
    throw new Error("assets data must be used within AssetDataProvider");
  }
  return value;
}
