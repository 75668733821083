/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import clsx from "clsx";
import { OwnedRight, SharedRight } from "lib/types/right";
import { ProgressSpinner } from "primereact/progressspinner";
import { TitleAndText } from "components/shared/TitleAndText";
import ManagementCard from "./ManagementCard";
import { useTranslate } from "lib/translate";
import { useUserData } from "lib/hooks/UserProvider";
import clientConfig from "lib/client_config";
import { useRightData } from "lib/hooks/RightData";
import useFetch from "lib/fetch-utils";
import { useAssetsData } from "lib/hooks/AssetData";

const RightManagement = () => {
  const { t } = useTranslate();
  const [userData] = useUserData();
  const [rightData] = useRightData();
  const [assetsData] = useAssetsData();

  const { performApiAction } = useFetch();
  const [ownedRights, setOwnedRight] = useState<OwnedRight[]>([]);
  const [sharedRights, setSharedRights] = useState<SharedRight[]>([]);
  const [rightFetchError, setRightFetchError] = useState("");
  const [isRightDataReady, setIsRightDataReady] = useState(true);
  useEffect(() => {
    const fetchRights = async () => {
      try {
        const rightRequest = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/rights/all`,
          xSession: userData?.session,
          newData: {
            batchSize: 20,
          },
          resultNeeded: true,
        });
        if (rightRequest?.ok) {
          let currentCursor = rightRequest?.data.cursor;
          let allOwnedData = [...rightRequest.data.owned];
          let allSharedData = [...rightRequest.data.shared];

          // if there are more than 20 owned or shared rights, fetch all of them
          for (;;) {
            const loadMoreData = await performApiAction({
              url: `${clientConfig.edgeApi}/api/v1/rights/all`,
              xSession: userData?.session,
              newData: {
                batchSize: 20,
                cursor: currentCursor,
              },
              resultNeeded: true,
            });
            // if the fetch is successful, update the data
            allOwnedData = [...allOwnedData, ...loadMoreData.data.owned];
            allSharedData = [...allSharedData, ...loadMoreData.data.shared];
            // update the cursor
            currentCursor = loadMoreData.data.cursor;

            // if there are no more rights to fetch, set the state and break the loop
            if (
              loadMoreData.data.owned.length <= 19 &&
              loadMoreData.data.shared.length <= 19
            ) {
              setOwnedRight(allOwnedData);
              setSharedRights(allSharedData);
              const updatedRightData = {
                ...rightData,
                owned: allOwnedData,
                shared: allSharedData,
              };
              localStorage.setItem("right", JSON.stringify(updatedRightData));
              setIsRightDataReady(false);
              break;
            }
          }
        } else {
          setRightFetchError(t(rightRequest?.error || "Error fetching rights"));
          setIsRightDataReady(false);
        }
      } catch (e) {
        setRightFetchError("Error fetching rights");

        setIsRightDataReady(false);
      }
    };
    if (userData && rightData) {
      fetchRights();
    }

    // when userData or rightData changes, fetchRights will be called
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, rightData]);

  const updatedOwnedStatus = ({
    id,
    value,
  }: {
    id: number;
    value: boolean;
  }) => {
    const updatedOwnRight = ownedRights.find(
      (owned: { right: { id: number } }) => owned.right.id === id
    );
    if (updatedOwnRight) {
      const updatedOwnRightCopy = {
        ...updatedOwnRight,
        right: {
          ...updatedOwnRight.right,
          active: value,
        },
      };
      const updatedOwnedRights = ownedRights.map(
        (owned: { right: { id: number } }) => {
          if (owned.right.id === id) {
            return updatedOwnRightCopy;
          }
          return owned;
        }
      );
      // update local data
      localStorage.setItem(
        "right",
        JSON.stringify({ ...rightData, owned: updatedOwnedRights })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setOwnedRight(updatedOwnedRights as any);
    }
  };

  return (
    <div className={clsx(assetsData?.assets?.length === 0 ? "mt-[300px]" : "")}>
      <TitleAndText
        title={t("orgs.requests.title")}
        text={t("orgs.requests.description")}
      />

      {isRightDataReady ? (
        <div className="mt-20 text-center">
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            animationDuration=".5s"
          />
        </div>
      ) : rightFetchError ? (
        <div>{rightFetchError}</div>
      ) : (
        <>
          <div>
            {assetsData &&
              ownedRights.length > 0 &&
              ownedRights.map((owned: any, idx: number) => (
                <div key={idx}>
                  <ManagementCard
                    owned={true}
                    receiverInfo={{
                      receiverOrgName: owned.invitee.org.name,
                      receiverOrgWebsite: owned.invitee.org,
                      receiverFirstName: owned.invitee.user.firstname,
                      receiverLastName: owned.invitee.user.lastname,
                      receiverEmail: owned.invitee.user.email,
                    }}
                    active={owned.right.active}
                    assetId={owned.right.asset_id}
                    rightId={owned.right.id}
                    createdAt={owned.right.created_at}
                    lastSeen={owned.right.last_seen}
                    relationship={owned.right.relationship_natures}
                    targetURL={owned.right.target_url}
                    targetURLType={owned.right.target_url_type}
                    assetCode={
                      assetsData?.assets?.find(
                        (asset: any) => asset.id === owned.right.asset_id
                      )?.asset_code
                    }
                    rightCode={owned.right.right_code}
                    assetName={
                      assetsData?.assets?.find(
                        (asset: any) => asset.id === owned.right.asset_id
                      )?.filename
                    }
                    defaultTargetURL={
                      assetsData?.assets?.find(
                        (asset: any) => asset.id === owned.right.asset_id
                      )?.target_url
                    }
                    updatedOwnedStatus={updatedOwnedStatus}
                  />
                </div>
              ))}
            {sharedRights.length > 0 &&
              sharedRights.map((shared: any, idx: number) => (
                <div key={idx}>
                  <ManagementCard
                    owned={false}
                    inviterInfo={{
                      inviterOrgName: shared.inviter.org.name,
                      inviterOrgWebsite: shared.inviter.org.website,
                      inviterUserFirstName: shared.inviter.user.firstname,
                      inviterUserLastName: shared.inviter.user.lastname,
                    }}
                    active={shared.right.active}
                    assetId={shared.right.id}
                    rightId={shared.right.id}
                    rightCode={shared.right.right_code}
                    createdAt={shared.right.created_at}
                    lastSeen={shared.right.last_seen}
                    relationship={shared.right.relationship_nature}
                    targetURL={shared.right.target_url}
                    targetURLType={shared.right.target_url_type}
                    assetCode={shared.asset.asset_code}
                    assetName={shared.asset.filename}
                    assetDescription={shared.asset.description}
                    altAttribute={shared.asset.alt_attribute}
                    messages={shared.messages}
                    now={rightData?.now}
                  />
                </div>
              ))}
          </div>
          {ownedRights.length === 0 && sharedRights.length === 0 && (
            <div className="font-bold">{t("orgs.requests.noRights")}</div>
          )}
          {/* {isLoadEverything && (
                    <Alert type="info" resetStatus={() => setIsLoadEverything(false)}>
                      {t("orgs.requests.load-more.info.no-more-data")}
                    </Alert>
                  )}
                  {(ownedRights.length > 19 || sharedRights.length > 19) && (
                    <div className="mt-4">
                      <PrimaryButton
                        text={isLoadingMore
                          ? (<i className="icon-[svg-spinners--dot-revolve] !mr-0"></i>)
                          : t("orgs.requests.load-more.button.label")}
                        onClick={() => loadMoreRights()}
                    />
                    </div>
                  )} */}
        </>
      )}
    </div>
  );
};

export default RightManagement;
