import { useRef } from "react";
import { PrimaryButton } from "components/shared/button";
import { useTranslate } from "lib/translate";
import {
  checkValidity,
  uploadFiles,
  validImageFileExtensions,
} from "lib/fileUpload";
import type { UploadOrgAsset } from "lib/types/assets";
import type { TranslationKeys } from "lib/translate";

interface UploadAssetButtonProps {
  defaultTargetURL: string | undefined;
  isLoading: boolean;
  updateAssets: () => void;
  // eslint-disable-next-line
  uploadOrgAsset: (data: UploadOrgAsset) => Promise<any>;
  updateFileUploadError: (message: TranslationKeys) => void;
}

const UploadAssetButton = ({
  defaultTargetURL,
  isLoading,
  updateAssets,
  uploadOrgAsset,
  updateFileUploadError,
}: UploadAssetButtonProps) => {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslate();

  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    if (isLoading) {
      return;
    }
    const validityMessage = checkValidity(event.target.files);

    if (validityMessage) {
      updateFileUploadError(validityMessage);
      return;
    }
    uploadFiles({
      files: Array.from(event.target.files),
      defaultTargetURL,
      updateAssets,
      uploadOrgAsset,
    });
  };

  return (
    <div>
      <PrimaryButton
        disabled={isLoading}
        text={
          <span className="flex items-center py-1 ">
            <i className="icon-[prime--plus] mr-2 text-xl"></i>
            {t("orgs.assets.add-asset")}
          </span>
        }
        onClick={() => imageInputRef.current?.click()}
        styleOverride="mb-0"
      />

      <input
        type="file"
        multiple
        accept={validImageFileExtensions
          .map((ext: string) => `.${ext}`)
          .join(",")}
        ref={imageInputRef}
        name="file"
        className="hidden"
        aria-hidden="true"
        onChange={onFileSelect}
      />
    </div>
  );
};

export default UploadAssetButton;
