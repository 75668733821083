import { Dropdown } from "primereact/dropdown";
import { useEffect, useMemo, useState } from "react";
import "chartjs-adapter-date-fns";
import { useNavigate, useLocation } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Chart } from "primereact/chart";
import type { ChartData, ChartOptions } from "chart.js";

import { Tooltip } from "primereact/tooltip";
import { SecondaryButton } from "components/shared/button";
import { useTranslate } from "lib/translate";
import clientConfig from "lib/client_config";
import { useUserData } from "lib/hooks/UserProvider";
import useFetch from "lib/fetch-utils";
import { useRightData } from "lib/hooks/RightData";
import { useAssetsData } from "lib/hooks/AssetData";
import { Helmet } from "react-helmet";
import { H1 } from "components/shared/Headers";
import { TitleAndText } from "components/shared/TitleAndText";

const InfoCard = ({
  iconClass,
  bgColor,
  title,
  value,
  tooltipText,
}: {
  iconClass: string;
  bgColor: string;
  title: string;
  value: string | number;
  tooltipText: string;
}) => (
  <>
    <Tooltip position="top" target={`#${bgColor}`} content={tooltipText} />

    <div
      id={bgColor}
      className=" inline-flex items-center rounded-md p-2 transition-colors">
      <div className={`mr-2 flex rounded-lg ${bgColor} p-2`}>
        <i className={`${iconClass} h-8 w-8`}></i>
      </div>
      <div className="flex-col items-center justify-center">
        <p className="text-xs text-gray-400">{title}</p>
        <p className="text-xl font-semibold">{value}</p>
      </div>
    </div>
  </>
);

// Define shared dataset properties
const sharedDatasetProps: Partial<ChartData<"line">["datasets"][0]> = {
  tension: 0.5,
  fill: true,
  backgroundColor: "rgba(196, 213, 247, 0.4)",
};

type Range = "7days" | "30days" | "90days";
const getDateRange = (range: Range) => {
  const today = new Date();
  const fromDate = new Date(today);
  switch (range) {
    case "7days":
      fromDate.setDate(fromDate.getDate() - 7);
      break;
    case "30days":
      fromDate.setDate(fromDate.getDate() - 30);
      break;
    case "90days":
      fromDate.setDate(fromDate.getDate() - 90);
      break;
  }

  return [today, fromDate];
};

const PageContents = () => {
  const { t } = useTranslate();
  const [userData] = useUserData();
  const [rightData] = useRightData();
  const [assetsData] = useAssetsData();
  const { performApiAction } = useFetch();
  const [range, setRange] = useState<Range>("7days");
  const [rangeValue, setRangeValue] = useState(7);
  const [isLoadingAnalyticsData, setIsLoadingAnalyticsData] = useState(true);
  const [analyticsData, setAnalyticsData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const assetId = searchParams.get("id");
  const { pathname } = location;
  const [dropDownViewList, setDropDownViewList] = useState([]);
  const [selectedViewAsset, setSelectedViewAsset] = useState(0);
  const [sentRight, setSentRight] = useState(0);
  const [activeAssets, setActiveAssets] = useState(0);
  const [totalViews, setTotalViews] = useState(0);
  const getAnalyticsData = async ({
    range,
    selectedViewAsset,
  }: {
    range: Range;
    selectedViewAsset: number;
  }) => {
    setIsLoadingAnalyticsData(true);
    const [today, fromDate] = getDateRange(range);
    const response = await performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/analytics/asset-views`,
      xSession: userData?.session,
      newData: {
        asset_id: selectedViewAsset,
        from: fromDate.getTime(),
        to: today.getTime(),
      },
      resultNeeded: true,
    });
    if (response?.ok) {
      const interval = 24 * 3600 * 1000 - 1;
      const lastIndex = response.data.data.length - 1;
      const [, fromDate] = getDateRange(range);
      const totalSent = rightData.owned.filter(
        (item: { right: { asset_id: number } }) =>
          item.right.asset_id === Number(selectedViewAsset)
      );
      const totalViews = response.data.data.reduce(
        (acc: number, data: { sum: number }) => acc + data.sum,
        0
      );
      const totalSentRightsByTimeRange = totalSent.filter(
        (item: { right: { created_at: number } }) => {
          return item.right.created_at * 1000 >= fromDate.getTime();
        }
      ).length;
      const analyticsData = response.data.data.map(
        (
          data: {
            at: number;
            sum: number;
          },
          index: number
        ) => {
          if (index === lastIndex) {
            // Give last data point a current timestamp
            return {
              ...data,
              at: Date.now(),
            };
          } else {
            // otherwise, add interval to the timestamp
            return {
              ...data,
              at: data.at + interval,
            };
          }
        }
      );

      setAnalyticsData(analyticsData);
      setActiveAssets(response.data.rights.length);
      setTotalViews(totalViews);
      setSentRight(totalSentRightsByTimeRange);
      setIsLoadingAnalyticsData(false);
    } else {
      setAnalyticsData([]);
      setActiveAssets(0);
      setTotalViews(0);
      setSentRight(0);
      setIsLoadingAnalyticsData(false);
    }
  };

  const chartData: ChartData<"line"> = useMemo(
    () => ({
      datasets: [
        {
          label: "Views",
          data:
            //eslint-disable-next-line
            (analyticsData.map((data: any) => ({
              x: new Date(data.at),
              y: data.sum,
              //eslint-disable-next-line
            })) as any[]) || [],
          borderColor: "#467be7",
          backgroundColor: "#467be7bb",
          ...sharedDatasetProps,
        },
      ],
    }),
    [analyticsData]
  );

  const chartOptions: ChartOptions<"line"> = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
        decimation: {
          enabled: true,
        },
      },
      interaction: {
        intersect: false,
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "Views",
            color: "#000000",
            font: {
              size: 16,
              style: "normal",
              lineHeight: 1.2,
            },
          },
          beginAtZero: true,
          gridLines: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          type: "time",
          time: {
            unit: rangeValue === 7 ? "day" : "week", // Specify "day" to display only dates on the x-axis
            tooltipFormat: "MM/dd/yyyy", // Format for tooltip when hovering over data points
            // bounds: "data", // Only show data points within the range of the data
            // round: "day", // Round the time to the nearest day https://github.com/chartjs/Chart.js/issues/9470
          },
        },
      },
    }),
    [rangeValue]
  );

  useEffect(() => {
    if (assetsData?.assets && userData) {
      const list = assetsData.assets.map(
        (asset: { filename: string; id: number }) => ({
          name:
            asset.filename.length > 40
              ? `${asset.filename.slice(0, 40)}...`
              : asset.filename,
          value: asset.id,
        })
      );
      setDropDownViewList(list);
      if (assetId) {
        setSelectedViewAsset(Number(assetId));
        getAnalyticsData({ range, selectedViewAsset: Number(assetId) });
      } else {
        if (assetsData.assets[0]) {
          setSelectedViewAsset(assetsData.assets[0].id);
          getAnalyticsData({
            range,
            selectedViewAsset: assetsData.assets[0].id,
          });
        } else {
          setIsLoadingAnalyticsData(false);
          setAnalyticsData([]);
          setActiveAssets(0);
          setTotalViews(0);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, assetsData, assetId]);

  return (
    <div className="flex flex-col">
      <div></div>
      <div className="flex items-center justify-between border-t-2 border-gray-100 py-4">
        <div className="flex items-center gap-2">
          <div className="text-lg font-bold">{t("analytics.select.view")}</div>

          <Dropdown
            className="w-64"
            value={selectedViewAsset}
            options={dropDownViewList}
            optionLabel="name"
            onChange={(e) => {
              setSelectedViewAsset(e.value);
              getAnalyticsData({ range, selectedViewAsset: e.value });
              if (assetId) {
                navigate({
                  pathname,
                  search: "", // Clear the query parameter
                });
              }
            }}
          />
        </div>
        <div className="flex items-center gap-2 ">
          <div className="flex items-center text-lg font-bold">
            {t("analytics.select.period")}
          </div>
          <SecondaryButton
            text="7 days"
            styleOverride="py-2 mb-0"
            disabled={range === "7days"}
            onClick={() => {
              setRange("7days");
              setRangeValue(7);
              getAnalyticsData({
                range: "7days",
                selectedViewAsset,
              });
            }}
          />
          <SecondaryButton
            styleOverride="py-2 mb-0"
            text="30 days"
            disabled={range === "30days"}
            onClick={() => {
              getAnalyticsData({ range: "30days", selectedViewAsset });
              setRangeValue(30);
              setRange("30days");
            }}
          />
          <SecondaryButton
            styleOverride="py-2 mb-0"
            text="90 days"
            disabled={range === "90days"}
            onClick={() => {
              getAnalyticsData({ range: "90days", selectedViewAsset });
              setRangeValue(90);
              setRange("90days");
            }}
          />
        </div>
      </div>
      {!isLoadingAnalyticsData && analyticsData.length > 0 && (
        <div className="flex border-t-2 border-gray-100 px-3 py-2">
          <InfoCard
            iconClass="icon-[mdi--eye-outline] text-yellow-400"
            bgColor="bg-yellow-200"
            title="Total Views"
            value={totalViews.toLocaleString()}
            tooltipText={t("analytics.total-views.tooltip")}
          />
          <InfoCard
            iconClass="icon-[mdi--send] text-lime-400"
            bgColor="bg-lime-200"
            title="Sent Rights"
            value={sentRight}
            tooltipText={t("analytics.sent-rights.tooltip")}
          />
          <InfoCard
            iconClass="icon-[material-symbols--photo-album-outline-sharp] text-blue-400"
            bgColor="bg-blue-200"
            title="Active Assets"
            value={activeAssets}
            tooltipText={t("analytics.active-assets.tooltip")}
          />
        </div>
      )}

      <div className="flex flex-wrap justify-evenly gap-4 border-t-2 border-gray-100 py-4">
        {isLoadingAnalyticsData ? (
          <div className="flex justify-center">
            <ProgressSpinner />
          </div>
        ) : analyticsData && analyticsData.length > 0 && totalViews > 0 ? (
          <div className="grow-[2]">
            <Chart
              type="line"
              data={chartData}
              options={chartOptions}
              className="w-full"
            />
          </div>
        ) : (
          <div className="w-full text-center text-gray-700">
            {t("analytics.chart.empty.message")}
          </div>
        )}
      </div>
    </div>
  );
};
const Analytics = () => {
  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title>
          {t("orgs.analytics.title") +
            t("general.title-separator") +
            t("vettify")}
        </title>
        <meta
          name="description"
          content={t("orgs.analytics.meta.description")}
        />
      </Helmet>
      <H1>{t("orgs.analytics.title")}</H1>
      <TitleAndText text={t("orgs.analytics.text")} />
      <PageContents />
    </>
  );
};

export default Analytics;
