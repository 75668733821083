import { createContext, useContext, useEffect, useState } from "react";

/**
 * Export a provider that returns the current org id and a setter for the current org id.
 * It saves its state in sessionStorage so that it persists across page reloads. It also saves it in localStorage so that it persists across browser sessions, but only falls back to that if sessionStorage is not available, that way, we can have multiple tabs open with different orgs.
 * The default value is either the `orgId` query parameter from the URL or the saved value in sessionStorage, or, if none are present, the first in the list.
 * So, we have:
 *  - undefined: there is no provider
 *  - null: there is a provider, but no orgId is set, that means we have no orgs
 *  - number: there is a provider and an orgId is set
 */
const CurrentOrgContext = createContext<
  [number | undefined, (value: number | undefined) => void] | null
>(null);

export const CurrentOrgProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentOrgId, setCurrentOrgId] = useState<number | undefined>();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const orgId = JSON.parse(user).currentOrg;
      setCurrentOrgId(orgId);
    }
  }, []);

  return (
    <CurrentOrgContext.Provider value={[currentOrgId, setCurrentOrgId]}>
      {children}
    </CurrentOrgContext.Provider>
  );
};

export function useCurrentOrgId() {
  const value = useContext(CurrentOrgContext);
  if (value === null) {
    throw new Error("useCurrentOrgId must be used within a CurrentOrgProvider");
  }
  return value;
}
