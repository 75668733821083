import { useState } from "react";
import Alert from "components/shared/Alert";
import { PrimaryButton } from "components/shared/button";
import { passwordValidator } from "lib/hooks/form";
import useFetch from "lib/fetch-utils";
import { Text } from "components/shared/Fields";
import Form from "components/shared/Form";
import { useTranslate } from "lib/translate";
import { useUserData } from "lib/hooks/UserProvider";
import clientConfig from "lib/client_config";

const ChangePassword = () => {
  const { t } = useTranslate();
  const [userData] = useUserData();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] =
    useState("");
  const [changePasswordSuccessMessage, setChangePasswordSuccessMessage] =
    useState("");
  const { performApiAction, error: postRequestError, resetStatus } = useFetch();

  const changePasswordHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Reset POST request error message.
    resetStatus("error");
    // Reset error / success message
    setChangePasswordErrorMessage("");
    setChangePasswordSuccessMessage("");
    if (!oldPassword) {
      setChangePasswordErrorMessage(t("setting.user-info.old-password.error"));
      return;
    }
    if (!passwordValidator(newPassword)) {
      setChangePasswordErrorMessage(
        t("setting.user-info.password-requirements.error")
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      setChangePasswordErrorMessage(
        t("setting.user-info.password-match.error")
      );
      return;
    }
    performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/users/update-profile`,
      xSession: userData?.session,
      newData: {
        old_password: oldPassword,
        password: newPassword,
      },
      onSuccess: async () => {
        // Update the user data from the server
        setChangePasswordSuccessMessage(
          t("setting.user-info.submit.password.success")
        );
        // Reset the form
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      },
    });
  };
  return (
    <div>
      <h3 className="text-xl font-bold">
        {t("setting.user-change-password.title")}
      </h3>
      <Form onSubmit={changePasswordHandler}>
        <div className="col-span-full">
          <div className="mt-2">
            <Text
              type="password"
              label={t("setting.user-info.form.old-password.label")}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="mb-2"
            />
            <Text
              type="password"
              label={t("setting.user-info.form.password.label")}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="mb-2"
            />
            <Text
              type="password"
              label={t("setting.user-info.form.confirm-password.label")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mb-2"
            />
            <p className="text-sm text-gray-500">
              {t("setting.user-info.form.password.help-text")}
            </p>
          </div>
        </div>
        <div className="col-span-full">
          <PrimaryButton
            type="submit"
            text={t("setting.user-info.submit.password.button")}
            styleOverride="px-4 py-2  w-full sm:!w-[180px]"
          />
          {postRequestError && (
            <div className="col-span-full">
              <Alert
                type="error"
                className="max-w-[640px]"
                resetStatus={() => {
                  resetStatus("error");
                }}>
                {postRequestError}
              </Alert>
            </div>
          )}
        </div>
        {changePasswordErrorMessage && (
          <div className="col-span-full">
            <Alert
              type="error"
              resetStatus={() => {
                setChangePasswordErrorMessage("");
              }}>
              {changePasswordErrorMessage}
            </Alert>
          </div>
        )}
        {changePasswordSuccessMessage && (
          <div className="col-span-full">
            <Alert
              type="success"
              resetStatus={() => {
                setChangePasswordSuccessMessage("");
              }}>
              {changePasswordSuccessMessage}
            </Alert>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ChangePassword;
