import type React from "react";
import Alert from "./Alert";

interface FormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  errorMessage?: string;
  successMessage?: string;
  className?: string;
  ref?: React.RefObject<HTMLFormElement>; // Add React.RefObject<HTMLFormElement> type
}

export default function Form({
  onSubmit,
  children,
  errorMessage = "",
  successMessage = "",
  className,
  ref,
}: React.PropsWithChildren<FormProps>) {
  return (
    <>
      {errorMessage && <Alert type="error">{errorMessage}</Alert>}
      {successMessage ? (
        <Alert type="info">{successMessage}</Alert>
      ) : (
        <form
          ref={ref}
          onSubmit={onSubmit}
          className={`grid max-w-2xl grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 ${className}`}>
          {children}
        </form>
      )}
    </>
  );
}
