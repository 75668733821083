import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { T, useTranslate } from "lib/translate";
import useFetch, { getFormBodyObject } from "lib/fetch-utils";
import { Text } from "components/shared/Fields";
import AgreeToPolicies from "components/AgreeToPolicies";
import { passwordValidator, useForm } from "lib/hooks/form";
import Alert from "components/shared/Alert";
import type { OrgProcessInviteRequest } from "lib/types/process-invite";
import clientConfig from "lib/client_config";
import { urlValidator } from "lib/validators";

export default function InviteConfirmForm({
  suggestedUserInfo,
  code,
  inviteType,
}: // eslint-disable-next-line
any) {
  const {
    suggested_firstname,
    suggested_lastname,
    suggested_orgname,
    suggested_website,
    email,
  } = suggestedUserInfo;
  const { t } = useTranslate();
  const { performApiAction } = useFetch();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const form = useForm<
    keyof OrgProcessInviteRequest | "agreeToPolicies" | "website" | "orgname"
  >();
  const orgNameProps = form.useRegister({
    name: "orgname",
    label: t("general.form.label.orgname"),
    type: "text",
    autoComplete: "org-name",
    value: suggested_orgname,
  });
  const websiteProps = form.useRegister({
    name: "website",
    label: t("general.form.label.website"),
    type: "text",
    autoComplete: "website",
    value: suggested_website,
    validator: (x) => urlValidator(x),
    errorText: t("errors.url"),
  });
  const firstNameProps = form.useRegister({
    name: "firstname",
    label: t("general.form.label.firstname"),
    type: "text",
    autoComplete: "given-name",
    value: suggested_firstname,
  });
  const lastNameProps = form.useRegister({
    name: "lastname",
    label: t("general.form.label.lastname"),
    type: "text",
    autoComplete: "family-name",
    value: suggested_lastname,
  });
  const passwordProps = form.useRegister({
    name: "password",
    label: t("general.form.label.password"),
    type: "password",
    validator: passwordValidator,
    autoComplete: "new-password",
    errorText: t("general.form.error.invalid-password"),
  });
  const agreeToPoliciesProps = form.useRegister({
    name: "agreeToPolicies",
    type: "switch",
    validator: (value) => !!value,
    errorText: t("general.form.error.agree-to-policies"),
  });

  form.setOnSubmit(async (event) => {
    try {
      const formData = {
        code: getFormBodyObject(event.target as HTMLFormElement).code,
        password: getFormBodyObject(event.target as HTMLFormElement).password,
        firstname: getFormBodyObject(event.target as HTMLFormElement).firstname,
        lastname: getFormBodyObject(event.target as HTMLFormElement).lastname,
        orgname: getFormBodyObject(event.target as HTMLFormElement).orgname,
        website: getFormBodyObject(event.target as HTMLFormElement).website,
      };
      const inviteRequest = await performApiAction({
        url:
          inviteType === "asset"
            ? `${clientConfig.edgeApi}/api/v1/invites/asset/process`
            : `${clientConfig.edgeApi}/api/v1/invites/user-org/process`,
        newData: formData,
        resultNeeded: true,
      });

      if (inviteRequest?.ok) {
        const rightRequest = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/rights/all`,
          xSession: inviteRequest?.data.session,
          newData: {
            batchSize: 20,
          },
          resultNeeded: true,
        });
        if (rightRequest?.ok) {
          localStorage.setItem("assets", JSON.stringify({ assets: [] }));
          localStorage.setItem(
            "created_at",
            JSON.stringify(new Date().getTime())
          );
          localStorage.setItem("user", JSON.stringify(inviteRequest.data));
          localStorage.setItem("right", JSON.stringify(rightRequest.data));
          navigate("/app/dashboard");
        } else {
          setError(
            `${t("login_right_request_error")} ${t(rightRequest?.error)}`
          );
        }
      } else {
        setError(`${t("login_request_error")} ${t(inviteRequest?.error)}`);
      }
    } catch (e) {
      setError(t("login_server_error"));
    }
  });
  return (
    <>
      <div>
        <h2 className="text-lg font-semibold text-gray-900">
          <T keyName="org.invite.title" />
        </h2>
        <p className="mt-2 text-sm text-gray-700">
          <T keyName="sign-up.subtitle-before" />{" "}
          <Link
            to="/app/login"
            className="font-medium text-vettiblue-500 hover:underline">
            <T keyName="sign-up.subtitle-link" />
          </Link>{" "}
          <T keyName="sign-up.subtitle-after" />
        </p>
      </div>
      <form
        onSubmit={form.submit}
        className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
        <Text {...orgNameProps} className="col-span-full" />
        <Text {...websiteProps} className="col-span-full" />

        <Text {...firstNameProps} />
        <Text {...lastNameProps} />
        <div className="col-span-full">
          <label>
            <div className="mb-2 block text-sm font-medium text-gray-700">
              Email
            </div>
          </label>
          <input
            type="text"
            disabled
            value={email}
            className="p-inputtext p-component w-full"
          />
        </div>
        <Text {...passwordProps} className="col-span-full" />

        <AgreeToPolicies {...agreeToPoliciesProps} />
        <input type="hidden" name="code" value={code}></input>

        <div className="col-span-full flex items-center justify-center">
          <Button
            type="submit"
            disabled={!form.canSubmit}
            loading={form.submitting}
            label={t("sign-up.form.submit")}
            icon="icon-[prime--arrow-right] text-xl"
            iconPos={form.submitting ? "left" : "right"}
            loadingIcon="icon-[svg-spinners--dot-revolve]"
            tooltip={
              !form.canSubmit ? t("sign-up.form.submit.disabled.tooltip") : ""
            }
            tooltipOptions={{ showOnDisabled: true }}
          />
        </div>
      </form>
      {error && <Alert type="error">{error}</Alert>}
    </>
  );
}
