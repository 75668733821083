import React from "react";
import { Navigate, useLocation } from "react-router-dom";

function PrivateRoute({ children }: { children: React.ReactNode }) {
  const userInfo = localStorage.getItem("user");
  const location = useLocation();
  if (!userInfo) {
    return <Navigate to="/app/login" state={{ from: location }} />;
  }

  return <>{children}</>;
}

export default PrivateRoute;
