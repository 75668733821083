import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { useTranslate } from "lib/translate";
import useFetch from "lib/fetch-utils";

import AuthLayout from "components/shared/AuthLayout";
import Alert from "components/shared/Alert";
import clientConfig from "lib/client_config";
import Logo from "components/shared/Logo";
import InviteConfirmationForm from "./InviteConfirmForm";
import { useLocation, useNavigate } from "react-router";

export default function InviteSignUpPage() {
  const { t } = useTranslate();
  const { performApiAction, data: res, error, isLoading } = useFetch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  useEffect(() => {
    if (!code) {
      navigate("/app/login");
      return;
    }
    performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/invites/user-org/validate`,
      newData: { code },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (isLoading) {
    return (
      <AuthLayout>
        <i className="icon-[svg-spinners--dot-revolve] text-center text-4xl text-vettiblue-500"></i>
        ;
      </AuthLayout>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t("org.invite.title") + t("general.title-separator") + t("vettify")}
        </title>
      </Helmet>
      <AuthLayout>
        <a
          target="_blank"
          href="https://vettify.io/"
          aria-label={"landing page"}
          rel="noreferrer">
          <Logo className="mb-10 h-10 w-auto" />
        </a>
        {res?.ok && (
          <InviteConfirmationForm
            code={code}
            suggestedUserInfo={res?.data}
            inviteType="org"
          />
        )}
        <div className="flex  items-center">
          <div className="w-full">
            {error && (
              <Alert type="error">
                {error || t("general.form.error.unknown")}
              </Alert>
            )}
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
