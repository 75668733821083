import { Link } from "react-router-dom";
import { type ComponentProps } from "react";

//eslint-disable-next-line
function isLinkProps(props: any): props is ComponentProps<typeof Link> {
  return Object.prototype.hasOwnProperty.call(props, "to");
}

export const ButtonOrLink = (
  props: ComponentProps<"button"> | ComponentProps<typeof Link>
) => {
  if (isLinkProps(props)) {
    return <Link {...props} />;
  }
  return <button {...props} />;
};
