function isLastSeenOverTwoDaysAgo(lastSeen: number) {
  const lastSeenTime = new Date(lastSeen * 1000);

  const currentTime = new Date();

  const twoDaysAgo = new Date(currentTime);
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

  return lastSeenTime < twoDaysAgo;
}
export const getStatus = ({
  active,
  lastSeen,
}: {
  active: boolean;
  lastSeen: number;
}) => {
  if (active) {
    if (lastSeen === 0) {
      return "pending";
    }
    if (isLastSeenOverTwoDaysAgo(lastSeen)) {
      return "inactive";
    }
    if (lastSeen > 0) {
      return "active";
    }
  } else {
    return "revoked";
  }
};

export const getStatusTooltip = ({
  status,
  owned,
}: {
  status: string;
  owned: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any => {
  const statusTooltips: Record<string, string> = {
    pending: owned
      ? "right-status-pending-owned"
      : "right-status-pending-received",
    active: owned
      ? "right-status-active-owned"
      : "right-status-active-received",
    inactive: owned
      ? "right-status-inactive-owned"
      : "right-status-inactive-received",
    revoked: owned
      ? "right-status-revoked-owned"
      : "right-status-revoked-received",
  };
  return statusTooltips[status];
};

export const getStatusStyles = (status: string) => {
  const chipStylesByStatus: Record<string, string> = {
    pending: "border-green-200 bg-green-100 text-green-400",
    active: "border-green-400 bg-green-200 text-green-600",
    inactive: "border-yellow-300 bg-yellow-100 text-yellow-500",
    revoked: "border-red-300 bg-red-100 text-red-500",
  };

  const circleStylesByStatus: Record<string, string> = {
    pending: "bg-green-400",
    active: "bg-green-600",
    inactive: "bg-yellow-400",
    revoked: "bg-red-400",
  };

  return {
    chipStyle: chipStylesByStatus[status],
    circleStyle: circleStylesByStatus[status],
  };
};
