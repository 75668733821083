import { Tooltip } from "primereact/tooltip";
import { useTranslate } from "lib/translate";

function convertUnixTimestamp(timestamp: number) {
  const date = new Date(timestamp * 1000);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${hours}:${minutes}:${seconds} ${month}/${day}/${year}`;
}
// function sortDatesByTimestamp(originLogs: { last_seen: number; origin: string }[]) {
//   const sortedDates = [...originLogs];
//   return sortedDates.sort((a, b) => b.last_seen - a.last_seen);
// }

export default function ActivityLogsTable({
  originLogs,
}: {
  originLogs: {
    origin: string;
    last_seen: number;
    views: number;
    clicks: string;
  }[];
}) {
  const { t } = useTranslate();

  return (
    <div className=" px-4 sm:px-6 lg:px-8">
      <div className="mt-2 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full overflow-auto">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    <div className="flex">
                      {t("sent-asset-view-details-permission-origin-label")}
                      <div>
                        <Tooltip
                          target={"#origin-tooltip"}
                          position="right"
                          content={t("view-details-origin-tooltip")}
                        />
                        <i
                          id="origin-tooltip"
                          className="icon-[ion--information-circle-outline] ml-1 text-center align-middle text-gray-500"></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <div className="flex">
                      {t(
                        "sent-asset-view-details-permission-last-activity-label"
                      )}
                      <div>
                        <Tooltip
                          target={"#last-seen-tooltip"}
                          position="right"
                          content={t("view-details-last-activity-tooltip")}
                        />
                        <i
                          id="last-seen-tooltip"
                          className="icon-[ion--information-circle-outline] ml-1 text-center align-middle text-gray-500"></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <div className="flex">
                      {t("sent-asset-view-details-permission-views-label")}
                      <div>
                        <Tooltip
                          target={"#views-tooltip"}
                          position="right"
                          content={t("view-details-views-tooltip")}
                        />
                        <i
                          id="views-tooltip"
                          className="icon-[ion--information-circle-outline] ml-1 text-center align-middle text-gray-500"></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <div className="flex">
                      {t("sent-asset-view-details-permission-clicks-label")}
                      <div>
                        <Tooltip
                          target={"#origin-clicks"}
                          position="right"
                          content={t("view-details-clicks-tooltip")}
                        />
                        <i
                          id="origin-clicks"
                          className="icon-[ion--information-circle-outline] ml-1 text-center align-middle text-gray-500"></i>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {originLogs?.map((log, idx) => (
                  <tr key={idx} className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {log.origin}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {convertUnixTimestamp(log.last_seen)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {log.views}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {log.clicks}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
