import { Helmet } from "react-helmet";
import { H1 } from "components/shared/Headers";
import Profile from "./Profile";
import OrganizationDetails from "./OrganizationDetails";
import ChangePassword from "./ChangePassword";
import { useTranslate } from "lib/translate";
import Divider from "components/shared/Divider";

const Settings = () => {
  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title>
          {t("settings.tab.title") +
            t("general.title-separator") +
            t("vettify")}
        </title>
        <meta
          name="description"
          content={t("orgs.settings.meta.description")}
        />
      </Helmet>
      <H1>{t("setting.title")}</H1>
      <div>
        <Divider styleOverride="py-4" />
      </div>
      <Profile />
      <div>
        <Divider styleOverride="py-4" />
      </div>
      <ChangePassword />
      <div>
        <Divider styleOverride="py-4" />
      </div>
      <OrganizationDetails />
    </>
  );
};
export default Settings;
