import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { TitleAndText } from "components/shared/TitleAndText";
import Alert from "components/shared/Alert";
import { H1 } from "components/shared/Headers";
import AssetCard from "./AssetCard";
import UploadAssetButton from "./UploadAssetButton";
import Dropzone from "./Dropzone";
import SendAsset from "./SendAsset";
import { useCurrentOrgId } from "lib/hooks/currentOrg";
import useFetch from "lib/fetch-utils";
import type { Asset, UploadOrgAsset } from "lib/types/assets";
import type { TranslationKeys } from "lib/translate";
import { useTranslate } from "lib/translate";
import clientConfig from "lib/client_config";
import { useUserData } from "lib/hooks/UserProvider";
import { useAssetsData } from "lib/hooks/AssetData";

const AssetManagement = () => {
  const [userData, setUserData] = useUserData();
  const [assetsData, setAssetsData] = useAssetsData();
  const { t } = useTranslate();
  const [orgId] = useCurrentOrgId();
  const { performApiAction, isLoading, error, resetStatus } = useFetch();
  const [fileUploadError, setFileUploadError] = useState<
    TranslationKeys | undefined
  >();
  const [isSendAssetOpen, setIsSendAssetOpen] = useState(false);
  const [sendAssetId, setSendAssetId] = useState<number | null>(null);
  const [sortBy, setSortBy] = useState(null);
  const [isAssetsLoaded, setIsAssetsLoaded] = useState(false);
  const currentOrg = userData?.orgs.find(
    (org: { id: number }) => org.id === orgId
  );
  useEffect(() => {
    if (assetsData) {
      setIsAssetsLoaded(true);
    }
  }, [assetsData]);

  const updateFileUploadError = (message: TranslationKeys) =>
    setFileUploadError(message);
  const sendAssetDrawerHandler = ({
    isOpen,
    id,
  }: {
    isOpen: boolean;
    id: number | null;
  }) => {
    setIsSendAssetOpen(isOpen);
    if (id) {
      setSendAssetId(id);
    }
  };
  const sortOptions = [
    { name: t("asset.management.sort-option.asc"), value: "asc" },
    { name: t("asset.management.sort-option.desc"), value: "desc" },
  ];
  const updateSortedData = (filter: string) => {
    const sortedAssets = assetsData.assets?.sort(
      (a: { filename: string }, b: { filename: string }) => {
        const nameA = a.filename.toLowerCase();
        const nameB = b.filename.toLowerCase();
        if (filter === "desc") {
          return nameB.localeCompare(nameA);
        }
        if (filter === "asc") {
          return nameA.localeCompare(nameB);
        }
        return 0;
      }
    );
    setAssetsData({
      ...assetsData,
      assets: sortedAssets,
    });
  };

  const makeOrgIcon = ({
    assetId,
    assetCode,
  }: {
    assetId: number;
    assetCode: string;
  }) => {
    performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/orgs/update`,
      xSession: userData?.session,
      newData: {
        logo: {
          asset_id: assetId,
        },
      },
      onSuccess: () => {
        const updatedUserData = {
          ...userData,
          orgs: userData?.orgs.map((org: { id: number }) =>
            org.id === orgId
              ? {
                  ...currentOrg,
                  logo: {
                    asset_id: assetId,
                    asset_code: assetCode,
                  },
                }
              : org
          ),
        };
        setUserData(updatedUserData);
        localStorage.setItem("user", JSON.stringify(updatedUserData));
      },
    });
  };

  const uploadOrgAsset = async (data: UploadOrgAsset) => {
    return await performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/assets/add`,
      xSession: userData?.session,
      newData: data,
      resultNeeded: true,
    });
  };
  const updateAssets = async () => {
    const response = await performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/assets/all`,
      xSession: userData?.session,
      newData: {
        batchSize: 20,
      },
      resultNeeded: true,
    });
    if (response?.ok) {
      localStorage.setItem("assets", JSON.stringify(response.data));
      setAssetsData(response.data);
    }
  };
  // const deleteHandler = async (id: number) => {
  //   const newAssets = assets!.filter(logo => logo.id !== id);
  //   performApiAction({
  //     url: `/api/v1/org/${orgId}/asset`,
  //     method: "DELETE",
  //     newData: { id: Number(id) },
  //     onSuccess: async () => {
  //       await mutateAssets(newAssets, {
  //         optimisticData: newAssets,
  //         throwOnError: false,
  //       });
  //     },
  //     errorMessage: t("orgs.assets.delete.error.summary"),
  //   });
  // };

  return (
    <div>
      <div>
        <H1>{t("orgs.assets.title")}</H1>
        <TitleAndText text={t("orgs.assets.text")} />
      </div>
      {isAssetsLoaded ? (
        <>
          <div className="mb-4 flex items-center justify-between">
            <UploadAssetButton
              defaultTargetURL={currentOrg?.website}
              isLoading={isLoading}
              updateAssets={updateAssets}
              uploadOrgAsset={uploadOrgAsset}
              updateFileUploadError={updateFileUploadError}
            />
            <div className="card flex justify-center">
              <Dropdown
                disabled={!assetsData || assetsData.assets?.length < 2}
                value={sortBy}
                onChange={(e) => {
                  updateSortedData(e.value);
                  setSortBy(e.value);
                }}
                options={sortOptions}
                optionLabel="name"
                placeholder={t("asset.management.dropdown.placeholder")}
                className="w-full"
              />
            </div>
          </div>

          {fileUploadError && (
            <Alert
              className="my-4"
              type="error"
              resetStatus={() => {
                setFileUploadError(undefined);
              }}>
              {t(fileUploadError)}
            </Alert>
          )}
          {error && (
            <Alert
              type="error"
              className="my-4"
              resetStatus={() => {
                resetStatus("error");
              }}>
              {error}
            </Alert>
          )}

          <>
            <Dropzone
              isLoading={isLoading}
              assets={assetsData?.assets}
              defaultTargetURL={currentOrg?.website}
              uploadOrgAsset={uploadOrgAsset}
              updateFileUploadError={updateFileUploadError}
              updateAssets={updateAssets}>
              <>
                {assetsData &&
                  assetsData?.assets?.map((asset: Asset) => (
                    <AssetCard
                      key={asset.id}
                      asset={asset}
                      currentOrg={currentOrg}
                      makeOrgIcon={makeOrgIcon}
                      sendAssetDrawerHandler={sendAssetDrawerHandler}
                    />
                  ))}
              </>
            </Dropzone>
            <SendAsset
              sendAssetId={sendAssetId}
              sendAssetDrawerHandler={sendAssetDrawerHandler}
              isSendAssetOpen={isSendAssetOpen}
              assets={assetsData.assets || []}
            />
          </>
        </>
      ) : (
        <div className="mt-20 text-center">
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            animationDuration=".5s"
          />
        </div>
      )}
    </div>
  );
};

export default AssetManagement;
