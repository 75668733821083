export const extensionChipStyles = (fileExtension: string | undefined) => {
  const stylesByExtension: Record<string, string> = {
    jpg: "border-yellow-300 bg-yellow-100 text-yellow-500",
    jpeg: "border-yellow-300 bg-yellow-100 text-yellow-500",
    png: "border-green-300 bg-green-100 text-green-500",
    gif: "border-violet-300 bg-violet-100 text-violet-500",
    svg: "border-blue-300 bg-blue-100 text-blue-500",
    webp: "border-cyan-300 bg-cyan-100 text-cyan-500",
    default: "border-gray-300 bg-gray-100 text-gray-500",
  };

  return stylesByExtension[fileExtension || "default"];
};
