import { Image } from "primereact/image";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { ProgressSpinner } from "primereact/progressspinner";
import { Link } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import Logo from "components/shared/Logo";
import { useTranslate } from "lib/translate";
import { useBreakPoint } from "lib/hooks/useBreakPoint";
import clientConfig from "lib/client_config";
import useFetch from "lib/fetch-utils";
import { useNavigate, useLocation } from "react-router-dom";
import ViewSnippetTab from "./ViewSnippetTab";

const BlueCheckIcon = () => {
  return (
    <div className="mr-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none">
        <path
          d="M25.002 0.492822C23.9207 -0.344678 22.4082 -0.0884277 21.6207 1.06157L10.377 17.5116L4.18324 10.8053C3.27074 9.76782 1.73949 9.71782 0.76449 10.6928C-0.21051 11.6616 -0.260511 13.2928 0.658239 14.3241C0.658239 14.3241 8.18324 22.6741 9.26449 23.5116C10.3457 24.3491 11.8582 24.0928 12.6457 22.9428L25.5395 4.08657C26.327 2.93032 26.0832 1.32407 25.002 0.492822Z"
          fill="#477BE7"
        />
      </svg>
    </div>
  );
};

const ExternalViewSnippet = () => {
  const { performApiAction, data, error } = useFetch();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { xxl, sm } = useBreakPoint();
  // eslint-disable-next-line
  const [snippet, setSnippet] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);
  useEffect(() => {
    // Check if user is login
    const userData = localStorage.getItem("user");
    if (data?.ok) {
      setIsExistingUser(!!data.data?.existing_user);
      if (userData) {
        const user = JSON.parse(userData);
        const receivedAssetOrgName = data.data?.existing_user?.orgname;
        const receivedAssetOrgId = user.orgs.find(
          (org: { name: string }) => org.name === receivedAssetOrgName
        )?.id;
        if (receivedAssetOrgId) {
          const updatedCurrentOrg = {
            ...user,
            currentOrg: receivedAssetOrgId,
          };
          const snippetInfo = {
            right: {
              messages: data.data.right?.messages,
              relationship_nature: data.data.right?.relationship_nature,
              target_url: data.data.right?.target_url,
              target_url_type: data.data.right?.target_url_type,
              last_seen: data.data.right?.last_seen,
            },
            asset: {
              filename: data.data.asset.filename,
              asset_code: data.data.asset.asset_code,
              right_code: data.data.asset.right_code,
              alt_attribute: data.data.asset.alt_attribute,
            },
            inviter: {
              firstname: data.data.invited_by.firstname,
              lastname: data.data.invited_by.lastname,
              orgname: data.data.invited_by.orgname,
            },
            date: {
              created_at: data.data.created_at,
              now: data.data.now,
            },
          };
          localStorage.setItem("user", JSON.stringify(updatedCurrentOrg));
          localStorage.setItem("snippet", JSON.stringify(snippetInfo));
          navigate({
            pathname: "/app/dashboard/view-snippet",
            search: `?orgId=${receivedAssetOrgId}`,
          });
        } else {
          setLoading(false);
          setSnippet(data.data);
        }
      } else {
        setSnippet(data.data);
        setLoading(false);
      }
    }
    // Prevent right click to image download
    // document.addEventListener("contextmenu", (e) => {
    //   e.preventDefault();
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.ok]);

  useEffect(() => {
    if (code) {
      performApiAction({
        url: `${clientConfig.edgeApi}/api/v1/invites/asset/validate`,
        newData: { code },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <>
      {loading ? (
        <div className="flex min-h-screen	w-full items-center justify-center">
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            animationDuration=".5s"
          />
        </div>
      ) : (
        <div className="block xl:flex xl:justify-between">
          <div className="p-4 sm:p-10">
            <a
              target="_blank"
              href="https://vettify.io/"
              aria-label={"landing page"}
              rel="noreferrer">
              <Logo className="h-10 w-auto" />
            </a>
            <div className="h-full w-full py-10">
              <h5 className="mb-2 text-2xl font-bold">
                {t("external-view-snippet-title")}
              </h5>
              <div className="mb-4 block md:flex">
                <p className="mr-1">{t("external-view-snippet-description")}</p>
                <a
                  href="https://vettify.io/resources/how-vettify-works"
                  target="_blank"
                  className="mr-1 font-bold text-blue-500 underline"
                  rel="noreferrer">
                  {t("external-view-snippet-link-how-it-works")}
                </a>
                <span className="mr-1">or</span>
                <a
                  href="https://vettify.io/faq"
                  target="_blank"
                  className="font-bold text-blue-500 underline"
                  rel="noreferrer">
                  {t("external-view-snippet-link-FAQ")}
                </a>
                .
              </div>

              {error && <p className=" mt-1 text-red-700">{error}</p>}
              {snippet && (
                <div className="rounded border bg-gray-50 p-6">
                  <div className={clsx(xxl ? "flex" : "block")}>
                    <div className={clsx("", xxl ? "mr-6" : "flex")}>
                      <Tooltip target=".custom-target-icon">
                        {snippet.asset.filename}
                      </Tooltip>
                      <div className="max-w-[300px]">
                        {xxl && (
                          <h6 className="custom-target-icon mb-2 mt-4  break-words text-xl font-semibold">
                            {snippet.asset.filename?.length > 40
                              ? `${snippet.asset.filename.slice(0, 40)}...`
                              : snippet.asset.filename}
                          </h6>
                        )}
                      </div>
                      <div className={clsx(xxl ? "" : "mb-4 mr-2")}>
                        <Image
                          preview
                          src={`https://cdn.vettify.io/asset/file/${snippet.asset.asset_code}`}
                          alt="asset"
                          width={xxl ? "200" : "100"}
                          height={xxl ? "200" : "100"}
                          className={clsx(xxl ? "flex justify-center" : "")}
                          imageClassName={clsx(
                            "  rounded-sm border bg-white object-contain p-2",
                            xxl ? "h-[150px] w-[150px]" : " h-[100px] w-[100px]"
                          )}
                        />
                      </div>

                      <div className="max-w-[350px]">
                        {!xxl && !sm && (
                          <h6 className="custom-target-icon break-words text-lg font-semibold">
                            {snippet.asset.filename?.length > 25
                              ? `${snippet.asset.filename.slice(0, 25)}...`
                              : snippet.asset.filename}{" "}
                          </h6>
                        )}
                        {sm && (
                          <h6 className="custom-target-icon break-words text-lg font-semibold">
                            {snippet.asset.filename?.length > 10
                              ? `${snippet.asset.filename.slice(0, 10)}...`
                              : snippet.asset.filename}{" "}
                          </h6>
                        )}
                      </div>
                    </div>
                    <div className=" lg:max-w-[858px]  xl:max-w-[870px] ">
                      <p className="text-sm">{t("snippet-tab.description")}</p>
                      <ViewSnippetTab snippet={snippet} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="bg-gradient-to-b from-[#061536] from-70% via-[#071738] via-40% to-[#477BE7] to-100% p-4 sm:p-0 xl:w-[550px] ">
            <div className="xxl:px-20 block h-full flex-col items-center  justify-center p-10 sm:flex xl:min-h-screen  xl:py-0">
              <div className="block text-white lg:flex lg:items-center xl:block">
                <div>
                  <h2 className="mb-6 text-2xl font-bold 2xl:text-3xl">
                    {t("external-view-snippet-sidebar-title")}
                  </h2>
                  <ul className="mb-10 mr-0 lg:mr-40 xl:mr-0">
                    <li className="mb-4 flex items-center text-lg">
                      <BlueCheckIcon />
                      {t("external-view-snippet-sidebar-description-1")}
                    </li>
                    <li className="mb-4 flex items-center text-lg">
                      <BlueCheckIcon />
                      {t("external-view-snippet-sidebar-description-2")}
                    </li>
                    <li className="mb-4 flex items-center text-lg">
                      <BlueCheckIcon />
                      {t("external-view-snippet-sidebar-description-3")}
                    </li>
                    <li className="mb-4 flex items-center text-lg">
                      <BlueCheckIcon />
                      {t("external-view-snippet-sidebar-description-4")}
                    </li>
                    <li className="mb-4 flex items-center text-lg">
                      <BlueCheckIcon />
                      {t("external-view-snippet-sidebar-description-5")}
                    </li>
                  </ul>
                </div>
                <div className="text-center lg:flex lg:items-center xl:block">
                  {isExistingUser ? (
                    <Link
                      to={{
                        pathname: "/app/login",
                      }}
                      className="rounded bg-blue-500 p-4 text-center font-semibold text-white">
                      {t("external-view-snippet-existing-user-button")}
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname: "/app/asset-invite",
                        search: `?code=${code}`,
                      }}
                      className="rounded bg-blue-500 p-4 text-center font-semibold text-white">
                      {t("external-view-snippet-get-started-button")}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExternalViewSnippet;
