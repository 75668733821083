import { SecondaryButton, TertiaryButton } from "./button";

const ConfirmModal = ({
  title,
  description,
  buttonTitle,
  closeModal,
  isModalOpen,
  onClickHandler,
}: {
  title: string;
  description: string;
  buttonTitle: string;
  closeModal: () => void;
  isModalOpen: boolean;
  onClickHandler?: () => void;
}) => {
  return (
    <>
      {isModalOpen && (
        <div
          id="popup-modal"
          tabIndex={-1}
          className="fixed inset-x-0 top-0 z-50 flex h-full items-center justify-center bg-black bg-opacity-30"
          onClick={closeModal}>
          <div
            className="relative m-auto w-full max-w-md rounded-lg bg-white shadow-md"
            onClick={(e) => e.stopPropagation()}>
            <div>
              <div className="px-6 pt-6">
                <button
                  type="button"
                  onClick={closeModal}
                  className="absolute end-2.5 top-6 inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-600"
                  data-modal-hide="popup-modal">
                  <span className="icon-[mdi--close] text-2xl" aria-hidden />
                </button>
                <div>
                  <h5 className="text-xl font-semibold">{title}</h5>
                </div>
              </div>

              <div className="px-6 py-4">
                <p>{description}</p>
              </div>
            </div>

            <div>
              <div className="flex justify-end px-6 pb-6">
                <SecondaryButton
                  onClick={() => {
                    closeModal();
                  }}
                  text="Close"
                />
                <TertiaryButton
                  onClick={() => {
                    onClickHandler && onClickHandler();
                  }}
                  text={buttonTitle}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmModal;
