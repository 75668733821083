/* eslint-disable react/jsx-indent */
import type { PropsWithChildren } from "react";
import { Fragment, useEffect, useState } from "react";
import { Listbox } from "@headlessui/react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import logoImg from "assets/logo.svg";
import mobileLogoImg from "assets/mobile_logo.svg";
import { T, useTranslate } from "lib/translate";
import { CurrentOrgProvider, useCurrentOrgId } from "lib/hooks/currentOrg";
import { useBreakPoint } from "lib/hooks/useBreakPoint";
import { UserDataProvider, useUserData } from "lib/hooks/UserProvider";
import clientConfig from "lib/client_config";
import useFetch from "lib/fetch-utils";
import { RightDataProvider, useRightData } from "lib/hooks/RightData";
import { AssetDataProvider, useAssetsData } from "lib/hooks/AssetData";
import TrialBanner from "./TrialBanner";
import Menu from "./Menu";

function OrgSelect() {
  const [userData, setUserData] = useUserData();
  const [, setRightData] = useRightData();
  const [, setAssetsData] = useAssetsData();
  const [, setCurrentOrgId] = useCurrentOrgId();
  const { sm } = useBreakPoint();
  const orgNameLimit = sm ? 15 : 30;
  //eslint-disable-next-line
  const [selectedOrg, setSelectedOrg] = useState<any>(null);
  //eslint-disable-next-line
  const [orgs, setOrgs] = useState<any[]>([]);
  const { performApiAction } = useFetch();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslate();

  useEffect(() => {
    if (userData) {
      const currentOrg = userData?.orgs.find(
        (org: { id: number }) => org.id === userData?.currentOrg
      );
      setSelectedOrg(currentOrg);
      setOrgs(userData.orgs);
    }
  }, [userData]);
  //eslint-disable-next-line
  async function setCurrentOrg(org: any | null) {
    setError("");
    // org might be null if we click on "create new org"
    if (org) {
      try {
        const switchOrgRes = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/users/switch-org`,
          xSession: userData?.session,
          newData: { orgId: org.id },
          resultNeeded: true,
        });
        if (switchOrgRes?.ok) {
          const updatedUserData = {
            ...userData,
            currentOrg: org.id,
            session: switchOrgRes.data.session,
          };
          // Update the user data in the context and local storage
          const rightRequest = await performApiAction({
            url: `${clientConfig.edgeApi}/api/v1/rights/all`,
            xSession: switchOrgRes.data.session,
            newData: {
              batchSize: 20,
            },
            resultNeeded: true,
          });
          const assetsRequest = await performApiAction({
            url: `${clientConfig.edgeApi}/api/v1/assets/all`,
            xSession: switchOrgRes.data.session,
            newData: {
              batchSize: 20,
            },
            resultNeeded: true,
          });
          if (rightRequest?.ok && assetsRequest?.ok) {
            // Updated org UI
            if (updatedUserData?.orgs) {
              const currentOrg = updatedUserData.orgs.find(
                (org: { id: number }) => org.id === userData?.currentOrg
              );
              setSelectedOrg(currentOrg);
              setOrgs(updatedUserData.orgs);
              setCurrentOrgId(org.id);
            }
            // Updated userData
            setUserData(updatedUserData);
            localStorage.setItem("user", JSON.stringify(updatedUserData));
            // Updated rightData
            setRightData(rightRequest.data);
            localStorage.setItem("right", JSON.stringify(rightRequest.data));
            // Updated assets
            setAssetsData(assetsRequest.data);
            localStorage.setItem("assets", JSON.stringify(assetsRequest.data));
            navigate("/app/dashboard");
          } else {
            setError(t(rightRequest?.error) || t("general-fetch-fail-message"));
          }
        } else {
          setError(t(switchOrgRes?.error) || t("general-fetch-fail-message"));
        }
      } catch (error) {
        setError(t("api.error.general.unknown-error"));
      }
    }
  }

  const ButtonContents = () => {
    return (
      <>
        {selectedOrg?.logo?.asset_code ? (
          <Image
            src={`https://cdn.vettify.io/asset/file/${selectedOrg.logo.asset_code}`}
            className="flex h-6 w-6 items-center rounded-sm bg-white object-contain"
          />
        ) : (
          <span
            className="icon-[heroicons--building-office-2] text-2xl"
            aria-hidden
          />
        )}
        <span className="grow truncate">{selectedOrg?.name}</span>
        <span className="icon-[prime--chevron-down] text-xl" aria-hidden />
      </>
    );
  };

  return (
    <Listbox
      as="div"
      className={clsx("relative", !sm ? "w-[300px]" : "w-[200px]")}
      value={selectedOrg ?? null}
      onChange={setCurrentOrg}>
      <Listbox.Button
        className={
          "flex h-12 w-full items-center gap-3 rounded-md bg-gray-700 py-2 pl-2 pr-4 text-left text-base text-slate-100 ring-vettiblue-500 transition-colors hover:bg-gray-600 focus:outline-none focus:ring-2"
        }
        title={selectedOrg?.name}>
        <ButtonContents />
      </Listbox.Button>
      <Listbox.Options
        className={
          "absolute inset-x-0 top-full z-100 mt-2 min-w-max rounded-lg bg-gray-700 py-2 text-gray-200 shadow-lg focus:outline-none max-h-[300px] overflow-y-auto"
        }>
        {orgs?.map((org) => (
          <Listbox.Option as={Fragment} key={org.id} value={org}>
            {({ active, selected }) => {
              return (
                <div
                  className={clsx(
                    "flex w-full items-center gap-3 p-2 text-base hover:cursor-pointer hover:bg-gray-600",
                    active && "bg-gray-600",
                    selected && "font-semibold tracking-wide text-vettiblue-400"
                  )}>
                  {org.logo.asset_code ? (
                    <Image
                      alt="org logo"
                      src={`https://cdn.vettify.io/asset/file/${org.logo.asset_code}`}
                      className="flex h-6 w-6 items-center rounded-sm bg-white object-contain"
                    />
                  ) : (
                    <span
                      className="icon-[heroicons--building-office-2] text-2xl"
                      aria-hidden
                    />
                  )}
                  <div className="grow text-left">
                    {org.name.length > orgNameLimit
                      ? `${org.name.slice(0, orgNameLimit)}...`
                      : org.name}
                  </div>
                </div>
              );
            }}
          </Listbox.Option>
        ))}
        <Listbox.Option as={Fragment} value={null}>
          {({ active, selected }) => (
            <Link
              to="/app/dashboard/new"
              className={clsx(
                "flex items-center gap-3 p-2 text-base hover:cursor-pointer hover:bg-gray-600",
                active && "bg-gray-600",
                selected && "font-semibold tracking-wide text-vettiblue-400"
              )}>
              <span aria-hidden className="icon-[prime--plus] text-2xl" />
              <span className="grow">
                <T keyName="app-shell.org-select.new-org-item" />
              </span>
            </Link>
          )}
        </Listbox.Option>
      </Listbox.Options>
      {error && <p className=" mt-1 text-red-700">{error}</p>}
    </Listbox>
  );
}

function AppShellHeader() {
  const { t } = useTranslate();
  const [userData] = useUserData();
  const { sm } = useBreakPoint();

  const isSubscriptionExpired = (subscription_end: number) => {
    const now = new Date();
    const subscriptionEndTimestamp = subscription_end;
    if (now.getTime() > subscriptionEndTimestamp * 1000) {
      return true;
    } else {
      return false;
    }
  };
  const isLocalStorageExpired = () => {
    const now = new Date();
    const createdAt = localStorage.getItem("created_at");
    const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    if (createdAt) {
      const createdAtTimestamp = new Date(parseInt(createdAt));
      if (
        now.getTime() - createdAtTimestamp.getTime() >
        sevenDaysInMilliseconds
      ) {
        localStorage.clear();
      }
    }
  };
  useEffect(() => {
    isLocalStorageExpired();
  }, []);
  return (
    <>
      <div className="w-full border-l-2 border-l-gray-600 bg-gray-800 px-7 py-4">
        <div className="mx-auto flex w-full max-w-[1440px]  items-center justify-between">
          <Link
            to="/app/dashboard"
            className={clsx("rounded-sm text-center text-gray-100")}>
            <Image
              src={!sm ? logoImg : mobileLogoImg}
              alt="Vettigro Logo"
              className="h-8 w-auto"
              height={!sm ? "80" : "32"}
              width={!sm ? "180" : "32"}
            />
          </Link>
          <div className="flex items-center justify-around">
            <div>
              <OrgSelect />
            </div>
            <Menu className="sm:relative">
              <Menu.Button
                className="relative ml-4 flex items-center gap-2 rounded-full bg-inherit p-1 focus:outline-none focus:ring-2 focus:ring-vettiblue-500"
                aria-label={t("general.header-footer.profile-pic-aria")}>
                {userData && userData.user && (
                  <span className="hidden text-gray-200 sm:block">
                    {userData.user.firstname.length > 15
                      ? userData.user.firstname.substring(0, 15)
                      : userData.user.firstname}{" "}
                    {userData.user.lastname.substring(0, 1).toUpperCase()}
                  </span>
                )}
                {userData && userData.user?.profile_pic ? (
                  <Image
                    className="h-8 w-8 rounded-full"
                    width="32"
                    height="32"
                    src={clientConfig.s3.publicUrl + userData?.user.profile_pic}
                    alt={t("general.header-footer.profile-pic-alt")}
                  />
                ) : (
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-vettiblue-150 text-vettiblue-900">
                    {/* 0x1F464 is a bust in silhouette emoji */}
                    {userData?.user?.firstname &&
                      userData?.user?.firstname.codePointAt(0) &&
                      String.fromCodePoint(
                        userData?.user.firstname.codePointAt(0) as number
                      )}
                  </div>
                )}
              </Menu.Button>
              <Menu.Items className="!inset-x-4 z-100">
                <Menu.Item to="/app/dashboard">
                  <T keyName="general.header-footer.dashboard" />
                </Menu.Item>
                <Menu.Item to="/app/dashboard/analytics">
                  <T keyName="general.header-footer.analytics" />
                </Menu.Item>
                <Menu.Item to="/app/dashboard/invite-org">
                  <T keyName="general.header-footer.invite-org" />
                </Menu.Item>
                <Menu.Item to="/app/dashboard/settings">
                  <T keyName="general.header-footer.settings" />
                </Menu.Item>
                <Menu.Item>
                  <a
                    rel="noreferrer"
                    href="https://vettify.io/contact"
                    target="_blank">
                    <T keyName="general.header-footer.help" />
                  </a>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = "https://vettify.io/";
                  }}>
                  <T keyName="general.header-footer.logout" />
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </div>
      {userData && isSubscriptionExpired(userData?.user?.subscription_end) && (
        <TrialBanner />
      )}
    </>
  );
}

export default function AppShell(props: PropsWithChildren<unknown>) {
  return (
    <UserDataProvider>
      <CurrentOrgProvider>
        <AssetDataProvider>
          <RightDataProvider>
            <div className="flex grow">
              <main className="flex grow flex-col">
                <AppShellHeader />
                <div className="mx-auto h-full w-full px-6 py-10 lg:px-40  lg:py-20 2xl:w-[1440px]">
                  {props.children}
                </div>
              </main>
            </div>
          </RightDataProvider>
        </AssetDataProvider>
      </CurrentOrgProvider>
    </UserDataProvider>
  );
}
