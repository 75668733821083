import { createContext, useContext, useEffect, useState } from "react";

const rightDataContext = createContext<
  // eslint-disable-next-line
  [any, React.Dispatch<React.SetStateAction<any>>] | null
>(null);

export const RightDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [rightData, setRightData] = useState("");

  useEffect(() => {
    const right = localStorage.getItem("right");
    if (right) {
      setRightData(JSON.parse(right));
    }
  }, []);

  return (
    <rightDataContext.Provider value={[rightData, setRightData]}>
      {children}
    </rightDataContext.Provider>
  );
};

export function useRightData() {
  const value = useContext(rightDataContext);
  if (value === null) {
    throw new Error("rightData must be used within RightDataProvider");
  }
  return value;
}
