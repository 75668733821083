import clsx from "clsx";

const classNames = {
  success: {
    wrapper: "bg-green-50",
    headerText: "text-green-700",
    descriptionText: "text-green-600",
  },
  info: {
    wrapper: "bg-vettiblue-50",
    headerText: "text-vettiblue-700",
    descriptionText: "text-vettiblue-600",
  },
  error: {
    wrapper: "bg-red-50",
    headerText: "text-red-700",
    descriptionText: "text-red-600",
  },
};

interface AlertProps {
  header?: string;
  icon?: () => JSX.Element;
  children?: React.ReactNode;
  className?: string;
  type?: keyof typeof classNames;
  // Success/Error Alert card on/off switch
  resetStatus?: () => void;
}

export default function Alert(props: AlertProps) {
  const {
    icon,
    header,
    children,
    className,
    type = "info",
    resetStatus,
  } = props;
  const { wrapper, headerText, descriptionText } = classNames[type];

  const basePadding = header ? "p-4" : "p-2";

  return (
    <div className={clsx(" mt-1 rounded-md", basePadding, wrapper, className)}>
      <div className="flex items-center justify-between">
        <div className="flex">
          {icon && <div className="mr-3 shrink-0">{icon()}</div>}
          {type === "success" && (
            <div className="mr-3 flex shrink-0 items-center">
              <span
                className="icon-[material-symbols--check-circle-outline] text-2xl text-green-500"
                aria-hidden
              />
            </div>
          )}
          {type === "error" && (
            <div className="mr-3 flex shrink-0 items-center">
              <span
                className="icon-[mdi--alert-circle] text-2xl text-red-500"
                aria-hidden
              />
            </div>
          )}
          {header ? (
            <div>
              {header && (
                <h3 className={clsx("text-sm font-medium", headerText)}>
                  {header}
                </h3>
              )}
              <div className={clsx("mt-2 text-sm", descriptionText)}>
                <p>{children}</p>
              </div>
            </div>
          ) : (
            <div className={clsx("m-0 text-sm", descriptionText)}>
              <p>{children}</p>
            </div>
          )}
        </div>
        {resetStatus && (
          <div>
            <span
              onClick={resetStatus}
              className="text-md icon-[material-symbols--cancel-outline] cursor-pointer text-gray-400"
              aria-hidden
            />
          </div>
        )}
      </div>
    </div>
  );
}
