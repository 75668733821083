import { useNavigate } from "react-router";
import { PrimaryButton } from "./shared/button";
import { useTranslate } from "lib/translate";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import { Image } from "primereact/image";
import { useBreakPoint } from "lib/hooks/useBreakPoint";
import logo from "assets/official_vettify_logo_Black.svg";

const NotFound = () => {
  const { sm } = useBreakPoint();
  const { t } = useTranslate();
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>
          {t("not-found.tab.title") +
            t("general.title-separator") +
            t("vettify")}
        </title>
      </Helmet>
      <div>
        <div className="mx-auto flex w-full items-center  justify-between p-5 sm:px-20 ">
          <div>
            <a
              rel="noreferrer"
              href="https://vettify.io/"
              className={"rounded-sm text-center text-gray-100"}>
              <Image
                src={logo}
                alt="Official Vettify Logo"
                className="h-8 w-auto"
                height={!sm ? "64" : "64"}
                width={!sm ? "180" : "180"}
              />
            </a>
          </div>
        </div>{" "}
      </div>
      <div className="flex flex-col justify-center items-center text-center mx-auto max-w-[700px] min-h-[calc(100vh-86px)]">
        <h1 className="font-extrabold text-8xl text-[#467BE7]">
          {t("not-found.title")}
        </h1>
        <h2
          className={clsx(
            "font-extrabold",
            sm ? "text-[32px] " : " text-[64px] "
          )}>
          {t("not-found.subtitle")}
          <span className="text-[#f56687]">.</span>
        </h2>
        <p
          className={clsx(
            "text-[#5b5e76] text-2xl mb-4",
            sm ? "text-lg" : "text-2xl"
          )}>
          {t("not-found.desc")}
        </p>
        <PrimaryButton
          text={t("not-found.button")}
          type="button"
          onClick={() => navigate("/")}
          styleOverride="px-[24px] py-[12px] text-lg"
        />
      </div>
    </div>
  );
};

export default NotFound;
