import clsx from "clsx";
import type { ReactElement } from "react";
import { useState } from "react";
import Alert from "components/shared/Alert";
import { checkValidity, uploadFiles } from "lib/fileUpload";
import type { Asset, UploadOrgAsset } from "lib/types/assets";
import type { TranslationKeys } from "lib/translate";
import { useTranslate } from "lib/translate";

interface DropzoneProps {
  children: ReactElement;
  assets: Asset[] | [] | undefined;
  defaultTargetURL: string | undefined;
  isLoading: boolean;
  // eslint-disable-next-line
  uploadOrgAsset: (data: UploadOrgAsset) => Promise<any>;
  updateFileUploadError: (message: TranslationKeys) => void;
  updateAssets: () => void;
}

const Dropzone = ({
  isLoading,
  assets,
  defaultTargetURL,
  uploadOrgAsset,
  updateFileUploadError,
  updateAssets,
  children,
}: DropzoneProps) => {
  const { t } = useTranslate();
  const [dragging, setDragging] = useState(false);
  const [failedFileNames, setFailedFileNames] = useState<string[]>([]);
  const addFailedFileNames = (fileNames: string[]) => {
    setFailedFileNames([...failedFileNames, ...fileNames]);
  };
  // eslint-disable-next-line
  const onDropHandler = (event: any) => {
    event.preventDefault();
    setDragging(false);

    // When uploadOrgAsset is still running, don't allow to upload more files.
    if (isLoading) {
      return;
    }
    const validityMessage = checkValidity(event.dataTransfer?.files);

    if (validityMessage) {
      updateFileUploadError(validityMessage);
      return;
    }

    uploadFiles({
      files: Array.from(event.dataTransfer?.files || []),
      defaultTargetURL,
      uploadOrgAsset,
      addFailedFileNames,
      updateAssets,
    });
  };

  return (
    <div>
      {failedFileNames.length > 0 && (
        <Alert
          type="error"
          className="my-5"
          resetStatus={() => {
            setFailedFileNames([]);
          }}>
          {t("orgs.add-asset.toast.error")}
          <ul className="list-inside list-disc">
            {failedFileNames.map((fileName, index) => (
              <li key={index}>{fileName}</li>
            ))}
          </ul>
        </Alert>
      )}
      <div className="relative flex h-full w-full flex-col">
        <div
          className={clsx(
            "absolute -inset-x-4 -inset-y-6 p-2 transition-opacity sm:-inset-x-6 lg:-inset-x-8",
            dragging ? "z-50 opacity-100" : "opacity-0",
            assets?.length === 0 ? "z-50 opacity-100" : "opacity-0"
          )}
          onDragOver={(event) => {
            event.preventDefault();
            setDragging(true);
          }}
          onDrop={(event) => onDropHandler(event)}
          onDragLeave={(event) => {
            event.preventDefault();
            setDragging(false);
          }}>
          <div
            className={clsx(
              " mt-5 flex w-full  items-center justify-center rounded-2xl border-2 border-dashed border-vettiblue-700 bg-vettiblue-100/90 transition-opacity",
              assets?.length === 0 ? "h-[300px] " : " h-full "
            )}>
            <div>
              <div className="flex flex-col items-center">
                <span
                  className="icon-[heroicons--cloud-arrow-up] mx-auto text-4xl text-gray-500 lg:text-[5rem]"
                  aria-hidden
                />
                <div className="mt-2 text-sm font-semibold text-gray-900">
                  {t("orgs.add-asset.upload-overlay.title")}
                </div>
                <div className="mt-1 text-sm text-gray-700">
                  {t("orgs.add-asset.upload-overlay.subtitle")}
                </div>
                <div className="mt-1 font-mono text-sm text-gray-700">
                  {t("orgs.add-asset.upload-overlay.description")}
                </div>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Dropzone;
