import { useState } from "react";
import { useTranslate } from "./translate";
import type { TranslationKeys } from "./translate";
import type {
  FetchResult,
  RequestParams,
  ResetStatusParam,
  Result,
  performApiActionParams,
} from "./types/result";

type FetchParams = Parameters<typeof fetch>;

export async function tryFetch<Data = never>(
  ...args: FetchParams
): Promise<Result<Data, TranslationKeys>> {
  try {
    const res = await fetch(...args);

    const data = await res.json();

    // null is a valid response
    if (data?.error || data?.err) {
      return {
        ok: false,
        error:
          data.error?.message ||
          data.err?.message ||
          data.status.msg ||
          data.status?.issues[0]?.message,
      };
    }

    return { ok: true, data };
  } catch (error) {
    if (typeof error === "string") {
      return { ok: false, error: error as TranslationKeys };
    }
    if (error instanceof Error) {
      return { ok: false, error: error.message as TranslationKeys };
    }

    console.error("Unknown error type", error);
    return { ok: false, error: "api.error.general.unknown-error" };
  }
}

/**
All the fetch function should use this hook to handle loading, error, data
Ideally, use GET with swr other request with this hook
data.ok is true if request is success but would be good with clear name like data.success. comeback later
*/
export default function useFetch<Data = never>(): FetchResult {
  const { t } = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>(null);

  // Use for GET request
  async function request({ url, method, headers, body }: RequestParams) {
    const args: FetchParams = [
      url,
      {
        method,
        headers,
        body,
      },
    ];
    // Reset status
    setError(null);
    setIsLoading(true);
    setData(null);
    try {
      const res = await tryFetch<Data>(...args);
      if (!res.ok) {
        setError(t(res.error));
      }
      setData(res);
    } catch (error) {
      setError(t("api.error.general.unknown-error"));
    } finally {
      setIsLoading(false);
    }
  }
  interface HeadersType {
    [key: string]: string;
  }
  // Use for other request
  async function performApiAction({
    url,
    xSession = "",
    newData,
    method = "POST",
    onSuccess,
    errorMessage,
    resultNeeded = false,
  }: performApiActionParams) {
    // Reset status
    setError(null);
    setData(null);
    setIsLoading(true);
    const headers: HeadersType = {
      "Content-Type": "application/json",
    };
    if (xSession) {
      headers["x-session"] = xSession;
    }

    try {
      const res = await tryFetch(url, {
        method,
        headers,
        body: JSON.stringify(newData),
      });
      if (!res.ok) {
        setError(errorMessage || t(res.error));
        return resultNeeded ? res : null;
      }

      // For get success data status data.ok
      setData({
        ...res,
      });
      // For mutate data or other action after success
      if (onSuccess) {
        onSuccess();
      }
      // Used for setting page mutate update, upload asset...
      if (resultNeeded) {
        return res;
      }
    } catch (e) {
      setError(errorMessage || t("api.error.general.unknown-error"));
    } finally {
      setIsLoading(false);
    }
  }
  // Handle Alert on/off
  function resetStatus(status: ResetStatusParam) {
    if (status === "error") {
      setError(null);
    }
    if (status === "success") {
      setData(null);
    }
  }
  return { request, performApiAction, resetStatus, data, error, isLoading };
}
// Handle form data for POST request
export const getFormBodyObject = (form: HTMLFormElement) => {
  const formData = new FormData(form);
  const bodyObject: Record<string, string> = {};
  for (const [key, value] of formData.entries()) {
    bodyObject[key] = value as string;
  }
  return bodyObject;
};
