import { Helmet } from "react-helmet";
import { Image } from "primereact/image";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import Prism from "prismjs";
import clsx from "clsx";
import { ProgressSpinner } from "primereact/progressspinner";
import "prismjs/themes/prism-tomorrow.css";
import { H1 } from "components/shared/Headers";
import { useTranslate } from "lib/translate";
import { useBreakPoint } from "lib/hooks/useBreakPoint";
import { getStatus, getStatusStyles, getStatusTooltip } from "lib/rightStatus";
import { Code } from "components/Code";
import Divider from "components/shared/Divider";
const Snippet = ({ code }: { code: string }) => {
  const { t } = useTranslate();
  const tabs = [
    {
      name: t("external-view-snippet-title-code"),
      current: true,
      value: "snippetCode",
      description: t("external-view-snippet-code-description"),
    },
  ];
  const [selected, setSelected] = useState(tabs);
  useEffect(() => {
    const highlight = async () => {
      await Prism.highlightAll();
    };
    highlight();
  }, []);
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          {t("snippet-tab.label")}
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current)?.name}>
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {selected.map((tab, idx) => (
              <div key={idx}>
                <Tooltip target=".custom-target-icon" />
                <button
                  data-pr-tooltip={tab.description}
                  data-pr-position="right"
                  data-pr-at="right+5 center"
                  data-pr-my="left center-2"
                  key={tab.name}
                  onClick={() => {
                    setSelected(
                      selected.map((item) => {
                        if (item.name === tab.name) {
                          return { ...item, current: true };
                        } else {
                          return { ...item, current: false };
                        }
                      })
                    );
                  }}
                  className={clsx(
                    "custom-target-icon",
                    tab.current
                      ? "border-blue-500 text-blue-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}>
                  {tab.name}
                  <span className="icon-[ion--information-circle-outline] ml-2"></span>
                </button>
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className="mb-8 mt-4 flex max-w-[350px] flex-col  overflow-auto sm:max-w-[400px] md:max-w-[600px] lg:max-w-[858px]">
        <Code code={code} id="snippet-demo-code" canCopy />
      </div>
    </div>
  );
};

const PageContents = () => {
  const { t } = useTranslate();
  //eslint-disable-next-line
  const [snippetInfo, setSnippetInfo] = useState<any>("");
  const [isLoading, setIsLoading] = useState(true);
  const [copyErrorMessage, setCopyErrorMessage] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const targetLink = `https://cdn.vettify.io/asset/link/${snippetInfo.asset?.right_code}`;
  const { sm } = useBreakPoint();
  const status = getStatus({
    active: true,
    lastSeen: snippetInfo.right?.last_seen,
  });
  const { chipStyle, circleStyle } = getStatusStyles(status as string);

  const code = `
  <div style="position: relative; width: 100%; height: 100%; text-align: center">
    <a href="${targetLink}" target="_blank">
      <img style="max-width: 100%; max-height: 100%; width: auto; height: auto;" src="https://cdn.vettify.io/asset/view/${
        snippetInfo.asset?.right_code
      }/${encodeURIComponent(snippetInfo.asset?.filename)}" alt="${
    snippetInfo.asset?.alt_attribute
  }"/>
    </a>
  </div>
  `;
  async function handleCopy(text: string) {
    setCopyErrorMessage("");
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (err) {
      setIsCopied(false);
      setCopyErrorMessage("Failed to copy. Please try again");
    }
  }
  useEffect(() => {
    const highlight = async () => {
      await Prism.highlightAll();
    };
    const snippetJSON = localStorage.getItem("snippet");
    if (snippetJSON) {
      const snippet = JSON.parse(snippetJSON);
      setSnippetInfo(snippet);
      setIsLoading(false);
      highlight();
    }
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="flex min-h-screen w-full items-center justify-center">
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            animationDuration=".5s"
          />
        </div>
      ) : (
        <>
          <H1>{t("view-snippet-page-title")}</H1>
          <div className="my-4">{t("view-snippet-page-description")}</div>
          <div></div>

          <div className="w-full overflow-auto lg:max-w-[858px]">
            <div className="mb-6">
              <h5 className="text-xl">{t("view-snippet-page-image-label")}</h5>
              <Divider styleOverride="py-4" />
              <p className="mb-2">{snippetInfo.asset?.filename}</p>
              <Image
                preview
                imageClassName=" h-[80px] w-[80px] object-contain rounded-sm p-2 border bg-gray-50"
                src={`https://cdn.vettify.io/asset/file/${snippetInfo.asset?.asset_code}`}
                alt="asset"
              />
            </div>
            <div className="mb-6 ">
              <h5 className="text-xl">
                {t("sent-asset-view-details-form-status-label")}
              </h5>
              <Divider styleOverride="py-4" />
              <Tooltip
                target={"#status-tooltip"}
                position="top"
                content={`${t(
                  getStatusTooltip({ status: status || "", owned: false })
                )}`}
              />
              <div
                id={"status-tooltip"}
                className={clsx(
                  " mr-2 flex max-w-[100px] items-center rounded-full border px-3",
                  chipStyle
                )}>
                <div
                  className={clsx(
                    "mr-2 h-[10px] w-[10px] rounded-full",
                    circleStyle
                  )}></div>
                <p className="text-sm capitalize">{status}</p>
              </div>
            </div>
            <div className="mb-6">
              <h5 className="text-xl">
                {t("sent-asset-view-details-form-date-label")}
              </h5>
              <Divider styleOverride="py-4" />
              <p className="mb-2">
                {t("sent-asset-view-details-form-date-field")}{" "}
                {new Date(
                  (snippetInfo.date?.created_at as number) * 1000
                ).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
              </p>
            </div>
            <div className="mb-6">
              <h5 className="text-xl">
                {t("sent-asset-view-details-form-sender-label")}
              </h5>
              <Divider styleOverride="py-4" />
              <p className="mb-2">
                {t("sent-asset-view-details-form-sender-name")}{" "}
                {snippetInfo.inviter?.firstname} {snippetInfo.inviter?.lastname}
              </p>
              <p className="mb-2">
                {t("sent-asset-view-details-form-sender-orgname")}{" "}
                {snippetInfo.inviter?.orgname}
              </p>
            </div>
            <div className="mb-6">
              <h5 className="text-xl">
                {t("sent-asset-view-details-form-target-link")}
              </h5>
              <Divider styleOverride="py-4" />
              <div className="flex">
                <p className="mb-2 mr-1">
                  {t("sent-asset-view-details-form-target-link-URL")}
                </p>
                <a
                  href={targetLink}
                  target="_blank"
                  className="text-blue-500 underline"
                  rel="noreferrer">
                  {snippetInfo.right?.target_url_type !== 2 ? (
                    <>
                      {sm &&
                        (snippetInfo.right.target_url.length > 25
                          ? `${snippetInfo.right.target_url.slice(0, 25)}...`
                          : snippetInfo.right.target_url)}
                      {!sm &&
                        (snippetInfo.right.target_url.length > 60
                          ? `${snippetInfo.right.target_url.slice(0, 60)}...`
                          : snippetInfo.right.target_url)}
                    </>
                  ) : (
                    " Attestation page"
                  )}
                </a>
              </div>
            </div>
            {snippetInfo.right?.relationship_nature && (
              <div className="mb-6">
                <h5 className="text-xl">
                  {t("sent-asset-view-details-form-relationship-nature")}
                </h5>
                <Divider styleOverride="py-4" />
                <div className="flex">
                  <p className="mb-2">
                    {t(
                      "sent-asset-view-details-form-target-relationship-nature"
                    )}{" "}
                    {snippetInfo.right?.relationship_nature}
                  </p>
                </div>
              </div>
            )}
            <Snippet code={code} />
          </div>
          <button
            onClick={() => {
              handleCopy(code);
            }}
            className="rounded bg-blue-500 px-4 py-2 text-center font-semibold text-white">
            {isCopied ? "Copied" : t("external-view-snippet-copy-button")}
          </button>
          {copyErrorMessage && (
            <p className=" mt-1 text-red-700">{copyErrorMessage}</p>
          )}
        </>
      )}
    </div>
  );
};

export default function ViewSnippet() {
  const { t } = useTranslate();
  return (
    <>
      <Helmet>
        <title>
          {t("view-snippet.title") +
            t("general.title-separator") +
            t("vettify")}
        </title>
        <meta name="description" content={t("view-snippet.description")} />
      </Helmet>
      <PageContents />
    </>
  );
}
