import { forwardRef } from "react";

import { T } from "lib/translate";
import { Switch, SwitchProps } from "components/shared/Fields";

const AgreeToPolicies = forwardRef<HTMLButtonElement, SwitchProps>(
  (props, ref) => {
    return (
      <div className="sm:col-span-2">
        <Switch ref={ref} {...props} screenReaderHint="Agree to policies">
          <span className="text-sm">
            <T keyName="agree-to-policies.before" />{" "}
            <a
              href="https://vettify.io/legal/privacy/1"
              target="_blank"
              className="font-medium text-blue-500 underline"
              rel="noreferrer">
              <T keyName="agree-to-policies.privacy-policy" />
            </a>{" "}
            <T keyName="agree-to-policies.between" />{" "}
            <a
              href="https://vettify.io/legal/terms/1"
              target="_blank"
              className="font-medium text-blue-500 underline"
              rel="noreferrer">
              <T keyName="agree-to-policies.terms-of-use" />
            </a>
            <T keyName="agree-to-policies.after" />
          </span>
        </Switch>
      </div>
    );
  }
);

export default AgreeToPolicies;
