import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Image } from "primereact/image";
import { ProgressSpinner } from "primereact/progressspinner";
import clsx from "clsx";
import { H1 } from "components/shared/Headers";
import useFetch from "lib/fetch-utils";
import { useTranslate } from "lib/translate";
import clientConfig from "lib/client_config";
import { useBreakPoint } from "lib/hooks/useBreakPoint";
import checkMarkIcon from "assets/check_mark.svg";
import { RightsAttestationResponse } from "api-types/v1/rights/attestation";

const BlueCheckIcon = () => {
  return (
    <div className="mr-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none">
        <path
          d="M25.002 0.492822C23.9207 -0.344678 22.4082 -0.0884277 21.6207 1.06157L10.377 17.5116L4.18324 10.8053C3.27074 9.76782 1.73949 9.71782 0.76449 10.6928C-0.21051 11.6616 -0.260511 13.2928 0.658239 14.3241C0.658239 14.3241 8.18324 22.6741 9.26449 23.5116C10.3457 24.3491 11.8582 24.0928 12.6457 22.9428L25.5395 4.08657C26.327 2.93032 26.0832 1.32407 25.002 0.492822Z"
          fill="#477BE7"
        />
      </svg>
    </div>
  );
};

const Content = () => {
  const { t } = useTranslate();
  const { performApiAction, data, error } = useFetch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const { xl } = useBreakPoint();
  //
  const [attestationData, setAttestationData] =
    useState<null | RightsAttestationResponse>(null);

  useEffect(() => {
    if (data?.ok) {
      setAttestationData(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (code) {
      performApiAction({
        url: `${clientConfig.edgeApi}/api/v1/rights/attestation`,
        newData: { right_code: code },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);
  return (
    <>
      <div>
        <div className="justify-between sm:block xl:flex">
          <div className="w-full">
            {xl && (
              <H1 className="px-20 sm:text-lg ">{t("attestation.title")}</H1>
            )}
            {attestationData ? (
              <div className="mx-auto w-full max-w-[900px] bg-gray-50 py-10  xl:mt-20">
                <div className="flex justify-around">
                  <div>
                    <div
                      className={clsx(
                        "flex h-20 w-20  items-center justify-center rounded-full border p-6 sm:h-40 sm:w-40",
                        attestationData.owner.logo.asset_code ? "p-6" : ""
                      )}>
                      {attestationData.owner.logo.asset_code ? (
                        <Image
                          preview
                          src={`https://cdn.vettify.io/asset/file/${attestationData.owner.logo.asset_code}`}
                          alt={attestationData.owner.name}
                          width="100%"
                          height="100%"
                        />
                      ) : (
                        <p className="break-words font-medium">
                          {attestationData.owner.name.length > 16
                            ? `${attestationData.owner.name?.slice(0, 16)}...`
                            : attestationData.owner.name}
                        </p>
                      )}
                    </div>
                    <div className="max-w-[150px] sm:max-w-[300px]">
                      <div className="my-2">
                        <p className="font-semibold">
                          {t("attestation.org.label")}
                        </p>
                        {xl ? (
                          <p>{attestationData.owner.name}</p>
                        ) : (
                          <p>
                            {attestationData.owner.name.length > 16
                              ? `${attestationData.owner.name?.slice(0, 16)}...`
                              : attestationData.owner.name}
                          </p>
                        )}
                      </div>
                      <div className="mb-10">
                        <p className="font-semibold">
                          {t("attestation.website.label")}
                        </p>
                        {xl ? (
                          <a
                            rel="noreferrer"
                            href={attestationData.owner.website}
                            target="_blank"
                            className="text-blue-500 underline">
                            {" "}
                            {attestationData.owner.website}
                          </a>
                        ) : (
                          <a
                            rel="noreferrer"
                            href={attestationData.owner.website}
                            target="_blank"
                            className="text-blue-500 underline">
                            {attestationData.owner.website.length > 16
                              ? `${attestationData.owner.website?.slice(
                                  0,
                                  16
                                )}...`
                              : attestationData.owner.website}
                          </a>
                        )}
                      </div>
                      {!xl && (
                        <>
                          <div>
                            <p className="font-semibold">
                              {t("attestation.relationship-nature.label")}
                            </p>
                            {attestationData.relationship_nature}
                          </div>
                          <div>
                            <p className="font-semibold">
                              {t("attestation.date")}
                            </p>
                            {new Date(
                              attestationData.created_at * 1000
                            ).toLocaleDateString("en-US", {
                              month: "short",
                              day: "2-digit",
                              year: "numeric",
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {xl && (
                    <div>
                      <div className="xl:mx-10">
                        <div className="flex h-40 w-40 items-center justify-center ">
                          <Image
                            src={checkMarkIcon}
                            alt="verify"
                            width={xl ? "40" : "20"}
                            height="40"
                          />
                        </div>

                        <div className="max-w-[300px]">
                          <div className="my-2">
                            <p className="font-semibold">
                              {t("attestation.relationship-nature.label")}
                            </p>
                            {attestationData.relationship_nature}
                          </div>
                          <div>
                            <p className="font-semibold">
                              {t("attestation.date")}
                            </p>
                            {new Date(
                              attestationData.created_at * 1000
                            ).toLocaleDateString("en-US", {
                              month: "short",
                              day: "2-digit",
                              year: "numeric",
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!xl && (
                    <div className="mt-10 flex justify-center sm:mt-20">
                      <Image
                        src={checkMarkIcon}
                        alt="verify"
                        width={xl ? "40" : "20"}
                        height="40"
                      />
                    </div>
                  )}

                  <div>
                    <div
                      className={clsx(
                        "flex h-20 w-20  items-center justify-center rounded-full border p-6 sm:h-40 sm:w-40",
                        attestationData.share.logo.asset_code ? "p-6" : ""
                      )}>
                      {attestationData.share.logo.asset_code ? (
                        <Image
                          preview
                          src={`https://cdn.vettify.io/asset/file/${attestationData.share.logo.asset_code}`}
                          alt={attestationData.share.name}
                          width="100%"
                          height="100%"
                        />
                      ) : (
                        <p className="break-words font-medium">
                          {attestationData.share.name.length > 16
                            ? `${attestationData.share.name?.slice(0, 16)}...`
                            : attestationData.share.name}
                        </p>
                      )}
                    </div>
                    <div className=" max-w-[300px] break-words">
                      <div className="my-2">
                        <p className="font-semibold">
                          {t("attestation.org.label")}
                        </p>
                        {xl ? (
                          <p>{attestationData.share.name}</p>
                        ) : (
                          <p>
                            {attestationData.share.name.length > 16
                              ? `${attestationData.share.name?.slice(0, 16)}...`
                              : attestationData.share.name}
                          </p>
                        )}
                      </div>
                      <div className="mb-2">
                        <p className="font-semibold">
                          {t("attestation.website.label")}
                        </p>
                        {xl ? (
                          <a
                            href={attestationData.share.website}
                            target="_blank"
                            className="text-blue-500 underline"
                            rel="noreferrer">
                            {" "}
                            {attestationData.share.website}
                          </a>
                        ) : (
                          <a
                            href={attestationData.share.website}
                            target="_blank"
                            className="text-blue-500 underline"
                            rel="noreferrer">
                            {attestationData.share.website.length > 16
                              ? `${attestationData.share.website?.slice(
                                  0,
                                  16
                                )}...`
                              : attestationData.share.website}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : error ? (
              <div className="pl-20 text-red-500">{error}</div>
            ) : (
              <div className="m-20 flex justify-center">
                <ProgressSpinner />
              </div>
            )}
          </div>
          <div className="bg-gradient-to-b from-[#061536] from-70% via-[#071738] via-40% to-[#477BE7] to-100% p-4 sm:p-0 xl:w-[700px] ">
            <div className=" xxl:px-20 block h-full flex-col  items-center justify-center p-10 sm:flex xl:h-[calc(100vh-86px)]  xl:py-0">
              <div className="block text-white lg:flex lg:items-center xl:block">
                <div>
                  <h2 className="mb-6 text-2xl font-bold 2xl:text-3xl">
                    {t("external-view-snippet-sidebar-title")}
                  </h2>
                  <ul className="mb-10 mr-0 lg:mr-40 xl:mr-0">
                    <li className="mb-4 flex items-center text-lg">
                      <BlueCheckIcon />
                      {t("external-view-snippet-sidebar-description-1")}
                    </li>
                    <li className="mb-4 flex items-center text-lg">
                      <BlueCheckIcon />
                      {t("external-view-snippet-sidebar-description-2")}
                    </li>
                    <li className="mb-4 flex items-center text-lg">
                      <BlueCheckIcon />
                      {t("external-view-snippet-sidebar-description-3")}
                    </li>
                    <li className="mb-4 flex items-center text-lg">
                      <BlueCheckIcon />
                      {t("external-view-snippet-sidebar-description-4")}
                    </li>
                    <li className="mb-4 flex items-center text-lg">
                      <BlueCheckIcon />
                      {t("external-view-snippet-sidebar-description-5")}
                    </li>
                  </ul>
                </div>
                <div className="text-center lg:flex lg:items-center xl:block">
                  <a
                    href="https://vettify.io/"
                    className="rounded bg-blue-500 p-4 text-center font-semibold text-white">
                    {t("external-view-snippet-get-started-button")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
