import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function RightSide() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_20_117)">
        <g filter="url(#filter0_f_20_117)">
          <path d="M128.6 400H0V77.8L307 291L128.6 400Z" fill="#7C87F8"></path>
          <path d="M0 77.8V0H240H400L307 291L0 77.8Z" fill="#BD6EEE"></path>
          <path d="M400 400H128.6L307 291L400 0V400Z" fill="#96ACFF"></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_20_117"
          x="-159.933"
          y="-159.933"
          width="719.867"
          height="719.867"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"></feBlend>
          <feGaussianBlur
            stdDeviation="79.9667"
            result="effect1_foregroundBlur_20_117"></feGaussianBlur>
        </filter>
      </defs>
    </svg>
  );
}

export default function AuthLayout({ children }) {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/");
      setUserData(JSON.parse(user));
    }
  }, []);

  return (
    <>
      <div className="min-h-screen relative flex grow lg:px-0">
        <div className="relative z-10 flex flex-1 flex-col bg-white px-4 py-12 md:flex-none md:px-28">
          <div className="relative mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            {userData ? (
              <div className="flex h-6 min-h-[50vh] w-full min-w-[64px] flex-col items-center justify-center">
                <i className="icon-[svg-spinners--dot-revolve] text-center text-4xl text-vettiblue-500"></i>
              </div>
            ) : (
              children
            )}
          </div>
        </div>
        <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
          <div className="absolute inset-0 h-full w-full">
            <RightSide />
          </div>
        </div>
      </div>
    </>
  );
}
