import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SignUpFork() {
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("user");

  useEffect(() => {
    if (userInfo) {
      navigate("/app/dashboard");
    } else {
      navigate("/app/login");
    }
  }, [userInfo]);

  return null;
}
