import clsx from "clsx";
import { Image } from "primereact/image";
import { useBreakPoint } from "lib/hooks/useBreakPoint";
import logo from "assets/official_vettify_logo_Black.svg";
import { useNavigate, useLocation } from "react-router";
import useFetch from "lib/fetch-utils";
import clientConfig from "lib/client_config";
import { useEffect, useState } from "react";
import Alert from "components/shared/Alert";
import { useTranslate } from "lib/translate";

const EmailConfirmationForm = () => {
  const { sm } = useBreakPoint();
  const { t } = useTranslate();
  const { performApiAction } = useFetch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const [confirmError, setConfirmError] = useState("");

  // When the component mounts, we want to send a request to the server to confirm the email
  useEffect(() => {
    const submitConfirmEmail = async () => {
      try {
        const emailConfirmResponse = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/users/confirm-email`,
          newData: { code },
          resultNeeded: true,
        });
        if (emailConfirmResponse?.ok) {
          const userSession = emailConfirmResponse.data.session;
          const rightResponse = await performApiAction({
            url: `${clientConfig.edgeApi}/api/v1/rights/all`,
            xSession: userSession,
            newData: {
              batchSize: 20,
            },
            resultNeeded: true,
          });
          localStorage.setItem(
            "user",
            JSON.stringify(emailConfirmResponse.data)
          );
          localStorage.setItem(
            "created_at",
            JSON.stringify(new Date().getTime())
          );
          localStorage.setItem("assets", JSON.stringify({ assets: [] }));
          localStorage.setItem("right", JSON.stringify(rightResponse.data));
          navigate("/app/dashboard");
        } else {
          setConfirmError(t("signup-confirm-error"));
        }
      } catch (e) {
        setConfirmError(t("signup-confirm-error"));
      }
    };
    if (code) {
      submitConfirmEmail();
    }
  }, [code]);

  return (
    <div>
      {confirmError ? (
        <div className="mx-auto max-w-[300px] sm:max-w-[400px] min-h-screen flex justify-center items-center">
          <div className="bg-gray-100 p-10 rounded-lg">
            <div className="mb-4 flex justify-center">
              <a
                href="https://vettify.io/"
                className={clsx("rounded-sm text-center text-gray-100")}>
                <Image
                  src={logo}
                  alt="Official Vettify Logo"
                  className="h-8 w-auto"
                  height={!sm ? "80" : "80"}
                  width={!sm ? "160" : "160"}
                />
              </a>
            </div>
            <div className="flex flex-col justify-center">
              <div className="mb-4">
                <h1 className="text-2xl text-center	font-semibold mb-2">
                  {t("signup-confirm-page-error-head")}
                </h1>
                <p>
                  {t("signup-confirm-page-error-desc-before-link")}
                  <a
                    href="https://vettify.io/contact"
                    className="text-blue-600 underline">
                    {t("signup-confirm-page-error-desc-link")}
                  </a>

                  {t("signup-confirm-page-error-desc-after-link")}
                </p>
              </div>

              <div className="mt-2">
                <Alert type="error">{confirmError}</Alert>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // When the confirmation is successful, we don't want to show anything and redirect to the dashboard
        <></>
      )}
    </div>
  );
};

export default EmailConfirmationForm;
