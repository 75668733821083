import clsx from "clsx";
import { Image } from "primereact/image";
import { Link } from "react-router-dom";
import { useBreakPoint } from "lib/hooks/useBreakPoint";
import logo from "assets/official_vettify_logo_Black.svg";
const Header = () => {
  const { sm } = useBreakPoint();

  return (
    <div className="mx-auto flex w-full items-center  justify-between p-5 sm:px-20 ">
      <div>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://vettify.io/"
          className={clsx("rounded-sm text-center text-gray-100")}>
          <Image
            src={logo}
            alt="Official Vettify Logo"
            className="h-8 w-auto"
            height={!sm ? "80" : "80"}
            width={!sm ? "180" : "180"}
          />
        </a>
      </div>
      <Link to="/app/login">
        <div className="rounded bg-[#477BE7] px-4 py-2 text-white">Login</div>
      </Link>
    </div>
  );
};

export default Header;
