import { useEffect } from "react";

import { T } from "@tolgee/react";
import { PrimaryButton } from "components/shared/button";
import Alert from "components/shared/Alert";
import { useCurrentOrgId } from "lib/hooks/currentOrg";
import { useTranslate } from "lib/translate";
import useFetch, { getFormBodyObject } from "lib/fetch-utils";
import { Text, TextArea } from "components/shared/Fields";
import { useForm } from "lib/hooks/form";
import Form from "components/shared/Form";
import { urlValidator } from "lib/validators";
import { useUserData } from "lib/hooks/UserProvider";
import clientConfig from "lib/client_config";
import type { OrgProfileRequest } from "lib/types/edgeProfile";

function descriptionValidator(description: string) {
  return description.length < 1024;
}
function OrganizationDetails() {
  const { t } = useTranslate();
  const {
    performApiAction,
    isLoading,
    data: appendResult,
    resetStatus,
    error,
  } = useFetch();

  const [orgId] = useCurrentOrgId();
  const [userData, setUserData] = useUserData();
  const currentOrg = userData?.orgs.find(
    (org: { id: number }) => org.id === orgId
  );
  const form = useForm<keyof OrgProfileRequest>();

  const nameProps = form.useRegister({
    name: "name",
    label: t("orgs.settings.company-name.title"),
    type: "text",
    errorText: t("orgs.settings.company-name.error"),
  });
  const domainProps = form.useRegister({
    name: "website",
    label: t("orgs.settings.domain.title"),
    type: "text",
    validator: (x) => urlValidator(x),
    errorText: t("orgs.settings.domain.error"),
  });
  const descriptionProps = form.useRegister({
    name: "description",
    label: t("orgs.settings.description.title"),
    type: "textarea",
    validator: descriptionValidator,
    required: false,
    errorText: t("orgs.settings.description.error"),
  });

  // const modified = currentOrg && (descriptionProps.value !== currentOrg.description || nameProps.value !== currentOrg.name || domainProps.value !== currentOrg.website);

  useEffect(() => {
    if (currentOrg) {
      nameProps.onChange(currentOrg.name);
      domainProps.onChange(currentOrg.website);
      descriptionProps.onChange(currentOrg.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg]);

  form.setOnSubmit(async (event) => {
    if (!currentOrg || isLoading || !form.canSubmit) {
      return;
    }
    performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/orgs/update`,
      xSession: userData?.session,
      newData: {
        name: nameProps.value,
        website: domainProps.value,
        // Update endpoint doesn't allow to update with empty string
        description: descriptionProps.value || "No description",
      },
      onSuccess: () => {
        const updatedOrgDetails = getFormBodyObject(
          event.currentTarget || (event.target as HTMLFormElement)
        );
        const updatedCurrentOrg = { ...currentOrg, ...updatedOrgDetails };
        const updatedUserData = {
          ...userData,
          orgs: userData?.orgs.map((org: { id: number }) =>
            org.id === currentOrg.id ? updatedCurrentOrg : org
          ),
        };
        setUserData(updatedUserData);
        localStorage.setItem("user", JSON.stringify(updatedUserData));
      },
    });
  });

  if (!currentOrg) {
    return <></>;
  }

  return (
    <>
      <h3 className="text-xl font-bold">{t("setting.user-org.title")}</h3>
      <div className="my-4">{t("orgs.settings.text")}</div>
      <Form onSubmit={form.submit}>
        <Text {...nameProps} />
        <Text {...domainProps} />
        <TextArea {...descriptionProps} className="col-span-full" />

        <PrimaryButton
          type="submit"
          text={t("orgs.settings.save")}
          styleOverride="px-4 py-2 w-full sm:!w-[100px]"
        />
        {appendResult?.ok && (
          <div className="col-span-full">
            <Alert
              type="success"
              resetStatus={() => {
                resetStatus("success");
              }}>
              <T keyName="setting.user-org.submit.success.message" />
            </Alert>
          </div>
        )}
        {error && (
          <div className="col-span-full">
            <Alert
              type="error"
              resetStatus={() => {
                resetStatus("error");
              }}>
              {error}
            </Alert>
          </div>
        )}
      </Form>
    </>
  );
}
export default OrganizationDetails;
