import { useEffect, useState } from "react";
import { Image } from "primereact/image";
import { Tooltip } from "primereact/tooltip";
import clsx from "clsx";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { PrimaryButton } from "components/shared/button";
import Alert from "components/shared/Alert";
import ActivityLogsTable from "./ActivityLogsTable";
import { useTranslate } from "lib/translate";
import { getStatusStyles, getStatusTooltip } from "lib/rightStatus";
import { H1 } from "components/shared/Headers";
import type { Info } from "lib/types/right";
import useFetch from "lib/fetch-utils";
import clientConfig from "lib/client_config";
import { useUserData } from "lib/hooks/UserProvider";
import { Text } from "components/shared/Fields";
import { urlValidator } from "lib/validators";
import { useRightData } from "lib/hooks/RightData";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Divider from "components/shared/Divider";
import Checkbox from "components/shared/Checkbox";

const ViewDetailPage = () => {
  const [viewDetailInfo, setViewDetailInfo] = useState<Info | null>(null);
  //eslint-disable-next-line
  const [originLogs, setOriginLogs] = useState<null | any>(null);
  const [isSampled, setIsSampled] = useState<boolean>(false);
  const [isEditingTargetLink, setIsEditingTargetLink] = useState(false);
  const [targetURLType, setTargetURLType] = useState(0);
  const [relationshipNature, setRelationshipNature] = useState<string>("");
  const [customURL, setCustomURL] = useState("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoadedViewDetailInfo, setIsLoadedViewDetailInfo] =
    useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { performApiAction } = useFetch();
  const [userData] = useUserData();
  const [rightData] = useRightData();
  const { t } = useTranslate();
  const { chipStyle, circleStyle } = getStatusStyles(
    viewDetailInfo?.status as string
  );
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orgId = searchParams.get("orgId");
  const urlOptions = [
    {
      name: t("asset.send-by-email.form.targetURL.dropdown-options.default"),
      value: 0,
    },
    {
      name: t("asset.send-by-email.form.targetURL.dropdown-options.vettify"),
      value: 2,
    },
    {
      name: t("asset.send-by-email.form.targetURL.dropdown-options.custom"),
      value: 1,
    },
  ];
  useEffect(() => {
    // fetch view detail info
    const viewDetailInfo = localStorage.getItem("view-detail");
    if (viewDetailInfo) {
      setViewDetailInfo(JSON.parse(viewDetailInfo));
      if (userData) {
        if (Number(orgId) !== Number(userData.currentOrg)) {
          navigate("/app/dashboard");
        } else {
          setIsLoadedViewDetailInfo(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  useEffect(() => {
    // fetch origin logs
    if (userData && viewDetailInfo) {
      const fetchOriginLogs = async () => {
        const response = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/analytics/right-origins`,
          xSession: userData?.session,
          newData: { right_id: Number(viewDetailInfo.rightId) },
          resultNeeded: true,
        });

        if (response?.ok) {
          setIsSampled(response.data.sampled);
          setOriginLogs(response?.data?.data);
        }
      };

      fetchOriginLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, viewDetailInfo]);

  const updatedOwnedTargetURL = ({ id }: { id: number }) => {
    const updatedOwnRight = rightData?.owned?.find(
      (owned: { right: { id: number } }) => owned.right.id === id
    );
    if (updatedOwnRight) {
      const updatedOwnRightCopy = {
        ...updatedOwnRight,
        right: {
          ...updatedOwnRight.right,
          target_url_type: targetURLType,
          target_url:
            targetURLType === 0
              ? viewDetailInfo?.defaultTargetURL
              : targetURLType === 1
              ? customURL
              : "",
          relationship_nature: targetURLType === 2 ? relationshipNature : "",
        },
      };
      const updatedOwnedRights = rightData?.owned?.map(
        (owned: { right: { id: number } }) => {
          if (owned.right.id === id) {
            return updatedOwnRightCopy;
          }
          return owned;
        }
      );
      // update local data
      localStorage.setItem(
        "right",
        JSON.stringify({ ...rightData, owned: updatedOwnedRights })
      );
    }
  };

  return (
    <div className="w-full">
      {isLoadedViewDetailInfo ? (
        <>
          <H1>{t("view-detail-page-title")}</H1>
          <div className="my-4">{t("view-detail-page-description")}</div>
          <div className="mb-6">
            <h5 className="text-xl">
              {t("sent-asset-view-details-form-asset-label")}
            </h5>
            <Divider styleOverride="py-4" />
            <p className="mb-2">{viewDetailInfo?.assetName}</p>
            <Image
              preview
              src={`https://cdn.vettify.io/asset/file/${viewDetailInfo?.assetCode}`}
              alt="asset-logo"
              imageClassName=" h-[80px] w-[80px] object-contain rounded-sm border bg-gray-50 p-2"
            />
          </div>
          <div className="mb-6">
            <h5 className="text-xl">
              {t("sent-asset-view-details-form-Status-label")}
            </h5>
            <Divider styleOverride="py-4" />
            <Tooltip
              target={"#status-tooltip"}
              position="top"
              content={`${t(
                getStatusTooltip({
                  owned: viewDetailInfo?.owned as boolean,
                  status: viewDetailInfo?.status as string,
                })
              )}`}
            />
            <div
              id={"status-tooltip"}
              className={clsx(
                "mr-2  flex max-w-[110px] items-center rounded-full border px-3",
                chipStyle
              )}>
              <div
                className={clsx(
                  "mr-2 h-[10px] w-[10px] rounded-full",
                  circleStyle
                )}></div>
              <p className="text-sm capitalize">
                {viewDetailInfo?.owned && viewDetailInfo?.status === "inactive"
                  ? "Not used"
                  : viewDetailInfo?.status}
              </p>
            </div>
          </div>
          <div>
            <div className="mb-6">
              <h5 className="text-xl">
                {t("sent-asset-view-details-form-date-label")}
              </h5>
              <Divider styleOverride="py-4" />
              <p className="mb-2">
                {t("sent-asset-view-details-form-date-field")}{" "}
                {new Date(
                  (viewDetailInfo?.createdAt as number) * 1000
                ).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
              </p>
            </div>

            <div className="mb-6">
              <h5 className="text-xl">
                {t("sent-asset-view-details-form-receiver-label")}
              </h5>
              <div>
                <Divider styleOverride="py-4" />
              </div>
              <p className="mb-2">
                {t("sent-asset-view-details-form-receiver-name")}{" "}
                {viewDetailInfo?.receiverFirstName}{" "}
                {viewDetailInfo?.receiverLastName}
              </p>
              <p className="mb-2">
                {t("sent-asset-view-details-form-receiver-email")}{" "}
                {viewDetailInfo?.receiverEmail}
              </p>
              <p className="mb-2">
                {t("sent-asset-view-details-form-receiver-orgname")}{" "}
                {viewDetailInfo?.receiverOrgName}
              </p>
            </div>
            <div className="mb-6">
              <h5 className="text-xl">
                {t("sent-asset-view-details-form-target-link")}
              </h5>
              <Divider styleOverride="py-4" />
              <div className="flex">
                <p className="mb-2 mr-1">
                  {t("sent-asset-view-details-form-receiver-target-url")}
                </p>
                <a
                  className="text-blue-500 underline"
                  href={
                    viewDetailInfo?.targetURLType !== 2
                      ? viewDetailInfo?.targetURL
                      : `https://cdn.vettify.io/asset/link/${viewDetailInfo.rightCode}`
                  }
                  target="_blank"
                  rel="noreferrer">
                  {viewDetailInfo?.targetURLType !== 2
                    ? viewDetailInfo?.targetURL
                    : t("attestation-link-text")}
                </a>
              </div>

              <div>
                <div>
                  <Checkbox
                    onChangeHandler={() =>
                      setIsEditingTargetLink(!isEditingTargetLink)
                    }
                    checked={isEditingTargetLink}
                    labelText={t("sent-asset-view-details-update-checkbox")}
                  />
                  {/* <Checkbox inputId="target_link" onChange={() => setIsEditingTargetLink(!isEditingTargetLink)} checked={isEditingTargetLink}></Checkbox>
                    <label htmlFor="target_link" className="ml-2">{t("sent-asset-view-details-update-checkbox")}</label> */}
                </div>
                {isEditingTargetLink && (
                  <div className="mt-4 max-w-sm">
                    <label htmlFor="targetURL">
                      <div className="mb-2 text-sm font-medium text-gray-700">
                        {t("asset.send-by-email.form.targetURL.label")}
                        <Tooltip target=".custom-target-icon" />
                        <i
                          className="custom-target-icon icon-[ion--information-circle-outline] ml-1 text-center  align-middle text-gray-500"
                          data-pr-tooltip={t(
                            "asset.send-by-email.form.targetURL.tooltip"
                          )}
                          data-pr-position="right"
                          data-pr-at="right+5 top"
                          data-pr-my="left center-2"></i>
                      </div>
                    </label>
                    <div className="mb-2">
                      <Dropdown
                        value={targetURLType}
                        onChange={(e) => {
                          setTargetURLType(e.value);
                          setIsSubmitted(false);
                        }}
                        name="targetURL"
                        options={urlOptions}
                        optionLabel="name"
                        placeholder={t(
                          "asset.send-by-email.form.targetURL.dropdown.placeholder"
                        )}
                        className="w-full"
                      />
                    </div>

                    {targetURLType === 1 && (
                      <div className="mb-2">
                        <Text
                          name="customURL"
                          label={t(
                            "asset.send-by-email.form.targetURL.customURL.label"
                          )}
                          type="text"
                          value={customURL}
                          onChange={(e) => setCustomURL(e.target.value)}
                          errorText={
                            isSubmitted && !urlValidator(customURL)
                              ? t("errors.url")
                              : ""
                          }
                        />
                      </div>
                    )}

                    {targetURLType === 2 && (
                      <div className="mb-2">
                        <Text
                          name="relationshipNature"
                          label={t(
                            "asset.send-by-email.form.relationship-nature"
                          )}
                          type="text"
                          value={relationshipNature}
                          onChange={(e) =>
                            setRelationshipNature(e.target.value)
                          }
                          withTooltip={true}
                          tooltipText={t(
                            "asset.send-by-email.relationship-nature.tooltip"
                          )}
                          errorText={
                            isSubmitted && !relationshipNature
                              ? t(
                                  "asset.send-by-email.form.relationship-nature.error"
                                )
                              : ""
                          }
                        />
                      </div>
                    )}
                    <PrimaryButton
                      onClick={() => {
                        setIsSubmitted(true);
                        const disabledSubmitted =
                          (targetURLType === 1 && !urlValidator(customURL)) ||
                          (targetURLType === 2 && !relationshipNature);

                        if (disabledSubmitted) {
                          return;
                        }
                        performApiAction({
                          url: `${clientConfig.edgeApi}/api/v1/rights/update`,
                          xSession: userData?.session,
                          newData: {
                            id: viewDetailInfo?.rightId,
                            target_url_type: targetURLType,
                            target_url:
                              targetURLType === 0
                                ? viewDetailInfo?.defaultTargetURL
                                : targetURLType === 1
                                ? customURL
                                : "",
                            relationship_nature:
                              targetURLType === 2 ? relationshipNature : "",
                          },
                          onSuccess: () => {
                            // reset state
                            setIsEditingTargetLink(false);
                            setCustomURL("");
                            setRelationshipNature("");
                            setIsSubmitted(false);
                            // show success message
                            setIsSuccess(true);
                            // update right local data
                            updatedOwnedTargetURL({
                              id: viewDetailInfo?.rightId as number,
                            });
                            if (viewDetailInfo) {
                              const updatedViewDetailInfo = {
                                ...viewDetailInfo,
                                targetURLType,
                                targetURL:
                                  targetURLType === 0
                                    ? viewDetailInfo?.defaultTargetURL || ""
                                    : targetURLType === 1
                                    ? customURL
                                    : "",
                              };
                              // update view-detail local data
                              localStorage.setItem(
                                "view-detail",
                                JSON.stringify(updatedViewDetailInfo)
                              );
                              setViewDetailInfo(updatedViewDetailInfo);
                            }
                          },
                        });
                      }}
                      text={t("asset.view-detail.button.save")}
                    />
                  </div>
                )}
                {isSuccess && (
                  <Alert
                    className="my-4"
                    type="success"
                    resetStatus={() => {
                      setIsSuccess(false);
                    }}>
                    {t("asset.view-detail.update-target-link.success.message")}
                  </Alert>
                )}
              </div>
            </div>
            <>
              <div className="mb-6">
                <div className="flex items-center">
                  <h5 className="text-xl">
                    {t("sent-asset-view-details-permission")}

                    {isSampled && (
                      <span className="text-sm text-gray-500">
                        {" "}
                        {t("view-detail-page-description-sampled")}
                      </span>
                    )}
                  </h5>
                  <div>
                    <Tooltip
                      target={"#sent-asset-view-details-permission"}
                      position="right"
                      content={t("sent-asset-view-details-permission-tooltip")}
                    />
                    <i
                      id="sent-asset-view-details-permission"
                      className="icon-[ion--information-circle-outline] ml-1 text-center align-middle text-gray-500"></i>
                  </div>
                </div>

                <Divider styleOverride="py-4" />
                {originLogs && originLogs?.length > 0 ? (
                  <div>
                    <ActivityLogsTable originLogs={originLogs} />
                  </div>
                ) : (
                  <div className="mt-4 text-center text-gray-400">
                    {t("sent-asset-view-details-permission-no-logs")}
                  </div>
                )}
              </div>
            </>
          </div>
        </>
      ) : (
        <div className="mt-20 text-center">
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            animationDuration=".5s"
          />
        </div>
      )}
    </div>
  );
};

const ViewDetail = () => {
  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title>
          {t("view-detail.title") + t("general.title-separator") + t("vettify")}
        </title>
        <meta name="description" content={t("view-detail.description")} />
      </Helmet>
      <ViewDetailPage />
    </>
  );
};

export default ViewDetail;
