import clsx from "clsx";

type PropsWithClassName<T extends object> = T & {
  className?: string;
  id?: string;
};

export function H1(props: PropsWithClassName<React.PropsWithChildren<object>>) {
  return (
    <h1
      id={props.id}
      className={clsx(
        "text-3xl font-bold tracking-tight text-gray-900 sm:leading-none lg:text-4xl",
        props.className
      )}>
      {props.children}
    </h1>
  );
}

export function H2(props: PropsWithClassName<React.PropsWithChildren<object>>) {
  return (
    <h2
      className={clsx(
        "my-6 max-w-2xl text-justify text-xl text-gray-500",
        props.className
      )}>
      {props.children}
    </h2>
  );
}

export function Title(
  props: PropsWithClassName<React.PropsWithChildren<object>>
) {
  return (
    <div className={clsx("mx-auto w-full py-4", props.className)}>
      {props.children}
    </div>
  );
}
