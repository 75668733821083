/* eslint-disable @typescript-eslint/no-explicit-any */
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslate } from "../../lib/translate";
import {
  SwitchProps,
  TextAreaProps,
  TextProps,
} from "../../components/shared/Fields";
export function useNotEmpty<T>(v: T) {
  const [notEmpty, setNotEmpty] = useState(!!v);

  useEffect(() => {
    setNotEmpty(!!v);
  }, [v]);

  return notEmpty;
}

export function useFocus(
  ref: React.MutableRefObject<HTMLElement | null>,
  defaultState = false
) {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    const onFocus = () => setState(true);
    const onBlur = () => setState(false);

    const current = ref.current;

    if (current) {
      current.addEventListener("focus", onFocus);
      current.addEventListener("blur", onBlur);
    }

    return () => {
      if (current) {
        current.removeEventListener("focus", onFocus);
        current.removeEventListener("blur", onBlur);
      }
    };
  }, [ref]);

  return state;
}

export function useHasChanged<T>(v: T): boolean {
  const [hasChanged, setHasChanged] = useState(false);
  const previousValue = useRef(v);

  useEffect(() => {
    if (v !== previousValue.current) {
      setHasChanged(true);
    }
  }, [v]);
  return hasChanged;
}

/** A helper to make functions that are triggered when a property becomes false.
 * @see useHasLostFocus for and example
 */
export function usePropertyWentFalse(property: boolean) {
  const [wentFalse, setWentFalse] = useState(false);
  const previousValue = useRef(property);

  useEffect(() => {
    if (wentFalse) {
      return;
    }
    if (!property && previousValue.current) {
      setWentFalse(true);
    }
    previousValue.current = property;
  }, [property, wentFalse]);
  return wentFalse;
}

/**
 * Hook that becomes true when an element has had focus and loses it.
 */
export function useHasLostFocus(
  ref: React.MutableRefObject<HTMLElement | null>,
  defaultState = false
): boolean {
  const focus = useFocus(ref, defaultState);
  const hasLostFocus = usePropertyWentFalse(focus);

  return hasLostFocus;
}

function isAllTruthy(...all: any[]): boolean {
  for (const hook of all) {
    if (!hook) {
      return false;
    }
  }
  return true;
}

export function useAllTruthy(...allHooks: any[]) {
  const [allTruthy, setAllTruthy] = useState(isAllTruthy(...allHooks));

  useEffect(() => {
    setAllTruthy(isAllTruthy(...allHooks));
  }, [allHooks]);

  return allTruthy;
}

/**
 * Check if a given value is valid against a validator function.
 */
export function useValidator<T>(
  initialValue: T,
  validator: (t: T) => boolean
): [T, typeof setValue, boolean] {
  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(validator(initialValue));

  useEffect(() => {
    setIsValid(validator(value));
  }, [value, validator]);

  return [value, setValue, isValid];
}

// https://stackoverflow.com/a/72686232/9329985 -> no 4
/**
 * validate that a password contains at least:
 * - a digit `(?=.*[0-9])`
 * - a lowercase letter `(?=.*[a-z])`
 * - an uppercase letter `(?=.*[A-Z])`
 * - 8 to 24 characters long `.{8,24}`
 */
export function passwordValidator(password: string) {
  return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
}

interface FormParameter<ValueType, NameType extends string> {
  name: NameType;
  required?: boolean;
  value?: ValueType;
  validator?: (v: ValueType) => boolean;
  errorText?: string;
}

interface FieldParameter<NameType extends string>
  extends FormParameter<string, NameType> {
  label: string;
  autoComplete?: string;
}

interface TextFieldParameter<NameType extends string>
  extends FieldParameter<NameType> {
  type: "text" | "password" | "email" | "textarea";
}
interface SwitchFieldParameter<NameType extends string>
  extends FormParameter<boolean, NameType> {
  type: "switch";
}

interface HiddenFieldParameters<NameType extends string>
  extends FormParameter<unknown, NameType> {
  type: "hidden";
}

type PossibleFormParameters<NameType extends string> =
  | TextFieldParameter<NameType>
  | SwitchFieldParameter<NameType>
  | HiddenFieldParameters<NameType>;

interface RequiredFormFieldProps<T, Element> {
  name: string;
  id: string;
  ref: React.Ref<Element> | undefined;
  value: T;
  onChange: (e: React.ChangeEvent<Element> | T) => void;
  errorText: string;
}

type TextAreaRequiredFormFieldProps = RequiredFormFieldProps<
  string,
  HTMLTextAreaElement
> & { label: string };

type TextFieldRequiredFormFieldProps = RequiredFormFieldProps<
  string,
  HTMLInputElement
> & { label: string; type: "text" | "password" | "email" };

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

/* eslint-disable @typescript-eslint/indent */
type PossibleFormProps<
  NameType extends string,
  T extends PossibleFormParameters<NameType>["type"]
> = T extends "switch"
  ? Overwrite<SwitchProps, RequiredFormFieldProps<boolean, HTMLButtonElement>>
  : T extends "textarea"
  ? Overwrite<TextAreaProps, TextAreaRequiredFormFieldProps>
  : T extends "text" | "password" | "email" | "hidden"
  ? Overwrite<TextProps, TextFieldRequiredFormFieldProps>
  : never;
/* eslint-enable @typescript-eslint/indent */

export function emailValidator(email: string) {
  return /^[\w\-\.+]+@([\w-]+\.)+[\w-]{2,}$/.test(email);
}
class VForm<NameType extends string> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private onSubmit: (
    event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ) => Promise<void> = async (_e) => {
    //eslint-disable-next-line @typescript-eslint/no-empty-function
  };
  private _submitting: boolean;
  private setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;

  private _submitted: boolean;
  private setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;

  private _canSubmit = true;

  public setOnSubmit(onSubmit: typeof this.onSubmit) {
    this.onSubmit = onSubmit;
  }

  public get canSubmit() {
    return this._canSubmit;
  }

  public get submitted() {
    return this._submitted;
  }

  public get submitting() {
    return this._submitting;
  }

  constructor() {
    /* eslint-disable react-hooks/rules-of-hooks */
    [this._submitted, this.setSubmitted] = useState(false);
    [this._submitting, this.setSubmitting] = useState(false);
    /* eslint-enable react-hooks/rules-of-hooks */
  }

  private async submitHandler(
    event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) {
    event.preventDefault();
    this.setSubmitting(true);
    this.setSubmitted(true);
    if (this.canSubmit && !this.submitting) {
      await this.onSubmit(event);
    }
    this.setSubmitting(false);
  }

  public get submit() {
    return this.submitHandler.bind(this);
  }

  public reset() {
    this.setSubmitted(false);
  }

  public showErrors() {
    this.setSubmitted(true);
  }

  private static getValidator(required: boolean): (value: any) => boolean {
    if (!required) {
      return () => true;
    }
    return (value: any) => !!value;
  }

  public useRegister<ParameterType extends PossibleFormParameters<NameType>>(
    field: ParameterType
  ): PossibleFormProps<NameType, ParameterType["type"]> {
    if (field.required === undefined) {
      field.required = true;
    }
    /* eslint-disable react-hooks/rules-of-hooks */
    const [value, setValue, isValid] = useValidator(
      field.value === undefined ? "" : field.value,
      field.validator || (VForm.getValidator(field.required) as any)
    );
    const [errorText, setErrorText] = useState("");

    const ref = useRef<HTMLElement>(null);
    const hasLostFocus = useHasLostFocus(ref);

    const label: string | undefined = (field as TextFieldParameter<NameType>)
      .label;

    const submitted = this._submitted;

    useEffect(() => {
      const showErrorText = () => {
        if (field.type === "switch") {
          return field.required && !isValid && this._submitted;
        }

        return field.required && !isValid && (hasLostFocus || this._submitted);
      };
      setErrorText(
        showErrorText.call(this)
          ? field.errorText ||
              `${label || "Value"} is ${value ? "invalid" : "required"}.` // TODO: translate this
          : ""
      );
      this._canSubmit = this._canSubmit && isValid;
    }, [
      field.type,
      field.required,
      isValid,
      submitted,
      hasLostFocus,
      value,
      label,
      field.errorText,
    ]);
    /* eslint-enable react-hooks/rules-of-hooks */

    this._canSubmit = this._canSubmit && isValid;

    const genericProps = {
      name: field.name,
      value: value as any,
      id: field.name,
      onChange: (e) => setValue(typeof e === "object" ? e.target.value : e),
      errorText,
      // required: field.required, // Do not set the required property, we do the validation ourselves
      ref: ref as any,
    } satisfies RequiredFormFieldProps<any, any>;

    switch (field.type) {
      case "hidden":
      case "switch":
        return genericProps as any;
      case "textarea":
        return {
          label,
          ...genericProps,
        } as any;

      case "text":
      case "password":
      case "email":
        return {
          label,
          type: field.type,
          autoComplete: (field as TextFieldParameter<NameType>).autoComplete,
          ...genericProps,
        } as any;

      default: {
        const _exhaustiveCheck: never = field;
        return _exhaustiveCheck;
      }
    }
  }

  public useRegisterEmail(name: NameType) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslate();
    return this.useRegister({
      name,
      label: t("general.form.label.email"),
      type: "email",
      autoComplete: "email",
      validator: emailValidator,
    });
  }
}

export function useForm<T extends string>() {
  return new VForm<T>();
}
