import { Tooltip } from "primereact/tooltip";
import type { FC } from "react";
import { useCopyToClipboard } from "usehooks-ts";

interface Props {
  code: string;
  canCopy?: boolean;
  id?: string;
}

export const Code: FC<Props> = ({ code, canCopy, id }) => {
  const [copiedText, copy] = useCopyToClipboard();

  return (
    <div className="w-[auto] sm:w-auto" id={id}>
      <Tooltip
        target="#button"
        position="top"
        content={copiedText ? "Copied" : "Copy"}
      />
      <div className="mb-[-10px] flex h-12 items-center justify-between rounded-t-xl bg-slate-600 p-4 font-semibold text-white">
        <span>HTML</span>
        {canCopy && (
          <button
            onClick={() => copy(code)}
            id="button"
            className="icon-[uil--copy] h-6 w-6"
          />
        )}
      </div>

      <pre style={{ whiteSpace: "pre-wrap", overflow: "auto" }}>
        <code className="language-html">{code}</code>
      </pre>
    </div>
  );
};
