/* eslint-disable @typescript-eslint/no-namespace */
import { Menu as HeadlessMenu } from "@headlessui/react";
import { createContext, useContext } from "react";

import clsx from "clsx";
import { AppearTransition } from "./Transitions";
import { ButtonOrLink } from "./ButtonOrLink";

const menuThemeContext = createContext<"light" | "dark">("light");

const MenuItem = (props: React.ComponentProps<typeof ButtonOrLink>) => {
  const theme = useContext(menuThemeContext);
  return (
    <HeadlessMenu.Item>
      {({ active }) => (
        <ButtonOrLink
          className={clsx(
            "w-full !justify-start rounded-lg !px-3 !py-1 text-left !text-lg  tracking-tight transition-colors md:rounded-none md:!px-4 md:!py-2 md:!text-base",
            active && (theme === "light" ? "!bg-slate-100" : "!bg-gray-600"),
            theme === "light"
              ? "text-slate-900 hover:!bg-slate-100 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              : "!text-gray-200 hover:bg-gray-600"
          )}
          {...props}
        />
      )}
    </HeadlessMenu.Item>
  );
};

interface MenuItemsProps {
  children: React.ReactNode;
  className?: string;
}

const MenuItems = ({ children, className }: MenuItemsProps) => {
  const theme = useContext(menuThemeContext);
  return (
    <AppearTransition>
      <HeadlessMenu.Items
        as="div"
        className={clsx(
          "absolute inset-x-0 z-30 mt-2 flex min-w-[120px] flex-col rounded-2xl p-4 text-lg shadow-xl focus:outline-none md:inset-x-[initial] md:mt-1 md:rounded-lg md:px-0 md:py-2",
          theme === "light"
            ? "bg-white ring-1 ring-slate-900/5"
            : "bg-gray-700",
          className
        )}>
        {children}
      </HeadlessMenu.Items>
    </AppearTransition>
  );
};

function Menu({
  as = "div",
  theme = "light",
  ...rest
}: {
  children: React.ReactNode;
  theme?: "light" | "dark";
} & React.ComponentProps<typeof HeadlessMenu>) {
  return (
    <menuThemeContext.Provider value={theme}>
      <HeadlessMenu as={as} {...rest} />
    </menuThemeContext.Provider>
  );
}
export default Menu;

namespace Menu {
  export const Button = HeadlessMenu.Button;
  export const Items = MenuItems;
  export const Item = MenuItem;
}
