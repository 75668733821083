import { useState } from "react";
import { useTranslate } from "lib/translate";
import useFetch from "lib/fetch-utils";
import { Text, TextArea } from "components/shared/Fields";
import Form from "components/shared/Form";
import { TitleAndText } from "components/shared/TitleAndText";
import { extractDomain } from "lib/validators";
import Alert from "components/shared/Alert";
import clientConfig from "lib/client_config";
import { useUserData } from "lib/hooks/UserProvider";
import { useAssetsData } from "lib/hooks/AssetData";
import { useCurrentOrgId } from "lib/hooks/currentOrg";
import { PrimaryButton } from "components/shared/button";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";

function CreateForm() {
  const [userData, setUserData] = useUserData();
  const [, setAssetsData] = useAssetsData();
  const [, setCurrentOrgId] = useCurrentOrgId();

  const { t } = useTranslate();
  const [orgName, setOrgName] = useState("");
  const [orgDomain, setOrgDomain] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { performApiAction, error, resetStatus } = useFetch();
  const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (!orgName || !orgDomain || !extractDomain(orgDomain)) {
      return;
    }

    const formData = {
      name: orgName,
      website: orgDomain,
      description,
    };
    const response = await performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/orgs/add`,
      xSession: userData?.session,
      newData: formData,
      resultNeeded: true,
    });

    if (response?.ok) {
      const { currentOrg, orgs, session } = response.data;
      const updatedUserData = {
        ...userData,
        orgs,
        currentOrg,
        session,
      };
      const assetsRequest = await performApiAction({
        url: `${clientConfig.edgeApi}/api/v1/assets/all`,
        xSession: session,
        newData: {
          batchSize: 20,
        },
        resultNeeded: true,
      });
      if (assetsRequest?.ok) {
        localStorage.removeItem("assets");
        localStorage.removeItem("right");
        localStorage.removeItem("view-detail");
        localStorage.setItem("user", JSON.stringify(updatedUserData));
        localStorage.setItem("assets", JSON.stringify(assetsRequest.data));
        setAssetsData(assetsRequest.data);
        setCurrentOrgId(currentOrg);
        setIsSubmitted(false);
        setUserData(updatedUserData);
        navigate("/app/dashboard");
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Text
          name="name"
          label={t("org.create.form.label.company-name")}
          type="text"
          value={orgName}
          onChange={(e) => setOrgName(e.target.value)}
          errorText={
            isSubmitted && !orgName
              ? t("asset.send-by-email.form.orgname.error")
              : ""
          }
        />
        <Text
          name="domain"
          errorText={
            isSubmitted && !extractDomain(orgDomain) ? t("errors.url") : ""
          }
          label={t("org.create.form.label.domain")}
          type="text"
          value={orgDomain}
          onChange={(e) => setOrgDomain(e.target.value)}
          placeholder={t("org.create.form.domain-placeholder")}
        />
        <TextArea
          name="description"
          label={t("org.create.form.label.description")}
          required={false}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={t("org.create.form.description-placeholder")}
          className="col-span-full"
        />
        {error && (
          <div className="col-span-full ">
            <Alert
              type="error"
              resetStatus={() => {
                resetStatus("error");
              }}>
              {error}
            </Alert>
          </div>
        )}
        <div className="col-span-full flex justify-start">
          <PrimaryButton type="submit" text={t("org.create.form.submit")} />
        </div>
      </Form>
    </>
  );
}

export default function New() {
  const { t } = useTranslate();
  return (
    <>
      <Helmet>
        <title>
          {t("org.create.title") + t("general.title-separator") + t("vettify")}
        </title>
        <meta name="description" content={t("org.create.meta.description")} />
      </Helmet>

      <TitleAndText
        title={t("org.create.title")}
        text={t("org.create.description")}
      />
      <CreateForm />
    </>
  );
}
