import { Sidebar } from "primereact/sidebar";
import { Image } from "primereact/image";
import type { FormEvent } from "react";
import { useState } from "react";
import { PrimaryButton } from "components/shared/button";
import SendAssetStep from "./SendAssetStep";
import type { Asset } from "lib/types/assets";
import useFetch from "lib/fetch-utils";
import { useTranslate } from "lib/translate";
import clientConfig from "lib/client_config";
import { useUserData } from "lib/hooks/UserProvider";
import { emailValidator } from "lib/hooks/form";
import { Text } from "components/shared/Fields";

const SendAsset = ({
  sendAssetId,
  isSendAssetOpen,
  sendAssetDrawerHandler,
  assets,
}: {
  sendAssetId: number | null;
  isSendAssetOpen: boolean;
  sendAssetDrawerHandler: ({
    isOpen,
    id,
  }: {
    isOpen: boolean;
    id: number | null;
  }) => void;
  assets: Asset[] | [] | undefined;
}) => {
  const [userData] = useUserData();
  const { t } = useTranslate();
  const { performApiAction, resetStatus } = useFetch();

  // send asset state
  const [firstname, setFirstname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [orgname, setOrgname] = useState<string>("");
  const [customURL, setCustomURL] = useState<string>("");
  const [relationshipNature, setRelationshipNature] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  //eslint-disable-next-line
  const [selectedOption, setSelectedOption] = useState<any>("");
  const [targetURL, setTargetURL] = useState(0);
  // Step 0:prepare, Step 1: Existing User, Step 2: New User
  const [step, setStep] = useState<number>(0);
  // After prepare, if user is existing, show orgs list
  const [orgsList, setOrgsList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);

  const urlOptions = [
    {
      name: t("asset.send-by-email.form.targetURL.dropdown-options.default"),
      value: 0,
    },
    {
      name: t("asset.send-by-email.form.targetURL.dropdown-options.custom"),
      value: 1,
    },
    {
      name: t("asset.send-by-email.form.targetURL.dropdown-options.vettify"),
      value: 2,
    },
  ];

  const asset = assets?.find((asset) => asset.id === sendAssetId);
  const assetName = asset?.filename.split(".").shift();

  const resetDrawerStatus = () => {
    setFirstname("");
    setEmail("");
    setOrgname("");
    setMessage("");
    setCustomURL("");
    setRelationshipNature("");
    sendAssetDrawerHandler({ isOpen: false, id: null });
    resetStatus("error");
    resetStatus("success");
    setTargetURL(0);
    setIsSubmitted(false);
    setStep(0);
    setOrgsList([]);
    setSelectedOrg(null);
    setSelectedOption("");
  };
  const resetFormState = () => {
    setFirstname("");
    setEmail("");
    setOrgname("");
    setMessage("");
    setCustomURL("");
    setRelationshipNature("");
    resetStatus("error");
    resetStatus("success");
    setTargetURL(0);
    setIsSubmitted(false);
    setStep(0);
    setOrgsList([]);
    setSelectedOrg(null);
    setSelectedOption("");
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!emailValidator(email)) {
      setEmailError(true);
      return;
    }
    const response = await performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/invites/asset/prepare`,
      xSession: userData?.session,
      newData: {
        email,
      },
      resultNeeded: true,
    });
    if (response?.ok) {
      setEmailError(false);
      if (response.data?.orgs?.length > 0) {
        setStep(1);
        setOrgsList(response.data.orgs);
      } else {
        setStep(2);
      }
    }
  };
  return (
    <div className="card justify-content-center flex">
      <Sidebar
        className="!w-[600px] p-5"
        closeOnEscape={true}
        dismissable={true}
        blockScroll={true}
        icons={false}
        visible={isSendAssetOpen}
        position="right"
        onHide={() => resetDrawerStatus()}>
        <div className="absolute top-10">
          <h2 className="text-2xl font-bold">
            {t("asset.send-by-email.form.title")}
          </h2>
        </div>

        <p className="text-sm leading-snug text-gray-500">
          {t("asset.send-by-email.form.description")}
        </p>

        <div className="mt-5">
          <h6 className="mb-2 font-semibold">{assetName}</h6>
          <div className="mb-4">
            <Image
              preview
              className=""
              src={`https://cdn.vettify.io/asset/file/${asset?.asset_code}`}
              alt="asset-logo"
              imageClassName=" h-[50px] w-[50px] object-contain rounded-sm  p-2 border bg-gray-50"
            />
          </div>
          {step === 0 && (
            <form onSubmit={handleSubmit}>
              <div className="mb-5 grid max-w-2xl grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-8">
                <Text
                  name="email"
                  label={t("asset.send-by-email.form.email")}
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                  errorText={
                    emailError ? t("asset.send-by-email.form.email.error") : ""
                  }
                />
                <div className="col-span-full">
                  <PrimaryButton type="submit" text="Next" />
                </div>
              </div>
            </form>
          )}
          {step !== 0 && (
            <SendAssetStep
              step={step}
              orgsList={orgsList}
              selectedOrg={selectedOrg}
              setSelectedOrg={setSelectedOrg}
              asset={{
                id: asset?.id as number,
                target_url: asset?.target_url as string,
              }}
              firstname={firstname}
              setFirstname={setFirstname}
              email={email}
              orgname={orgname}
              setOrgname={setOrgname}
              customURL={customURL}
              setCustomURL={setCustomURL}
              relationshipNature={relationshipNature}
              setRelationshipNature={setRelationshipNature}
              message={message}
              setMessage={setMessage}
              isSubmitted={isSubmitted}
              setIsSubmitted={setIsSubmitted}
              targetURL={targetURL}
              setTargetURL={setTargetURL}
              urlOptions={urlOptions}
              resetDrawerStatus={resetDrawerStatus}
              resetFormState={resetFormState}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          )}
        </div>
      </Sidebar>
    </div>
  );
};

export default SendAsset;
