/* eslint-disable */
import type { Asset } from "./types/assets";
import type { ErrorResult, OkResult, Result } from "./types/result";
import type { TranslationKeys } from "./translate";

interface FileUploadError {
  fileName: string;
  message: string;
}

function arrayBufferToHex(buffer: ArrayBuffer): string {
  const uint8Array = new Uint8Array(buffer);
  let hexString = "";
  for (let i = 0; i < uint8Array.length; i++) {
    const hex = uint8Array[i].toString(16);
    hexString += hex.length === 1 ? `0${hex}` : hex;
  }
  return hexString;
}

export async function arrayBufferToHexEncodedString(
  arrayBuffer: Promise<ArrayBuffer>
): Promise<string> {
  const buffer = await arrayBuffer;
  return arrayBufferToHex(buffer);
}

//eslint-disable-next-line
function partition<T, TrueType extends T = any, FalseType extends T = any>(
  array: T[],
  predicate: (value: T) => boolean
) {
  const filterTrue: TrueType[] = [];
  const filterFalse: FalseType[] = [];
  array.forEach((value) => {
    //eslint-disable-next-line
    (predicate(value) ? filterTrue : filterFalse).push(value as any);
  });
  return [filterTrue, filterFalse] as const;
}
export const validImageFileExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "svg+xml",
  "webp",
  "svg",
];

function isValidFileExtension(fileType: string) {
  return validImageFileExtensions.includes(fileType);
}

export function checkValidity(
  files: FileList | undefined
): TranslationKeys | undefined {
  if (!files || !files.length) {
    return "orgs.add-asset.drag-drop.no-files.message";
  }

  if (files.length > 5) {
    return "orgs.add-asset.drag-drop.too-many-files.message";
  }

  for (const file of files) {
    const fileExtension = file.type.split("/")[1];
    if (!isValidFileExtension(fileExtension)) {
      return "orgs.add-asset.drag-drop.invalid-file-type.message";
    }
    if (file.size > 1024 * 1024 * 10) {
      return "orgs.add-asset.drag-drop.invalid-file-size.message";
    }
  }
}

// Promise<Result<PublicLogoWithRequests, FileUploadError>>
const uploadOneFile = async ({
  file,
  uploadOrgAsset,
  defaultTargetURL,
}: {
  file: File;
  // eslint-disable-next-line
  uploadOrgAsset: (data: any) => Promise<any>;
  defaultTargetURL: string | undefined;
}): Promise<any> => {
  const arrayBuffer = file.arrayBuffer(); // Use file.arrayBuffer() to get ArrayBuffer
  const hexEncodedString = await arrayBufferToHexEncodedString(arrayBuffer); // ArrayBuffer to hex-encoded string
  // initial data tag,description as empty string
  // target_url as defaultTargetURL which is org's domain URL
  const data = {
    filename: file.name,
    file: {
      mimetype: file.type,
      file: hexEncodedString,
    },
    tags: "",
    target_url: defaultTargetURL,
    alt_attribute: "",
    description: "",
  };
  const res = await uploadOrgAsset(data);
  if (!res?.ok) {
    return {
      ok: false,
      error: {
        fileName: file.name,
        message: "orgs.add-asset.toast.error",
      },
    };
  }
  return {
    ok: true,
    data: {
      ...(res!.data as any),
      // requests: [], // The logo is new, there are no requests for it yet.
    },
  };
};

export const uploadFiles = async ({
  files,
  updateAssets,
  defaultTargetURL,
  uploadOrgAsset,
  addFailedFileNames,
}: {
  files: File[];
  defaultTargetURL: string | undefined;
  updateAssets: () => void;
  uploadOrgAsset: (data: any) => Promise<any>;
  addFailedFileNames?: (fileNames: string[]) => void;
}) => {
  if (!files.length) {
    return;
  }
  const newFiles = await Promise.all(
    files.map((file: File) =>
      uploadOneFile({
        file,
        uploadOrgAsset,
        defaultTargetURL,
      })
    )
  );
  const [successes, failures] = partition<
    Result<Asset, FileUploadError>,
    OkResult<Asset>,
    ErrorResult<FileUploadError>
  >(newFiles, (file) => file.ok);

  const fileErrors = failures.map((file) => file.error.fileName);

  if (successes.length > 0) {
    updateAssets();
  }
  // Show user which files failed to upload
  if (addFailedFileNames) {
    addFailedFileNames(fileErrors);
  }
};
