import { Image } from "primereact/image";
import { useEffect, useRef, useState } from "react";
import { Menu } from "primereact/menu";
import type { MenuItem } from "primereact/menuitem";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { PrimaryButton, SecondaryButton } from "components/shared/button";
import type { Asset } from "lib/types/assets";
import { useTranslate } from "lib/translate";
import type { Orgs } from "lib/types/userData";
import { useBreakPoint } from "lib/hooks/useBreakPoint";
import clientConfig from "lib/client_config";
import useFetch from "lib/fetch-utils";
import { useUserData } from "lib/hooks/UserProvider";
import { extensionChipStyles } from "lib/extensionChipStyles";
import Alert from "components/shared/Alert";

const AssetCard = ({
  currentOrg,
  asset,
  makeOrgIcon,
  sendAssetDrawerHandler,
}: {
  currentOrg: Orgs | undefined;
  asset: Asset;
  makeOrgIcon: ({
    assetId,
    assetCode,
  }: {
    assetId: number;
    assetCode: string;
  }) => void;
  sendAssetDrawerHandler: ({
    isOpen,
    id,
  }: {
    isOpen: boolean;
    id: number | null;
  }) => void;
}) => {
  const { performApiAction } = useFetch();
  const [userData] = useUserData();
  const { id, filename, asset_code } = asset;
  const menuRight = useRef<Menu>(null);
  const assetName = filename?.split(".").shift();
  const fileExtension: string | undefined = filename?.split(".").pop();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { lg, sm } = useBreakPoint();
  const isMainLogo = asset.id === currentOrg?.logo?.asset_id;
  const [isAnalyticsEmpty, setIsAnalyticsEmpty] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [analyticsDataError, setAnalyticsDataError] = useState("");
  // const confirmDelete = () => {
  //   confirmDialog({
  //     message: t("orgs.assets.delete.message"),
  //     header: t("orgs.assets.delete.confirm-title"),
  //     acceptLabel: t("orgs.assets.delete.accept"),
  //     acceptClassName: "p-button-danger",
  //     rejectLabel: t("orgs.assets.delete.reject"),
  //     accept: () => deleteHandler(id),
  //   });
  // };
  useEffect(() => {
    const fetchAnalyticData = async () => {
      setAnalyticsDataError("");
      const today = new Date();
      const fromDate = new Date(today).setDate(new Date(today).getDate() - 90);
      try {
        const analyticsResponse = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/analytics/asset-views`,
          xSession: userData?.session,
          newData: {
            asset_id: Number(id),
            from: fromDate,
            to: today.getTime(),
          },
          resultNeeded: true,
        });
        if (analyticsResponse?.ok) {
          const totalViews = analyticsResponse.data.data.reduce(
            (acc: number, data: { sum: number }) => acc + data.sum,
            0
          );
          if (totalViews > 0) {
            setIsAnalyticsEmpty(false);
          } else {
            setIsAnalyticsEmpty(true);
          }
        }
        if (!analyticsResponse?.ok) {
          setAnalyticsDataError("Error fetching analytics data");
        }
      } catch (error) {
        setAnalyticsDataError("Error fetching analytics data");
      } finally {
        setIsReady(true);
      }
    };

    if (userData) {
      fetchAnalyticData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const items: MenuItem[] = [
    {
      label: t("asset.card.dropdown.menu.set-as-log"),
      icon: "icon-[prime--check] text-2xl !text-lime-500",
      command: () => {
        makeOrgIcon({ assetId: id, assetCode: asset_code });
      },
      disabled: isMainLogo,
      // visible: fileExtension === "svg",
    },
    {
      label: t("asset.card.dropdown.menu.download"),
      icon: "icon-[prime--download] text-2xl !text-purple-500",
      command: () => {
        const a = document.createElement("a");
        a.href = `https://cdn.vettify.io/asset/download-asset/${asset_code}`;
        a.download = filename;
        a.click();
      },
    },
    // {
    //   label: t("asset.card.dropdown.menu.delete"),
    //   icon: "icon-[prime--trash] text-2xl !text-vettired-500",
    //   command: () => {
    //     confirmDelete();
    //   },
    //   disabled: isMainLogo,
    // },
  ];
  return (
    <div className="mb-4 w-full rounded-lg border border-gray-200 bg-white p-6">
      <div className="flex justify-between">
        <div className="flex">
          <div className="mr-4 ">
            <Image
              preview
              src={`https://cdn.vettify.io/asset/file/${asset_code}`}
              alt="asset-logo"
              imageClassName="h-[50px] w-[50px] rounded-sm object-contain  lg:h-24 lg:w-24  p-2 border bg-gray-50"
            />
          </div>
          <div>
            <div className="mb-5 flex items-center">
              {assetName && (
                <p className=" mr-2 text-sm font-semibold lg:text-lg">
                  {!lg && !sm && (
                    <>
                      {assetName.length > 50
                        ? `${assetName?.slice(0, 50)}...`
                        : assetName}
                    </>
                  )}
                  {lg && (
                    <>
                      {assetName.length > 25
                        ? `${assetName?.slice(0, 25)}...`
                        : assetName}
                    </>
                  )}
                  {sm && (
                    <>
                      {assetName.length > 12
                        ? `${assetName?.slice(0, 12)}...`
                        : assetName}
                    </>
                  )}
                </p>
              )}
              <div
                className={`mr-2 rounded-full border px-3 ${extensionChipStyles(
                  fileExtension
                )}`}>
                <p className="text-sm">{fileExtension}</p>
              </div>
              {isMainLogo && (
                <div>
                  <Tooltip
                    target={"#main-logo"}
                    position="top"
                    content={`${t("asset-main-logo")}`}
                  />
                  <i
                    id="main-logo"
                    className="icon-[prime--star-fill] text-2xl !text-yellow-500"
                  />
                </div>
              )}
            </div>
            {isReady && (
              <div>
                <SecondaryButton
                  text={t("asset.card.button.edit-link")}
                  onClick={() =>
                    navigate({
                      pathname: "/app/dashboard/asset-edit",
                      search: `?editId=${id}`,
                    })
                  }
                  styleOverride="relative mr-4"
                />
                {!isAnalyticsEmpty && (
                  <SecondaryButton
                    text={t("asset.card.button.analytics-link")}
                    onClick={() =>
                      navigate({
                        pathname: "/app/dashboard/analytics",
                        search: `?id=${id}`,
                      })
                    }
                    styleOverride="relative mr-4"
                  />
                )}

                <PrimaryButton
                  text={t("asset.card.button.send-asset")}
                  onClick={() => sendAssetDrawerHandler({ isOpen: true, id })}
                  styleOverride="relative"
                />
              </div>
            )}

            {analyticsDataError && (
              <Alert
                className="w-[270px]"
                type="error"
                resetStatus={() => {
                  setAnalyticsDataError("");
                }}>
                {analyticsDataError}
              </Alert>
            )}
          </div>
        </div>
        <div>
          <Menu
            model={items}
            popup
            ref={menuRight}
            id="popup_menu_right"
            popupAlignment="right"
          />
          <button
            aria-controls="popup_menu_right"
            aria-haspopup
            onClick={(event) => menuRight.current?.toggle(event)}>
            <i className="icon-[bi--three-dots] w-5 text-gray-500" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssetCard;
