import { Link } from "react-router-dom";
import { T, useTranslate } from "../../lib/translate";
import { useForm } from "../../lib/hooks/form";
import AuthLayout from "../shared/AuthLayout";
import Alert from "../shared/Alert";
import { Text } from "../shared/Fields";
import useFetch, { getFormBodyObject } from "../../lib/fetch-utils";
import clientConfig from "../../lib/client_config";
import Logo from "../shared/Logo";
import { PrimaryButton } from "../shared/button";
import { useEffect } from "react";
import { UserLostPasswordRequest } from "../../lib/types/lost-password";

function LostPasswordForm() {
  const { t } = useTranslate();

  const form = useForm<keyof UserLostPasswordRequest>();
  const emailProps = form.useRegisterEmail("email");
  const { performApiAction, error, data: res } = useFetch();

  form.setOnSubmit(async (event: React.SyntheticEvent) => {
    await performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/users/password-reset/send`,
      newData: getFormBodyObject(event.target as HTMLFormElement),
    });
  });

  if (res?.ok) {
    return (
      <>
        <div>
          <h2 className="text-lg font-semibold text-gray-900">
            <T keyName="lost-password.email-sent.title" />
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            <T keyName="lost-password.email-sent.subtitle" />{" "}
            <Link
              to="/app/login"
              className="font-medium text-vettiblue-500 hover:underline">
              <T keyName="lost-password.email-sent.subtitle-link" />
            </Link>
          </p>
        </div>
        <Alert
          header={t("lost-password.email-sent.header")}
          icon={() => (
            <span
              className="icon-[heroicons--inbox-arrow-down] text-2xl"
              aria-hidden
            />
          )}
          className="my-4">
          <T keyName="lost-password.email-sent.message" />
        </Alert>
      </>
    );
  }

  return (
    <>
      <div>
        <h2 className="text-lg font-semibold text-gray-900">
          <T keyName="lost-password.title" />
        </h2>
        <p className="mt-2 text-sm text-gray-700">
          <T keyName="lost-password.subtitle" />{" "}
        </p>
      </div>
      <form onSubmit={form.submit} className="mt-10 grid grid-cols-1 gap-y-8">
        <Text
          {...emailProps}
          errorText={
            !form.canSubmit && form.submitted
              ? t("lost-password.form.submit.error.text")
              : ""
          }
        />

        <div className="flex items-center justify-start">
          <PrimaryButton
            type="submit"
            text={
              form.submitting ? (
                <i className="icon-[svg-spinners--dot-revolve] !mr-0"></i>
              ) : (
                <span className=" flex items-center py-1 ">
                  {t("lost-password.form.submit")}
                  <i className="icon-[prime--arrow-right] ml-2 text-xl"></i>
                </span>
              )
            }
            styleOverride="mb-0 min-w-[180px] px-4 py-2"
          />
        </div>
        {error && <Alert type="error">{error}</Alert>}
      </form>
    </>
  );
}

export default function LostPasswordPage() {
  const { t } = useTranslate();

  useEffect(() => {
    document.title = `${t("forgot-password.title")} ${t(
      "general.title-separator"
    )} ${t("vettify")}`;
  }, []);

  return (
    <>
      <div className="grow-0"></div>
      <AuthLayout>
        <a href="https://vettify.io/" aria-label={"landing page"}>
          <Logo className="mb-10 h-10 w-auto" />
        </a>
        <LostPasswordForm />
      </AuthLayout>
      <div className="grow-0"></div>
    </>
  );
}
