import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Alert from "../shared/Alert";
import Logo from "../shared/Logo";
import { Text } from "../shared/Fields";
import AuthLayout from "../shared/AuthLayout";
import useFetch, { getFormBodyObject } from "../../lib/fetch-utils";
import { UserLoginRequest } from "../../lib/types/login";
import clientConfig from "../../lib/client_config";
import { useForm } from "../../lib/hooks/form";
import { T, useTranslate } from "../../lib/translate";
import { PrimaryButton } from "../shared/button";
import { Helmet } from "react-helmet";

function LoginForm() {
  const { t } = useTranslate();

  const navigate = useNavigate();
  const { performApiAction } = useFetch();
  const [error, setError] = useState("");

  const form = useForm<keyof UserLoginRequest>();

  const emailProps = form.useRegisterEmail("email");
  const passwordProps = form.useRegister({
    name: "password",
    label: t("general.form.label.password"),
    type: "password",
    autoComplete: "current-password",
  });
  form.setOnSubmit(async (event: React.SyntheticEvent) => {
    try {
      const loginRequest = await performApiAction({
        url: `${clientConfig.edgeApi}/api/v1/users/login`,
        newData: getFormBodyObject(event.target as HTMLFormElement),
        resultNeeded: true,
      });
      if (loginRequest?.ok) {
        const rightRequest = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/rights/all`,
          xSession: loginRequest?.data.session,
          newData: {
            batchSize: 20,
          },
          resultNeeded: true,
        });
        const assetsRequest = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/assets/all`,
          xSession: loginRequest?.data.session,
          newData: {
            batchSize: 20,
          },
          resultNeeded: true,
        });
        if (rightRequest?.ok && assetsRequest?.ok) {
          localStorage.setItem(
            "created_at",
            JSON.stringify(new Date().getTime())
          );
          localStorage.setItem("assets", JSON.stringify(assetsRequest.data));
          localStorage.setItem("user", JSON.stringify(loginRequest.data));
          localStorage.setItem("right", JSON.stringify(rightRequest.data));
          navigate("/app/dashboard");
        } else {
          setError(`${t("login_data_request_error")}`);
        }
      } else {
        setError(`${t("login_request_error")} ${t(loginRequest?.error)}`);
      }
    } catch (e) {
      setError(t("login_server_error"));
    }
  });

  return (
    <form onSubmit={form.submit} className="mt-10 grid grid-cols-1 gap-y-8">
      <Text {...emailProps} />
      <div>
        <Text {...passwordProps} />
        <Link
          to="/app/lost-password"
          className="text-xs font-medium text-vettiblue-500 hover:underline">
          <T keyName="login.form.forgot-password" />
        </Link>
      </div>

      <div className="flex justify-start">
        <PrimaryButton
          type="submit"
          text={
            form.submitting ? (
              <i className="icon-[svg-spinners--dot-revolve] !mr-0"></i>
            ) : (
              <span className=" flex items-center py-1 text-[14px]">
                {t("login.form.submit")}
                <i className="icon-[prime--arrow-right] ml-2 text-[16px]"></i>
              </span>
            )
          }
          styleOverride="mb-0 min-w-[110px] px-4 py-2"
        />
      </div>
      {error && <Alert type="error">{error}</Alert>}
    </form>
  );
}

export default function LoginPage() {
  const { t } = useTranslate();

  useEffect(() => {
    document.title = `${t("login.title")} ${t("general.title-separator")} ${t(
      "vettify"
    )}`;
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {t("login.title")} {t("general.title-separator")} {t("vettify")}
        </title>
        <meta name="description" content={""} />
      </Helmet>
      <div className="grow-0"></div>
      <AuthLayout>
        <div>
          <a href="https://vettify.io/" aria-label={"landing page"}>
            <Logo className="mb-10 h-10 w-auto" />
          </a>
          <h2 className="text-lg font-semibold text-gray-900">
            <T keyName="login.title" />
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            <T keyName="login.subtitle-before" />{" "}
          </p>
          <Link
            to="/app/sign-up"
            className="text-xs font-medium text-vettiblue-500 hover:underline">
            <T keyName="login.subtitle-link" />
          </Link>
        </div>
        <LoginForm />
      </AuthLayout>
      <div className="grow-0"></div>
    </div>
  );
}
