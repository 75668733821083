import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { T, useTranslate } from "lib/translate";
import AuthLayout from "components/shared/AuthLayout";
import { Text } from "components/shared/Fields";
import useFetch, { getFormBodyObject } from "lib/fetch-utils";
import { passwordValidator, useForm } from "lib/hooks/form";
import Alert from "components/shared/Alert";
import clientConfig from "lib/client_config";
import Logo from "components/shared/Logo";
import { PrimaryButton } from "components/shared/button";

function ResetPasswordForm({ code }: { code: string | null }) {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { performApiAction, error, data: res } = useFetch();
  const [isPassedValidation, setIsPassedValidation] = useState(false);
  const [isValidationLoading, setIsValidationLoading] = useState(true);
  const [validationError, setValidationError] = useState("");
  const form = useForm();
  const codeProps = form.useRegister({
    name: "code",
    type: "hidden",
    value: code,
    required: false,
  });
  const newPasswordProps = form.useRegister({
    name: "password",
    label: t("user.password-reset.new-password.label"),
    type: "password",
    autoComplete: "new-password",
    validator: passwordValidator,
    errorText: t("general.form.error.invalid-password"),
  });
  const confirmNewPasswordProps = form.useRegister({
    name: "confirmNewPassword",
    label: t("user.password-reset.confirm-new-password.label"),
    type: "password",
    validator: (value: string) => value === newPasswordProps.value,
    errorText: t("user.password-reset.confirm-new-password.error"),
  });
  // This fetch should not use performApiAction, result should not overwrite by /api/v1/users/password-reset/process.
  const validateResetCodeURL = async () => {
    setValidationError("");
    setIsPassedValidation(false);
    try {
      const res = await fetch(
        `${clientConfig.edgeApi}/api/v1/users/password-reset/validate`,
        {
          method: "POST",
          body: JSON.stringify({ code }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      // validate the code failed
      if (Object.keys(data).length !== 0) {
        setValidationError(t("api.error.user.password-reset.invalid-token"));
        setIsValidationLoading(false);
        setIsPassedValidation(false);
        return;
      } else {
        setIsValidationLoading(false);
        setIsPassedValidation(true);
        codeProps.onChange(code as string);
      }
    } catch (e) {
      setValidationError(t("api.error.general.unknown-error"));
    }
  };
  useEffect(() => {
    if (code) {
      validateResetCodeURL();
    }
    // We don't want to run this effect when the form changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  form.setOnSubmit(async (event: React.SyntheticEvent) => {
    await performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/users/password-reset/process`,
      newData: {
        code: getFormBodyObject(event.target as HTMLFormElement).code,
        password: getFormBodyObject(event.target as HTMLFormElement).password,
      },
    });

    if (res?.ok) {
      const userSession = res.data.session;
      if (userSession) {
        const rightRequest = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/rights/all`,
          xSession: userSession,
          newData: {
            batchSize: 20,
          },
          resultNeeded: true,
        });
        const assetsRequest = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/assets/all`,
          xSession: userSession,
          newData: {
            batchSize: 20,
          },
          resultNeeded: true,
        });
        if (rightRequest?.ok && assetsRequest?.ok) {
          localStorage.setItem(
            "created_at",
            JSON.stringify(new Date().getTime())
          );
          localStorage.setItem("assets", JSON.stringify(assetsRequest.data));
          localStorage.setItem("user", JSON.stringify(res.data));
          localStorage.setItem("right", JSON.stringify(rightRequest.data));
          // updated the navigation history when request is successful.
          history.pushState({}, "", window.location.href);
          navigate("/app/dashboard");
        }
      }
    }
  });

  return (
    <>
      {!isPassedValidation && !isValidationLoading && (
        <Alert type="error">{validationError}</Alert>
      )}
      {isPassedValidation && !isValidationLoading && (
        <form onSubmit={form.submit} className="mt-10 grid grid-cols-1 gap-y-8">
          <Text {...codeProps} className="hidden" />
          <Text {...newPasswordProps} />
          <Text {...confirmNewPasswordProps} />

          <div className="flex items-center justify-start">
            <PrimaryButton
              type="submit"
              text={
                form.submitting ? (
                  <i className="icon-[svg-spinners--dot-revolve] !mr-0"></i>
                ) : (
                  <span className=" flex items-center py-1">
                    {t("user.password-reset.form.submit")}
                    <i className="icon-[prime--arrow-right] ml-2 text-xl"></i>
                  </span>
                )
              }
              styleOverride="mb-0 w-100 min-w-[180px] px-4 py-2"
            />
          </div>
          {error && <Alert type="error">{error}</Alert>}
        </form>
      )}
    </>
  );
}

export default function PasswordReset() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const { t } = useTranslate();
  useEffect(() => {
    if (!code) {
      navigate("/app/login");
    }
  }, [code]);
  return (
    <>
      <Helmet>
        <title>
          {t("password-reset.title") +
            t("general.title-separator") +
            t("vettify")}
        </title>
        <meta
          name="description"
          content={t("password-reset.meta.description")}
        />
      </Helmet>

      <AuthLayout>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://vettify.io/"
          aria-label={"landing page"}>
          <Logo className="mb-10 h-10 w-auto" />
        </a>
        <h2 className="text-lg font-semibold text-gray-900">
          <T keyName="password-reset.title" />
        </h2>
        <ResetPasswordForm code={code} />
      </AuthLayout>
    </>
  );
}
