import { useState } from "react";
import { Helmet } from "react-helmet";
import { T } from "@tolgee/react";
import { useTranslate } from "lib/translate";
import { TitleAndText } from "components/shared/TitleAndText";
import { Text } from "components/shared/Fields";
import { splitTextOnNewLine } from "lib/common";
import useFetch from "lib/fetch-utils";
import Alert from "components/shared/Alert";
import clientConfig from "lib/client_config";
import { useUserData } from "lib/hooks/UserProvider";
import { urlValidator } from "lib/validators";
import { PrimaryButton } from "components/shared/button";
import { emailValidator } from "lib/hooks/form";

export function PageContents() {
  const [userData] = useUserData();
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const {
    performApiAction,
    resetStatus,
    isLoading,
    error,
    data: res,
  } = useFetch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isFormDataValid =
    companyName &&
    companyWebsite &&
    firstName &&
    lastName &&
    emailValidator(email) &&
    urlValidator(companyWebsite);

  const { t } = useTranslate();

  const handleSendRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (isLoading || !isFormDataValid) {
      return;
    }
    setIsSubmitting(true);

    const requestData = {
      orgname: companyName,
      website: companyWebsite,
      firstname: firstName,
      lastname: lastName,
      email,
    };
    await performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/invites/user-org/send`,
      xSession: userData?.session,
      newData: requestData,
      errorMessage: t("notifications.request.failed"),
      onSuccess: () => {
        setCompanyName("");
        setCompanyWebsite("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setIsSubmitted(false);
      },
    });
    setIsSubmitting(false);
  };

  return (
    <>
      <div id="invite-org-page">
        <TitleAndText
          title={t("org.new-request-invitation")}
          text={splitTextOnNewLine(t("org.new-request-invitation.description"))}
        />
      </div>
      <div className="mt-8 max-w-4xl">
        <form className="grid grid-cols-2 gap-6" onSubmit={handleSendRequest}>
          <Text
            label={t("org.invite.form.company-name.label")}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder={t("org.invite.form.company-name.placeholder")}
            errorText={
              isSubmitted && !companyName
                ? t("org.invite.form.company-name.error")
                : ""
            }
          />
          <Text
            label={t("org.invite.form.company-website.label")}
            value={companyWebsite}
            onChange={(e) => setCompanyWebsite(e.target.value)}
            placeholder={t("org.invite.form.company-website.placeholder")}
            errorText={
              isSubmitted && !urlValidator(companyWebsite)
                ? t("errors.url")
                : ""
            }
          />
          <Text
            label={t("org.invite.form.first-name.label")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder={t("org.invite.form.first-name.placeholder")}
            errorText={
              isSubmitted && !firstName
                ? t("org.invite.form.first-name.error")
                : ""
            }
          />
          <Text
            label={t("org.invite.form.last-name.label")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={t("org.invite.form.last-name.placeholder")}
            errorText={
              isSubmitted && !lastName
                ? t("org.invite.form.last-name.error")
                : ""
            }
          />
          <Text
            label={t("org.invite.form.email.label")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("org.invite.form.email.placeholder")}
            errorText={
              isSubmitted && !emailValidator(email)
                ? t("org.invite.form.email.error")
                : ""
            }
          />

          <div className="col-span-2 flex justify-start">
            <PrimaryButton
              type="submit"
              text={
                isSubmitting ? (
                  <i className="icon-[svg-spinners--dot-revolve] !mr-0"></i>
                ) : (
                  t("org.invite.form.submit.label")
                )
              }
              styleOverride="px-4 py-2 min-w-[100px]"
            />
          </div>
        </form>
        {res?.ok && (
          <Alert
            type="success"
            resetStatus={() => {
              resetStatus("success");
            }}>
            <T keyName="notifications.request.success" />
          </Alert>
        )}
        {error && (
          <Alert
            type="error"
            resetStatus={() => {
              resetStatus("error");
            }}>
            {error}
          </Alert>
        )}
      </div>
    </>
  );
}

export default function InviteOrg() {
  const { t } = useTranslate();
  return (
    <>
      <Helmet>
        <title>
          {t("invite-org.tab.title") +
            t("general.title-separator") +
            t("vettify")}
        </title>
        <meta name="description" content={t("orgs.details.meta.description")} />
      </Helmet>
      <PageContents />
    </>
  );
}
