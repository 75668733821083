import React, { useMemo, useEffect } from "react";
import "./App.css";
import "./styles/theme.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import PrivateRoute from "components/shared/PrivateRoute";
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from "@tolgee/react";
import en from "i18n/en.json";
import config from "./lib/client_config";
import { useLocation } from "react-router-dom";
import LostPasswordPage from "components/login/LostPasswordPage";
import Dashboard from "components/dashboard/index";
import DashboardLayout from "components/dashboard/DashboardLayout";
import Settings from "components/Settings";
import Analytics from "components/Analytics";
import InviteOrg from "components/InviteOrg";
import AssetEdit from "components/AssetEdit";
import ViewDetail from "components/ViewDetail";
import ViewSnippet from "components/ViewSnippet";
import OrgInvite from "components/inviteFlow/OrgInvite";
import AssetInvite from "components/inviteFlow/AssetInvite";
import ExternalViewSnippet from "components/ExternalViewSnippet";
import Attestation from "components/Attestation";
import PasswordResetPage from "components/PasswordReset";
import EmailConfirmationForm from "components/signUp/EmailConfirmationForm";
import SignUpForm from "components/signUp/SignUpForm";
import SignUpFork from "components/login/SignUpFork";
import LoginPage from "components/login/LoginPage";
import NotFound from "components/NotFound";
import New from "components/New";

interface RecursiveStringObject {
  [key: string]: string | RecursiveStringObject;
}

// Function to recursively copy the object and its properties, but replacing the property value with a string
// This is used to create an empty object with the same structure as the original object
function emptyObject(obj: RecursiveStringObject) {
  const result: RecursiveStringObject = {};
  for (const prop in obj) {
    if (typeof obj[prop] === "object") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result[prop] = emptyObject(obj[prop] as any);
    } else {
      // repeat U+2002 (1/2 em space) the number of times of the string length
      result[prop] = String.fromCharCode(8194).repeat(
        (obj[prop] as string).length
      );
    }
  }
  return result;
}

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    // for development
    defaultLanguage: "en",
    apiKey: config.tolgee.publicKey,
    apiUrl: config.tolgee.publicUrl,

    // for production
    staticData: {
      en,
      cs: emptyObject(en),
    },
  });

function App() {
  const location = useLocation();

  useMemo(async () => {
    tolgee.setEmitterActive(false);
    tolgee.changeLanguage("en");
    tolgee.setEmitterActive(true);
  }, [location]);

  useEffect(() => {
    function toggleLanguageEvent(e: KeyboardEvent) {
      if (
        e.ctrlKey &&
        e.shiftKey &&
        e.altKey &&
        (e.key === "L" || e.code === "KeyL")
      ) {
        e.preventDefault();
        e.stopPropagation();
        tolgee.changeLanguage(tolgee.getLanguage() === "en" ? "cs" : "en");
        console.log(`Language changed to ${tolgee.getLanguage()}`);
      }
    }
    document.addEventListener("keyup", toggleLanguageEvent);
    return () => {
      document.removeEventListener("keyup", toggleLanguageEvent);
    };
  }, []);

  return (
    <div className="h-full">
      <TolgeeProvider tolgee={tolgee} options={{ useSuspense: false }}>
        <Routes>
          {/* If the url doesn't match any paths, send them to the index */}
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<SignUpFork />} />
          <Route path="/app/login" element={<LoginPage />} />
          <Route path="/app/lost-password" element={<LostPasswordPage />} />
          <Route path="/app/password-reset" element={<PasswordResetPage />} />
          <Route path="/app/asset-invite" element={<AssetInvite />} />
          <Route path="/app/org-invite" element={<OrgInvite />} />
          <Route path="/app/view-snippet" element={<ExternalViewSnippet />} />
          <Route path="/app/attestation" element={<Attestation />} />
          <Route path="/app/sign-up" element={<SignUpForm />} />
          <Route
            path="/app/email-confirmation"
            element={<EmailConfirmationForm />}
          />
          {/* Dashboard routes with nested navigation */}
          <Route
            path="/app/dashboard"
            element={
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            }>
            <Route index element={<Dashboard />} />
            <Route path="settings" element={<Settings />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="invite-org" element={<InviteOrg />} />
            <Route path="asset-edit" element={<AssetEdit />} />
            <Route path="view-detail" element={<ViewDetail />} />
            <Route path="view-snippet" element={<ViewSnippet />} />
            <Route path="new" element={<New />} />
          </Route>
        </Routes>
      </TolgeeProvider>
    </div>
  );
}

const AppWithRouter = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default AppWithRouter;
