import { useEffect, useState } from "react";
import Prism from "prismjs";
import clsx from "clsx";
import { Tooltip } from "primereact/tooltip";
import { Code } from "../Code";
import "prismjs/themes/prism-tomorrow.css";
import { useTranslate } from "lib/translate";
// eslint-disable-next-line
export default function ViewSnippetTab(snippet: any) {
  const { t } = useTranslate();
  const tabs = [
    {
      name: t("external-view-snippet-title-code"),
      current: true,
      value: "snippetCode",
      description: t("external-view-snippet-code-description"),
    },

    // { name: t("external-view-snippet-title-tag"), current: true, value: "snippetTag", description: t("external-view-snippet-tag-description") },
    // { name: t("external-view-snippet-title-frame"), current: false, value: "snippetFrame", description: t("external-view-snippet-frame-description") },
    // { name: t("external-view-snippet-title-script"), current: false, value: "snippetScript", description: t("external-view-snippet-script-description") },
  ];
  const [selected, setSelected] = useState(tabs);
  const [isCopied, setIsCopied] = useState(false);
  const [copyErrorMessage, setCopyErrorMessage] = useState("");
  const targetLink = `https://cdn.vettify.io/asset/link/${snippet.snippet.asset.right_code}`;

  const code = `
  <div style="position: relative; width: 100%; height: 100%; text-align: center">
    <a href="${targetLink}" target="_blank">
      <img style="max-width: 100%; max-height: 100%; width: auto; height: auto;" src="https://cdn.vettify.io/asset/view/${
        snippet.snippet.asset.right_code
      }/${encodeURIComponent(snippet.snippet.asset.filename)}" alt="${
    snippet.snippet.asset.alt_attribute
  }"/>
    </a>
  </div>
  `;
  async function handleCopy(text: string) {
    setCopyErrorMessage("");

    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (err) {
      setIsCopied(false);
      setCopyErrorMessage("Failed to copy. Please try again");
    }
  }

  useEffect(() => {
    const highlight = async () => {
      await Prism.highlightAll();
    };
    highlight();
  }, []);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          {t("snippet-tab.label")}
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current)?.name}>
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {selected.map((tab, idx) => (
              <div key={idx}>
                <Tooltip target=".custom-target-icon" />
                <button
                  data-pr-tooltip={tab.description}
                  data-pr-position="right"
                  data-pr-at="right+5 center"
                  data-pr-my="left center-2"
                  key={tab.name}
                  onClick={() => {
                    setSelected(
                      selected.map((item) => {
                        if (item.name === tab.name) {
                          return { ...item, current: true };
                        } else {
                          return { ...item, current: false };
                        }
                      })
                    );
                  }}
                  className={clsx(
                    "custom-target-icon",
                    tab.current
                      ? "border-blue-500 text-blue-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}>
                  {tab.name}
                  <span className="icon-[ion--information-circle-outline] ml-2"></span>
                </button>
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className="mb-8 mt-4 flex flex-col overflow-auto ">
        <Code code={code} id="snippet" canCopy />
      </div>
      <button
        onClick={() => handleCopy(code)}
        className="rounded bg-blue-500 px-6 py-2 text-center font-semibold text-white">
        {isCopied ? "Copied" : t("external-view-snippet-copy-button")}
      </button>
      {copyErrorMessage && (
        <p className=" mt-1 text-red-700">{copyErrorMessage}</p>
      )}
    </div>
  );
}
