import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import { Image } from "primereact/image";
import { Chips } from "primereact/chips";
import { Tooltip } from "primereact/tooltip";
import clsx from "clsx";
import { useTranslate } from "lib/translate";
import { TitleAndText } from "components/shared/TitleAndText";
import { Text, TextArea } from "components/shared/Fields";
import useFetch from "lib/fetch-utils";
import Alert from "components/shared/Alert";
import { urlValidator } from "lib/validators";
import { useUserData } from "lib/hooks/UserProvider";
import clientConfig from "lib/client_config";
import type { Asset } from "lib/types/assets";
import {
  arrayBufferToHexEncodedString,
  validImageFileExtensions,
} from "lib/fileUpload";
import { PrimaryButton, SecondaryButton } from "components/shared/button";
import { useAssetsData } from "lib/hooks/AssetData";
import { useCurrentOrgId } from "lib/hooks/currentOrg";
import { useNavigate, useLocation } from "react-router-dom";
import DragAndDrop from "components/DragAndDrop";
import { extensionChipStyles } from "lib/extensionChipStyles";

interface EditAssetData {
  assetName: string;
  assetExtension: string;
  description: string;
  tags: string[] | [];
  assetImage: string;
  newFile?: {
    mimetype: string;
    file: string;
  };
  assetDefaultTargetURL: string;
  altAttribute: string;
}
const initialEditAssetData: EditAssetData = {
  assetName: "",
  assetExtension: "",
  description: "",
  tags: [],
  assetImage: "",
  assetDefaultTargetURL: "",
  altAttribute: "",
};

function PageContents() {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editId = searchParams.get("editId");
  const [userData, setUserData] = useUserData();
  const [orgId] = useCurrentOrgId();
  const [assetsData, setAssetsData] = useAssetsData();

  const {
    performApiAction,
    error: performApiActionError,
    resetStatus,
    isLoading,
  } = useFetch();
  const [loading, setLoading] = useState(true);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [currentEdit, setCurrentEdit] =
    useState<EditAssetData>(initialEditAssetData);
  const [uploadError, setUploadError] = useState("");
  const editAsset = assetsData.assets?.find(
    (asset: Asset) => asset.id === Number(editId)
  );
  const currentOrg = userData?.orgs.find(
    (org: { id: number }) => org.id === orgId
  );
  const currentMainLogo = currentOrg?.logo.asset_id === Number(editId);
  const updateCurrentAssetField = ({
    fieldName,
    value,
  }: {
    fieldName: string;
    value: string | boolean | string[];
  }) => {
    setCurrentEdit((prevUserData) => ({
      ...prevUserData,
      [fieldName]: value,
    }));
  };
  const updateAssetButtonOnClickHandler = (currentEdit: EditAssetData) => {
    if (
      !currentEdit.assetName ||
      isLoading ||
      !urlValidator(currentEdit.assetDefaultTargetURL)
    ) {
      return;
    }

    const {
      assetName,
      assetExtension,
      description,
      tags,
      newFile,
      assetDefaultTargetURL,
      altAttribute,
    } = currentEdit;
    const fileNameWithExtension = `${assetName}.${assetExtension}`;

    const updateAssets = async () => {
      const response = await performApiAction({
        url: `${clientConfig.edgeApi}/api/v1/assets/all`,
        xSession: userData?.session,
        newData: {
          batchSize: 20,
        },
        resultNeeded: true,
      });
      if (response?.ok) {
        localStorage.setItem("assets", JSON.stringify(response.data));
        setAssetsData(response.data);
      }
    };
    const updateOrgList = async () => {
      const response = await performApiAction({
        url: `${clientConfig.edgeApi}/api/v1/orgs/list`,
        method: "GET",
        xSession: userData?.session,
        resultNeeded: true,
      });
      if (response?.ok) {
        const { orgs } = response.data;
        const updatedUserData = {
          ...userData,
          orgs,
        };
        localStorage.setItem("user", JSON.stringify(updatedUserData));
        setUserData(updatedUserData);
      }
    };

    performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/assets/update`,
      xSession: userData?.session,
      newData: {
        id: Number(editId),
        filename: fileNameWithExtension,
        tags: tags.length > 0 ? tags.join(",") : "",
        description,
        file: newFile || undefined,
        target_url: assetDefaultTargetURL,
        alt_attribute: altAttribute,
      },
      onSuccess: async () => {
        await updateAssets();
        if (currentMainLogo) {
          await updateOrgList();
        }
        navigate("/app/dashboard");
      },
    });
  };

  // const deleteButtonOnClickHandler = async () => {
  //   const newAssets = assets!.filter(logo => logo.id !== editAsset!.id);

  //   performApiAction({
  //     url: `/api/v1/org/${orgId}/asset`,
  //     method: "DELETE",
  //     newData: { id: Number(editAsset!.id) },
  //     onSuccess: async () => {
  //       await mutate(newAssets, {
  //         optimisticData: newAssets,
  //         throwOnError: false,
  //       });
  //       router.push("/app/assets");
  //     },
  //   });
  // };
  // const confirmDelete = () => {
  //   confirmDialog({
  //     message: t("orgs.assets.delete.message"),
  //     header: t("orgs.assets.delete.confirm-title"),
  //     acceptLabel: t("orgs.assets.delete.accept"),
  //     acceptClassName: "p-button-danger",
  //     rejectLabel: t("orgs.assets.delete.reject"),
  //     accept: () => deleteButtonOnClickHandler(),
  //   });
  // };

  const onFileSelect = async (file: File | null) => {
    if (!file) {
      setUploadError(t("orgs.asset-edit.form.error-message.no-file"));
      return;
    }

    if (!validImageFileExtensions.includes(file.name.split(".").pop() || "")) {
      setUploadError(t("orgs.asset-edit.form.error-message.invalid-file-type"));

      return;
    }
    if (file.size > 1024 * 1024 * 10) {
      setUploadError(t("orgs.asset-edit.form.error-message.invalid-file-size"));
      return;
    }
    const arrayBuffer = file.arrayBuffer();
    const data = await arrayBufferToHexEncodedString(arrayBuffer);

    setCurrentEdit((prevUserData) => ({
      ...prevUserData,
      assetImage: URL.createObjectURL(file),
      newFile: {
        mimetype: file?.type,
        file: data,
      },
      assetExtension: file?.name.split(".").pop() || "",
    }));
  };
  useEffect(() => {
    if (assetsData?.assets) {
      const isCorrectUser = assetsData.assets.some(
        (asset: Asset) => asset.id === Number(editId)
      );
      if (!isCorrectUser) {
        navigate("/app/dashboard");
      }
    }
  }, [assetsData?.assets, editId]);
  useEffect(() => {
    // Run only first time to get editAsset, prevent call again
    if (editAsset && loading) {
      setCurrentEdit({
        assetName: editAsset.filename.split(".").shift() || "",
        assetExtension: editAsset.filename.split(".").pop() || "",
        description: editAsset.description,
        tags: editAsset.tags ? editAsset.tags.split(",") : [],
        assetImage: `https://cdn.vettify.io/asset/file/${editAsset.asset_code}`,
        assetDefaultTargetURL: editAsset.target_url,
        altAttribute: editAsset.alt_attribute,
      });
      setLoading(false);
    }
  }, [editAsset, loading]);
  return (
    <>
      {loading ? (
        <div className="flex h-6 min-h-[50vh] w-full min-w-[64px] flex-col items-center justify-center">
          <i className="icon-[svg-spinners--dot-revolve] text-center text-4xl text-vettiblue-500"></i>
        </div>
      ) : (
        <div className="max-w-[1440px] pb-5">
          <div className="mx-auto mb-10 w-[100%] lg:w-[600px]">
            <TitleAndText
              title={t("orgs.asset-edit.title")}
              text={t("orgs.asset-edit.text")}
            />
          </div>
          <div>
            <div className="mx-auto w-[100%] lg:w-[600px]">
              <div className="mb-1 text-sm font-medium text-gray-700">
                {t("orgs.asset-edit.form.label.image")}
              </div>
              <div className="inline-flex flex-col">
                {currentEdit.assetImage ? (
                  <div className="mb-1">
                    <div className="inline-block rounded-sm border  bg-gray-50 p-2">
                      <Image
                        src={currentEdit.assetImage}
                        alt={currentEdit.assetName}
                        preview
                        imageClassName="w-[160px] h-[160px] object-contain rounded-sm  "
                      />
                    </div>
                  </div>
                ) : (
                  <div className="inline-block rounded border p-2">
                    <i className="icon-[material-symbols--image] h-[80px] w-[80px] text-gray-500"></i>
                  </div>
                )}
                <div className="w-full">
                  <DragAndDrop onFileSelect={onFileSelect}>
                    <Tooltip
                      target=".custom-target-icon"
                      content={t("orgs.assets.update-asset.button.tooltip")}
                      position="right"
                    />
                    <SecondaryButton
                      text="Choose file"
                      styleOverride={clsx(
                        "custom-target-icon !mb-4 me-0 w-full",
                        uploadError ? "!mb-0" : "mb-4"
                      )}
                      onClick={() => {
                        imageInputRef.current?.click();
                      }}
                    />
                    <input
                      type="file"
                      multiple
                      accept={validImageFileExtensions
                        .map((ext: string) => `.${ext}`)
                        .join(",")}
                      ref={imageInputRef}
                      name="file"
                      className="hidden"
                      aria-hidden="true"
                      onChange={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        const file =
                          (event.target?.files && event.target.files[0]) ||
                          null;
                        onFileSelect(file);
                      }}
                    />
                  </DragAndDrop>
                </div>
              </div>
              <div>
                {uploadError && (
                  <Alert
                    type="error"
                    className="mb-4 max-w-[600px]"
                    resetStatus={() => {
                      setUploadError("");
                    }}>
                    {uploadError}
                  </Alert>
                )}
              </div>

              <div className="mb-5">
                <p className="mb-1 text-sm">
                  {t("orgs.asset-edit.form.label.file-extension")}
                </p>
                <div
                  className={`mb-2 mr-2 inline-block rounded-full border px-3 ${extensionChipStyles(
                    currentEdit.assetExtension
                  )}`}>
                  <p className="text-sm">{currentEdit.assetExtension}</p>
                </div>
              </div>

              <Text
                errorText={
                  currentEdit.assetName.length === 0
                    ? t("orgs.asset-edit.form.error.asset-name")
                    : ""
                }
                className="mb-5"
                label={t("orgs.asset-edit.form.label.asset-name")}
                value={currentEdit.assetName}
                onChange={(e) =>
                  updateCurrentAssetField({
                    fieldName: "assetName",
                    value: e.target.value,
                  })
                }
                placeholder={t("orgs.asset-edit.form.placeholder.asset-name")}
                withTooltip={true}
                tooltipText={t("orgs.asset-edit.form.tooltip.asset-name")}
              />

              <TextArea
                className="mb-5"
                label={t("orgs.asset-edit.form.label.description")}
                value={currentEdit.description}
                onChange={(e) =>
                  updateCurrentAssetField({
                    fieldName: "description",
                    value: e.target.value,
                  })
                }
                placeholder={t("orgs.asset-edit.form.placeholder.description")}
                withTooltip={true}
                tooltipText={t("orgs.asset-edit.form.tooltip.description")}
              />
              <Text
                className="mb-5"
                label={t("orgs.asset-edit.form.label.alt")}
                value={currentEdit.altAttribute}
                onChange={(e) =>
                  updateCurrentAssetField({
                    fieldName: "altAttribute",
                    value: e.target.value,
                  })
                }
                placeholder={t("orgs.asset-edit.form.placeholder.alt")}
                withTooltip={true}
                tooltipText={t("orgs.asset-edit.form.tooltip.alt")}
              />
              <div className=" p-fluid mb-5">
                <div className="text-sm font-medium text-gray-700">
                  {t("orgs.asset-edit.form.label.tags")}
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon icon-[ion--information-circle-outline] ml-1 text-center  align-middle text-gray-500"
                    data-pr-tooltip={t("orgs.asset-edit.form.tooltip.tags")}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"></i>
                </div>
                <Chips
                  inputId="tags"
                  className="!border-transparent"
                  value={currentEdit.tags}
                  onChange={(e) =>
                    updateCurrentAssetField({
                      fieldName: "tags",
                      value: e.value as string[],
                    })
                  }
                  separator=","
                />
              </div>
              <Text
                errorText={
                  !urlValidator(currentEdit.assetDefaultTargetURL)
                    ? t("errors.url")
                    : ""
                }
                className="mb-5"
                label={t("orgs.asset-edit.form.label.asset-default-URL")}
                value={currentEdit.assetDefaultTargetURL}
                onChange={(e) =>
                  updateCurrentAssetField({
                    fieldName: "assetDefaultTargetURL",
                    value: e.target.value,
                  })
                }
                placeholder={t(
                  "orgs.asset-edit.form.placeholder.asset-default-URL"
                )}
                withTooltip={true}
                tooltipText={t(
                  "orgs.asset-edit.form.tooltip.asset-asset-default-URL"
                )}
              />
            </div>
          </div>

          <div className="mx-auto mt-5 w-[100%] lg:w-[600px]">
            {performApiActionError && (
              <Alert
                type="error"
                className="mb-4 max-w-[600px]"
                resetStatus={() => {
                  resetStatus("error");
                }}>
                {performApiActionError}
              </Alert>
            )}

            <PrimaryButton
              text={
                isLoading ? (
                  <i className="icon-[svg-spinners--dot-revolve] !mr-0"></i>
                ) : (
                  t("orgs.asset-edit.form.button.save")
                )
              }
              type="button"
              onClick={() => updateAssetButtonOnClickHandler(currentEdit)}
              styleOverride="px-4 py-2"
            />
          </div>
        </div>
      )}
    </>
  );
}
function AssetEdit() {
  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title>
          {t("orgs.asset-edit.title") +
            t("general.title-separator") +
            t("vettify")}
        </title>
        <meta
          name="description"
          content={t("orgs.asset-edit.meta.description")}
        />
      </Helmet>
      <PageContents />
    </>
  );
}
export default AssetEdit;
