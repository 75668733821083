import {
  T as TolgeeT,
  useTranslate as tolgeeUseTranslate,
} from "@tolgee/react";
import type en from "i18n/en.json";

export type TranslationsType = typeof en;

export type DotNotationEntries<T> = T extends object
  ? {
      [K in keyof T]: `${K & string}${T[K] extends undefined
        ? ""
        : T[K] extends object
        ? `.${DotNotationEntries<T[K]>}`
        : ""}`;
    }[keyof T]
  : "";

export type TranslationKeys = DotNotationEntries<TranslationsType>;

export const useTranslate: () => {
  t: (key: TranslationKeys, fallback?: string) => string;
  isLoading: boolean;
} = tolgeeUseTranslate;

interface Props {
  keyName: TranslationKeys;
  children?: never;
}

type TProps = Omit<
  React.ComponentProps<typeof TolgeeT>,
  "keyName" | "children"
> &
  Props;

export const T = (props: TProps) => <TolgeeT {...props} />;
