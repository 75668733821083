import type React from "react";
import type { DragEvent } from "react";
import { useState } from "react";
import { useTranslate } from "lib/translate";

type DragAndDropProps = DragEvent<HTMLDivElement>;

const DragAndDrop = ({
  children,
  onFileSelect,
}: {
  children: React.ReactNode;
  onFileSelect: (file: File | null) => void;
}) => {
  const [dragging, setDragging] = useState(false);
  const { t } = useTranslate();
  const handleDragOver = (e: DragAndDropProps) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragEnter = (e: DragAndDropProps) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: DragAndDropProps) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: DragAndDropProps) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const file = e.dataTransfer.files[0];
    onFileSelect(file);
  };

  return (
    <div
      className={`w-full ${
        dragging
          ? "h-[80px] rounded-lg border border-dashed border-blue-500"
          : ""
      }`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}>
      {dragging ? (
        <div className="mt-2 text-center align-middle">
          <span
            className="icon-[heroicons--cloud-arrow-up] mx-auto text-2xl text-blue-500 "
            aria-hidden
          />
          <p>{t("asset-edit.drop-event.text")}</p>
        </div>
      ) : (
        [children]
      )}
    </div>
  );
};

export default DragAndDrop;
