import { Transition } from "@headlessui/react";
import type { PropsWithChildren } from "react";
import { Fragment } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const AppearTransition = (props: PropsWithChildren<Props>) => {
  return (
    <Transition
      as={Fragment}
      enter="duration-150 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      {...props}
    />
  );
};
