import { createContext, useContext, useEffect, useState } from "react";
import type { UserData } from "../types/userData";
import clientConfig from "../client_config";
import useFetch from "../fetch-utils";
const userDataContext = createContext<
  | [
      UserData | undefined,
      // eslint-disable-next-line
      React.Dispatch<React.SetStateAction<any>>,
      string | null,
      boolean
    ]
  | null
>(null);

export const UserDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userData, setUserData] = useState<UserData | undefined>();
  const {
    performApiAction,
    error: fetchOrgsListError,
    isLoading: isFetchOrgsListReady,
  } = useFetch();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const session = JSON.parse(user).session;
      const userData = JSON.parse(user);
      const fetchOrgsList = async () => {
        const updatedOrgsList = await performApiAction({
          url: `${clientConfig.edgeApi}/api/v1/orgs/list`,
          method: "GET",
          xSession: session,
          resultNeeded: true,
        });
        if (updatedOrgsList?.ok) {
          setUserData({
            ...(userData as UserData),
            orgs: updatedOrgsList.data.orgs,
          });
        }
      };
      fetchOrgsList();
    }
    // trigger only once when component mounted
  }, []);

  return (
    <userDataContext.Provider
      value={[userData, setUserData, fetchOrgsListError, isFetchOrgsListReady]}>
      {children}
    </userDataContext.Provider>
  );
};

export function useUserData() {
  const value = useContext(userDataContext);
  if (value === null) {
    throw new Error("userData must be used within a UserDataProvider");
  }
  return value;
}
