import { useEffect, useState } from "react";
import { PrimaryButton } from "components/shared/button";
import Alert from "components/shared/Alert";
import { useTranslate } from "lib/translate";
import Form from "components/shared/Form";
import { Text } from "components/shared/Fields";
import useFetch from "lib/fetch-utils";
import { useUserData } from "lib/hooks/UserProvider";
import clientConfig from "lib/client_config";

const Profile = () => {
  const { t } = useTranslate();
  const [userData, setUserData] = useUserData();
  const {
    performApiAction,
    error: postRequestError,
    resetStatus,
    data,
  } = useFetch();
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [updateUserNameErrorMessage, setUpdateUserNameErrorMessage] =
    useState("");
  const [updateUserNameSuccessMessage, setUpdateUserNameSuccessMessage] =
    useState("");

  useEffect(() => {
    if (userData) {
      setUserFirstName(userData?.user.firstname);
      setUserLastName(userData?.user.lastname);
      setUserEmail(userData?.user.email);
    }
  }, [userData]);

  useEffect(() => {
    if (data?.ok) {
      const updatedUserData = {
        ...userData,
        user: {
          ...userData?.user,
          firstname: userFirstName,
          lastname: userLastName,
        },
      };
      // Update the user data in the local storage
      localStorage.setItem("user", JSON.stringify(updatedUserData));
      // Update the user data in the context
      setUserData(updatedUserData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.ok]);

  const updateUserName = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Reset POST request error message.
    resetStatus("error");
    // Reset error / success message
    setUpdateUserNameErrorMessage("");
    setUpdateUserNameSuccessMessage("");

    if (!userFirstName || !userLastName) {
      setUpdateUserNameErrorMessage(t("setting.user-info.name.error"));
      return;
    }
    performApiAction({
      url: `${clientConfig.edgeApi}/api/v1/users/update-profile`,
      xSession: userData?.session,
      newData: {
        firstname: userFirstName,
        lastname: userLastName,
      },
      onSuccess: async () => {
        // Update the user data from the server
        setUpdateUserNameSuccessMessage(
          t("setting.user-info.submit.user-name.success")
        );
      },
    });
  };

  return (
    <>
      <h3 className="text-xl font-bold">{t("setting.user-info.title")}</h3>
      <div className="my-4">{t("setting.user-info.subtitle")}</div>
      <Form onSubmit={updateUserName}>
        <Text
          label={t("setting.user-info.form.first-name.label")}
          withTooltip={true}
          tooltipIcon={
            <i
              className="custom-target-icon icon-[streamline-emojis--globe-showing-americas] ml-1"
              data-pr-tooltip={t(
                "setting.user-info.form.public-pieces.tooltip"
              )}
              data-pr-position="right"
              data-pr-at="right+5 top"
              data-pr-my="left center-2"></i>
          }
          value={userFirstName}
          onChange={(e) => setUserFirstName(e.target.value)}
        />
        <Text
          label={t("setting.user-info.form.last-name.label")}
          withTooltip={true}
          tooltipIcon={
            <i
              className="custom-target-icon icon-[streamline-emojis--globe-showing-americas] ml-1"
              data-pr-tooltip={t(
                "setting.user-info.form.public-pieces.tooltip"
              )}
              data-pr-position="right"
              data-pr-at="right+5 top"
              data-pr-my="left center-2"></i>
          }
          value={userLastName}
          onChange={(e) => setUserLastName(e.target.value)}
        />
        <Text
          disabled={true}
          label={t("setting.user-info.form.email.label")}
          value={userEmail}
        />
        {postRequestError && (
          <div className="col-span-full">
            <Alert
              type="error"
              className="mt-0 max-w-[640px]"
              resetStatus={() => {
                resetStatus("error");
              }}>
              {postRequestError}
            </Alert>
          </div>
        )}

        <div className="col-span-full">
          <PrimaryButton
            type="submit"
            text={t("setting.user-info.submit.user-name.button")}
            styleOverride="px-4 py-2 w-full sm:!w-[100px]"
          />
        </div>
        {updateUserNameErrorMessage && (
          <div className="col-span-full">
            <Alert
              type="error"
              className="mt-0 max-w-[640px]"
              resetStatus={() => {
                setUpdateUserNameErrorMessage("");
              }}>
              {updateUserNameErrorMessage}
            </Alert>
          </div>
        )}
        {updateUserNameSuccessMessage && (
          <div className="col-span-full">
            <Alert
              type="success"
              className="mt-0 max-w-[640px]"
              resetStatus={() => {
                setUpdateUserNameSuccessMessage("");
              }}>
              {updateUserNameSuccessMessage}
            </Alert>
          </div>
        )}
      </Form>
    </>
  );
};

export default Profile;
