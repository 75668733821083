import { Image } from "primereact/image";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "primereact/tooltip";
import { PrimaryButton, SecondaryButton } from "components/shared/button";
import ConfirmModal from "components/shared/ConfirmModal";

import Alert from "components/shared/Alert";
import { useBreakPoint } from "lib/hooks/useBreakPoint";
import clientConfig from "lib/client_config";
import useFetch from "lib/fetch-utils";
import { useUserData } from "lib/hooks/UserProvider";
import type { ManagementCardProps } from "lib/types/right";
import {
  getStatus,
  getStatusStyles,
  getStatusTooltip,
} from "lib/hooks/rightStatus";
import { useTranslate } from "lib/translate";
import { useCurrentOrgId } from "lib/hooks/currentOrg";
import Divider from "components/shared/Divider";

const ManagementCard = ({
  owned,
  receiverInfo,
  inviterInfo,
  active,
  assetId,
  rightId,
  rightCode,
  createdAt,
  lastSeen,
  relationship,
  targetURL,
  targetURLType,
  assetCode,
  assetName,
  assetDescription,
  altAttribute,
  messages,
  now,
  defaultTargetURL,
  updatedOwnedStatus,
}: ManagementCardProps) => {
  const [currentOrgId] = useCurrentOrgId();
  const { t } = useTranslate();
  const [userData] = useUserData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState<string | undefined>("");
  const { sm, lg, xl } = useBreakPoint();
  const { performApiAction, error, resetStatus } = useFetch();
  const type = owned ? "Sent" : "Received";
  const { chipStyle, circleStyle } = getStatusStyles(status as string);
  const navigate = useNavigate();

  useEffect(() => {
    setStatus(
      getStatus({
        active,
        lastSeen,
      })
    );
  }, [active, lastSeen]);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="mb-4 w-full rounded-lg border border-gray-200 bg-white p-6">
      <div className="flex justify-between">
        <div className="w-full">
          <div className="flex justify-between">
            <div className="flex">
              <div className="mr-4">
                {assetCode ? (
                  <Image
                    preview
                    src={`https://cdn.vettify.io/asset/file/${assetCode}`}
                    alt="asset-logo"
                    imageClassName="h-[50px] w-[50px] rounded-sm object-contain  lg:h-24 lg:w-24  p-2 border bg-gray-50"
                  />
                ) : (
                  <div className="h-[50px] w-[50px] rounded-sm border  bg-gray-50 object-contain  p-2 lg:h-24 lg:w-24" />
                )}
              </div>
              <div className="text-sm lg:text-lg">
                <p className="mb-1  font-semibold ">
                  {lg || sm
                    ? assetName?.length > 12
                      ? `${assetName?.slice(0, 12)}...`
                      : assetName
                    : assetName?.length > 40
                    ? `${assetName?.slice(0, 40)}...`
                    : assetName}
                </p>
                {!owned && (
                  <p>
                    {t("right-management-card.org.label")}:{" "}
                    {inviterInfo?.inviterOrgName}
                  </p>
                )}
                {owned && (
                  <>
                    {sm ? (
                      <p>
                        To:{" "}
                        {receiverInfo?.receiverEmail &&
                        receiverInfo?.receiverEmail?.length > 8
                          ? `${receiverInfo?.receiverEmail?.slice(0, 8)}...`
                          : receiverInfo?.receiverEmail}
                      </p>
                    ) : (
                      <p>To: {receiverInfo?.receiverEmail}</p>
                    )}
                  </>
                )}
                {!sm && (
                  <div className="flex">
                    <p className="mr-1">
                      {t("right-management-card.target-url.label")}:
                    </p>
                    <a
                      rel="noreferrer"
                      className="text-blue-500 underline"
                      href={
                        Number(targetURLType) !== 2
                          ? targetURL
                          : `https://cdn.vettify.io/asset/link/${rightCode}`
                      }
                      target="_blank">
                      {xl &&
                        !lg &&
                        !sm &&
                        (Number(targetURLType) !== 2
                          ? targetURL?.length > 45
                            ? `${targetURL?.slice(0, 45)}...`
                            : targetURL
                          : t("attestation-link-text"))}
                      {lg &&
                        !xl &&
                        !sm &&
                        (Number(targetURLType) !== 2
                          ? targetURL?.length > 45
                            ? `${targetURL?.slice(0, 45)}...`
                            : targetURL
                          : t("attestation-link-text"))}
                    </a>
                  </div>
                )}
                {!sm && (
                  <p>
                    {owned
                      ? t("right-management-card.created-at.label.owned")
                      : t("right-management-card.created-at.label.received")}
                    :{" "}
                    {new Date(createdAt * 1000).toLocaleDateString("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                )}
              </div>
            </div>
            <div>
              <div className="flex">
                <div
                  className={clsx(
                    "mr-2 rounded-full border border-gray-400 px-3 ",
                    owned ? "bg-gray-400 text-white" : "text-gray-400"
                  )}>
                  {type}
                </div>

                <Tooltip
                  target={
                    owned
                      ? `#status-tooltip-${rightId}-owned`
                      : `#status-tooltip-${rightId}-received`
                  }
                  position="top"
                  content={`${t(
                    getStatusTooltip({
                      status: status || "",
                      owned,
                    })
                  )}`}
                />
                <div
                  id={
                    owned
                      ? `status-tooltip-${rightId}-owned`
                      : `status-tooltip-${rightId}-received`
                  }
                  className={clsx(
                    " mr-2 flex items-center rounded-full border px-3",
                    chipStyle
                  )}>
                  <div
                    className={clsx(
                      "mr-2 h-[10px] w-[10px] rounded-full",
                      circleStyle
                    )}></div>
                  <p className="text-sm capitalize">
                    {!owned && status === "inactive" ? "Not used" : status}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {!(!owned && status === "revoked") && (
            <Divider styleOverride="py-4" />
          )}

          <div className="flex w-full">
            {owned && (
              <>
                <div>
                  {status === "pending" && (
                    <SecondaryButton
                      text={t("right-management-card.revoke.button.name")}
                      styleOverride="mr-4 color-red-600 border-red-600 text-red-600"
                      onClick={() => openModal()}
                    />
                  )}
                  {status === "active" && (
                    <>
                      <SecondaryButton
                        text={t("right-management-card.revoke.button.name")}
                        styleOverride="mr-4 color-red-600 border-red-600 text-red-600"
                        onClick={() => openModal()}
                      />
                      <SecondaryButton
                        text={t(
                          "right-management-card.view-analytics.button.name"
                        )}
                        styleOverride="mr-4"
                        onClick={() => {
                          navigate({
                            pathname: "/app/analytics",
                            search: `?id=${assetId}`,
                          });
                        }}
                      />
                    </>
                  )}
                  {status === "revoked" && (
                    <SecondaryButton
                      text={t("right-management-card.re-activate.button.name")}
                      onClick={() => {
                        performApiAction({
                          url: `${clientConfig.edgeApi}/api/v1/rights/update`,
                          xSession: userData?.session,
                          newData: {
                            id: rightId,
                            active: true,
                          },
                          onSuccess: () => {
                            if (updatedOwnedStatus) {
                              updatedOwnedStatus({
                                id: rightId as number,
                                value: true,
                              });
                            }
                          },
                        });
                      }}
                    />
                  )}
                  <PrimaryButton
                    text={t("right-management-card.view-detail.button.name")}
                    onClick={() => {
                      const info = {
                        status,
                        createdAt,
                        ...receiverInfo,
                        assetName,
                        assetCode,
                        targetURL,
                        assetDescription,
                        targetURLType,
                        rightCode,
                        rightId,
                        owned,
                        defaultTargetURL,
                      };
                      localStorage.setItem("view-detail", JSON.stringify(info));
                      navigate({
                        pathname: "/app/dashboard/view-detail",
                        search: `?orgId=${currentOrgId}`,
                      });
                    }}
                  />
                  <ConfirmModal
                    title={t("right-management-card.confirm-modal.title")}
                    description={t(
                      "right-management-card.confirm-modal.description"
                    )}
                    buttonTitle={t(
                      "right-management-card.confirm-modal.button.title"
                    )}
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    onClickHandler={() => {
                      performApiAction({
                        url: `${clientConfig.edgeApi}/api/v1/rights/update`,
                        xSession: userData?.session,
                        newData: {
                          id: rightId,
                          active: false,
                        },
                        onSuccess: () => {
                          if (updatedOwnedStatus) {
                            updatedOwnedStatus({
                              id: rightId as number,
                              value: false,
                            });
                          }
                        },
                      });
                      closeModal();
                    }}
                  />
                </div>
              </>
            )}
            {!owned && (
              <>
                {status !== "revoked" && (
                  <PrimaryButton
                    text="View Snippet"
                    onClick={() => {
                      const snippetInfo = {
                        right: {
                          messages,
                          relationship_nature: relationship,
                          target_url: targetURL,
                          last_seen: lastSeen,
                          target_url_type: targetURLType,
                        },
                        asset: {
                          filename: assetName,
                          asset_code: assetCode,
                          right_code: rightCode,
                          alt_attribute: altAttribute,
                        },
                        inviter: {
                          firstname: inviterInfo?.inviterUserFirstName,
                          lastname: inviterInfo?.inviterUserLastName,
                          orgname: inviterInfo?.inviterOrgName,
                        },
                        date: {
                          created_at: createdAt,
                          now,
                        },
                      };
                      localStorage.setItem(
                        "snippet",
                        JSON.stringify(snippetInfo)
                      );
                      navigate({
                        pathname: "/app/dashboard/view-snippet",
                        search: `?orgId=${currentOrgId}`,
                      });
                    }}
                  />
                )}
              </>
            )}
          </div>
          {error && (
            <Alert
              type="error"
              resetStatus={() => {
                resetStatus("error");
              }}>
              {error}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagementCard;
