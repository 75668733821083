import { Switch as HeadlessSwitch } from "@headlessui/react";
import clsx from "clsx";
import { forwardRef } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";

interface LabelProps {
  children: React.ReactNode;
  label: string;
  withTooltip?: boolean;
  tooltipIcon?: React.ReactNode;
}

function Label({ children, label, withTooltip, tooltipIcon }: LabelProps) {
  return (
    <>
      <label>
        <div className=" mb-2  flex items-center text-sm font-medium text-gray-700">
          {label}
          {withTooltip && (
            <>
              <Tooltip target=".custom-target-icon" />
              {tooltipIcon}
            </>
          )}
        </div>
        {children}
      </label>
    </>
  );
}

function FieldError({ errorText }: { errorText: string }) {
  return <small className="p-error">{errorText}</small>;
}

interface FieldProps {
  label?: string;
  errorText?: string;
  className?: string;
  children: React.ReactNode;
  withTooltip?: boolean;
  tooltipText?: string;
  tooltipIcon?: React.ReactNode;
}

function Field({
  label,
  errorText,
  children,
  className,
  withTooltip,
  tooltipText,
  tooltipIcon = (
    <i
      className="custom-target-icon icon-[ion--information-circle-outline] ml-1 text-center  align-middle text-gray-500"
      data-pr-tooltip={tooltipText}
      data-pr-position="right"
      data-pr-at="right+5 top"
      data-pr-my="left center-2"></i>
  ),
}: FieldProps) {
  return (
    <div className={clsx("flex flex-col gap-1", className)}>
      {label ? (
        <Label
          label={label}
          withTooltip={withTooltip}
          tooltipIcon={tooltipIcon}>
          {children}{" "}
        </Label>
      ) : (
        children
      )}
      {errorText && <FieldError errorText={errorText} />}
    </div>
  );
}

interface SpecificTextFieldProps {
  label: string;
  errorText?: string;
  tooltipText?: string;
  withTooltip?: boolean;
  tooltipIcon?: React.ReactNode;
}

type TextFieldProps = React.ComponentPropsWithRef<typeof InputText> &
  SpecificTextFieldProps;

const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const {
    label,
    errorText = "",
    className,
    withTooltip,
    tooltipText,
    tooltipIcon,
    ...fwdProps
  } = props;
  return (
    <Field
      label={label}
      withTooltip={withTooltip}
      tooltipText={tooltipText}
      tooltipIcon={tooltipIcon}
      errorText={errorText}
      className={className}>
      <InputText
        ref={ref}
        className={clsx(errorText.length > 0 && "p-invalid", "w-full")}
        {...fwdProps}
      />
    </Field>
  );
});

interface SpecificTextAreaFieldProps {
  label: string;
  errorText?: string;
  tooltipText?: string;
  withTooltip?: boolean;
  tooltipIcon?: React.ReactNode;
}

type TextAreaFieldProps = React.ComponentPropsWithRef<typeof InputTextarea> &
  SpecificTextAreaFieldProps;

const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (props, ref) => {
    const {
      label,
      errorText = "",
      className,
      withTooltip,
      tooltipText,
      tooltipIcon,
      ...fwdProps
    } = props;
    return (
      <Field
        label={label}
        withTooltip={withTooltip}
        tooltipText={tooltipText}
        tooltipIcon={tooltipIcon}
        errorText={errorText}
        className={className}>
        <InputTextarea
          ref={ref}
          className={clsx(
            errorText.length > 0 && "p-invalid",
            "min-h-[10rem] w-full"
          )}
          autoResize
          {...fwdProps}
        />
      </Field>
    );
  }
);

interface SpecificSwitchProps {
  value?: boolean;
  children?: React.ReactNode;
  childrenLeft?: React.ReactNode;
  screenReaderHint?: string;
  errorText?: string;
}

type ExtractProps<T> = T extends React.ComponentType<infer P>
  ? P
  : T extends React.Component<infer P>
  ? P
  : never;

type HeadlessSwitchProps = ExtractProps<typeof HeadlessSwitch>;

type SwitchFieldProps = Omit<
  HeadlessSwitchProps,
  keyof SpecificSwitchProps | "checked"
> &
  SpecificSwitchProps;

const SwitchField = forwardRef<HTMLButtonElement, SwitchFieldProps>(
  (props: SwitchFieldProps, ref) => {
    const {
      value = false,
      screenReaderHint = "",
      children,
      childrenLeft,
      errorText,
      ...fwdProps
    } = props;
    return (
      <div>
        <div className="flex items-center justify-center gap-2">
          {childrenLeft && (
            <p className="text-base text-gray-500">{childrenLeft}</p>
          )}
          <div className="shrink-0 leading-none">
            <HeadlessSwitch
              ref={ref}
              checked={value}
              className={clsx(
                value ? "bg-vettiblue-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-vettiblue-500 focus:ring-offset-2 [&:not(:focus-visible)]:focus:outline-none"
              )}
              {...fwdProps}>
              <span className="sr-only">{screenReaderHint}</span>
              <span
                aria-hidden="true"
                className={clsx(
                  value ? "translate-x-5" : "translate-x-0",
                  "inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </HeadlessSwitch>
          </div>
          {children && (
            <div className="text-base text-gray-500">{children}</div>
          )}
        </div>
        {errorText && <FieldError errorText={errorText} />}
      </div>
    );
  }
);

export type TextProps = TextFieldProps;
export const Text = TextField;

export type TextAreaProps = TextAreaFieldProps;
export const TextArea = TextAreaField;

export type SwitchProps = SwitchFieldProps;
export const Switch = SwitchField;

export default Field;
