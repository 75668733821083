import isValidDomain from "is-valid-domain";

export function urlValidator(url: string) {
  return /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
    url
  );
}

// Hostname, domains, origins, websites, URLs: these terms are easily confusable. This function
// enables our users to enter anything that might ressemble a domain. In the backend, for orgs and
// invitations we only care about the domain. For asset requests, we store the full URL since it
// might be useful down the road.
//
// This function returns "example.com" for the following inputs:
// "  example.com   "
// "example.com/foo/bar"
// "https://example.com/"
export function extractDomain(input: string): string | false {
  input = input.trim();

  // if input is a valid domain, return input
  if (isValidDomain(input)) {
    return input;
  }

  // otherwise try to parse input as a URL and extract hostname
  try {
    const d = new URL(input).hostname;
    if (isValidDomain(d)) {
      return d;
    }
  } catch (e) {}

  return false;
}
