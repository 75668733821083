import { Helmet } from "react-helmet";
import { useTranslate } from "lib/translate";
import AssetManagement from "components/dashboard/AssetManagement";
import RightManagement from "./RightManagement";
import Divider from "components/shared/Divider";

export default function OrgDetailsPage() {
  const { t } = useTranslate();

  return (
    <div>
      <Helmet>
        <title>
          {t("dashboard.tab.title") +
            t("general.title-separator") +
            t("vettify")}
        </title>
        <meta name="description" content={t("orgs.details.meta.description")} />
      </Helmet>

      <AssetManagement />
      <div className="py-16">
        <Divider />
      </div>
      <RightManagement />
    </div>
  );
}
